<template>
  <div class="box">
    <!-- 折线图 -->
    <v-chart
      v-if="componentType == 'line_0'"
      autoresize
      :options="zhexianchartOptions"
      class="myChart"
    ></v-chart>
    <!--竖形 柱状图 -->
    <v-chart
      v-if="componentType == 'column_0'"
      autoresize
      :options="zhuzhuangchartOptions"
      class="myChart"
    ></v-chart>
    <!--横向 柱状图 -->
    <v-chart
      v-if="componentType == 'bar_0'"
      autoresize
      :options="zhuzhuanghengchartOptions"
      class="myChart"
    ></v-chart>
    <!--饼状图 -->
    <v-chart
      v-if="componentType == 'pie'"
      autoresize
      :options="bingzhuangOptions"
      class="myChart"
    ></v-chart>
    <!--环状图 -->
    <v-chart
      v-if="componentType == 'donut'"
      autoresize
      :options="huanzhuangOptions"
      class="myChart"
    ></v-chart>
    <!--漏斗图 -->
    <div ref="funnleChartsRef" v-if="componentType == 'funnel'" style="width: 100%; height: 100%"></div>
    <!-- <v-chart
      v-if="componentType == 'funnel'"
      autoresize
      :options="funnelOptions"
      style="width: 100%; height: 100%"
    ></v-chart> -->
    <!--指针图 -->
    <v-chart
      v-if="componentType == 'gauge'"
      autoresize
      :options="zhizhenOptions"
      class="myChart"
    ></v-chart>
    <!-- 瀑布图 -->
    <v-chart
      v-if="componentType == 'waterfall'"
      autoresize
      :options="waterfallOptions"
      class="myChart"
    ></v-chart>
    <!--双层折线图 -->
    <v-chart
      v-if="componentType == 'line_1'"
      autoresize
      :options="zhexianDoubleOptions"
      class="myChart"
    ></v-chart>
    <!--堆叠柱状图 -->
    <v-chart
      v-if="componentType == 'column_duidie'"
      autoresize
      :options="duidiezhuzhuangOptions"
      class="myChart"
    ></v-chart>
    <!--横向堆叠柱状图 -->
    <v-chart
      v-if="componentType == 'bar_duidie'"
      autoresize
      :options="duidiezhuzhuanghengOPtions"
      class="myChart"
    ></v-chart>
    <!--堆叠折线图 -->
    <v-chart
      v-if="componentType == 'area'"
      autoresize
      :options="duidiezhexianOPtions"
      class="myChart"
    ></v-chart>
    <!--多个柱状图 -->
    <v-chart
      v-if="componentType == 'column_1'"
      autoresize
      :options="morezhuzhuangOPtions"
      class="myChart"
    ></v-chart>
    <!--条形图 -->
    <v-chart
      v-if="componentType == 'bar_1'"
      autoresize
      :options="crossManyDiffBar"
      class="myChart"
    ></v-chart>
    <!--数字图 -->
    <div v-if="componentType == 'number'" class="myNumber">
      {{ changeMicrometerLevel(12138) }}
    </div>
    <!--表格图表 -->
    <el-table
      v-if="componentType == 'tableDetail'"
      :data="tableData"
      border
      style="margin-top: 15px"
      :cell-style="rowClass"
      :header-cell-style="headClass"
    >
      <el-table-column
        prop="customer"
        :label="$t('label.weixin..xsld.kehu')"
        min-width="80"
      >
      </el-table-column>
      <el-table-column
        prop="opportunity"
        :label="$t('lable.quote.syncing.opportunity')"
        min-width="115"
      >
      </el-table-column>
      <el-table-column
        prop="time"
        min-width="110"
        :label="$t('homepage.components.creationtime')"
      >
      </el-table-column>
      <el-table-column
        prop="all"
        min-width="80"
        :label="$t('label.referperson')"
      ></el-table-column>
    </el-table>
    <!--表格图表2 -->
    <el-table
      v-if="componentType == 'tableDetail'"
      :data="tableDataTwo"
      border
      style="margin-top: 20px"
      :cell-style="rowClass"
      :header-cell-style="headClass"
    >
      <el-table-column
        prop="customer"
        :label="$t('label.weixin..xsld.kehu')"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="opportunity"
        :label="`${$t('label.stdashbord.xsld.business.jine')}${$t(
          'label.summation'
        )}`"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="time"
        width="120"
        :label="$t('vue_label_record_count')"
      >
      </el-table-column>
    </el-table>
    <!--地图热力图 -->
    <v-chart
      v-if="componentType == 'heatMap'"
      autoresize
      :options="getOptionsHeatMap"
      class="myChart"
    ></v-chart>
    <!--世界地图 -->
    <v-chart
      v-if="componentType == 'worldMap'"
      autoresize
      :options="getOptionsWordMap"
      class="myChart"
    ></v-chart>
    <!--美国地图 -->
    <v-chart
      v-if="componentType == 'usaMap'"
      autoresize
      :options="getOptionsUsaMap"
      class="myChart"
    ></v-chart>
    <!--英国|新加坡|墨西哥|加拿大|法国|德国|澳大利亚地图-->
    <v-chart
      v-if="componentType == 'britainMap'||
      componentType == 'singaporeMap'||
      componentType == 'mexicoMap'||
      componentType == 'canadaMap'||
      componentType == 'franceMap'||
      componentType == 'germanyMap'||
      componentType == 'australiaMap'"
      autoresize
      :options="getOptionsSevenMap"
      class="myChart"
    ></v-chart>
    <!-- 矩阵树形图 -->
    <v-chart
      v-if="componentType == 'matrixTree'"
      autoresize
      :options="matrixTreeOptions"
      style="width: 100%; height: 100%"
    ></v-chart>
    <!-- 雷达图 -->
    <v-chart
      v-if="componentType == 'radarMap'"
      autoresize
      :options="radarMapOptions"
      class="myChart"
    ></v-chart>
    <!-- 气泡图 -->
    <v-chart
      v-if="componentType == 'bubble'"
      autoresize
      :options="bubbleChartOptions"
      style="width: 100%; height: 100%"
    ></v-chart>
    <!-- 双轴图 -->
    <v-chart
      v-if="componentType == 'biaxialFigure'"
      autoresize
      :options="biaxialFigureOptions"
      class="myChart"
    ></v-chart>
    <!-- 目标图 -->
    <v-chart
      v-if="componentType == 'target'"
      autoresize
      :options="targetOptions"
      class="myChart"
    ></v-chart>
    <!-- 同环比报表 -->
    <el-table
      v-if="componentType == 'sequential'"
      :data="tableDataArr"
      border
      style="margin-top: 15px"
      :cell-style="rowClass"
      :header-cell-style="headClass"
    >
      <el-table-column
        prop="timeApi"
        :label="$t('label.createdate')"
        min-width="115"
      >
      </el-table-column>
      <el-table-column
        prop="valueApi"
        :label="$t('label.stdashbord.xsld.business.jine')"
        min-width="80"
      >
      </el-table-column>
      <el-table-column
        prop="monthRatio"
        min-width="110"
        :label="$t('label_ring_ratio')"
      >
      </el-table-column>
      <el-table-column
        prop="lastYearSum"
        min-width="80"
        :label="`${$t('label_report_last_year_same')}`"
      ></el-table-column>
      <el-table-column
        prop="yearRatio"
        min-width="80"
        :label="$t('label_report_year_last_year')"
      ></el-table-column>
    </el-table>
    <!-- 同环比双轴图 -->
    <v-chart
      v-if="componentType == 'biaxial'"
      autoresize
      :options="diagramOptions"
      class="myChart"
    ></v-chart>
    <!-- 数字图（同环比） -->
    <div v-if="componentType == 'digital'" class="digitalMap">
      <div class="nowMonth">
        <div class="now-date">{{ $t("this.month") }}:</div>
        <div class="now-data">$300</div>
        <div class="greenDiv"></div>
        <div class="ratio-data">3.09%</div>
      </div>
      <div class="MonthClass">
        <div class="last-date">{{ $t("label.event.lastmonth") }} :</div>
        <div class="last-data">$291</div>
      </div>
    </div>
  </div>
</template>
<script>
import chinaChinese from "@/config/heatMap/chinaChinese.json"; // 中国地图中文配置文件
import chinaEnglish from "@/config/heatMap/chinaEnglish.json"; // 中国地图英文配置文件
import Highcharts from "highcharts";
import axios from "axios";
require("highcharts/modules/funnel")(Highcharts);
export default {
  components: {},
  props: {
    componentType: String,
  },
  data() {
    return {
      zhexianchartOptions: {}, //折线图
      zhuzhuangchartOptions: {}, //柱状图
      zhuzhuanghengchartOptions: {}, //横向柱状图
      bingzhuangOptions: {}, //饼状图
      huanzhuangOptions: {}, //环状图
      funnelOptions: {}, // 漏斗图
      zhizhenOptions: {}, //指针图
      zhexianDoubleOptions: {}, //双层折线图
      duidiezhuzhuangOptions: {}, //堆叠柱状图
      duidiezhuzhuanghengOPtions: {}, //横向堆叠柱状图
      duidiezhexianOPtions: {}, //堆叠折线图
      morezhuzhuangOPtions: {}, //多个柱状图
      crossManyDiffBar: {}, //条形图
      waterfallOptions: {}, //瀑布图
      getOptionsHeatMap: {}, //地图热力图
      getOptionsWordMap: {}, //世界地图
      getOptionsSevenMap: {}, //英国|新加坡|墨西哥|加拿大|法国|德国|澳大利亚地图-
      getOptionsUsaMap: {}, //美国地图
      matrixTreeOptions: {}, //矩阵树形图
      radarMapOptions: {}, //雷达图
      bubbleChartOptions: {}, //气泡图
      targetOptions: {}, //目标图
      biaxialFigureOptions: {}, //双轴图
      diagramOptions: {}, //同环比双轴图
      tableDataArr: [
        {
          timeApi: "2021-04",
          valueApi: "2000",
          lastYearSum: "0",
          monthRatio: "0.00%",
          yearRatio: "0.00%",
        },
        {
          timeApi: "2021-05",
          valueApi: "2000",
          lastYearSum: "0",
          monthRatio: "0.00%",
          yearRatio: "0.00%",
        },
        {
          timeApi: "2021-06",
          valueApi: "2000",
          lastYearSum: "0",
          monthRatio: "0.00%",
          yearRatio: "0.00%",
        },
        {
          timeApi: "2021-07",
          valueApi: "2000",
          lastYearSum: "0",
          monthRatio: "6969.47%",
          yearRatio: "0.00%",
        },
      ],

      tableData: [
        {
          time: "2016-05-02",
          opportunity: this.$i18n.t("vue_label_jihui_yi"),
          customer: this.$i18n.t("vue_label_zhang_yi"),
          all: this.$i18n.t("vue_label_wang_yi"),
        },
        {
          time: "2016-05-04",
          opportunity: this.$i18n.t("vue_label_jihui_er"),
          customer: this.$i18n.t("vue_label_zhang_er"),
          all: this.$i18n.t("vue_label_wang_er"),
        },
      ], //表格图表
      tableDataTwo: [
        {
          time: "3",
          opportunity: "183,426",
          customer: this.$i18n.t("vue_label_zhang_er"),
        },
      ], //表格图表
    };
  },
  mounted() {
    this.zhexianChat(); //折线图
    this.zhuzhuangchart(); //柱状图
    this.zhuzhuanghengChat(); //横向柱状图
    this.bingzhuangChat(); //饼状图
    this.huanzhuangChat(); //环状图
    this.zhizhenChat(); //指针图
    this.zhexianDoubleChat(); //双层折线图
    this.duidiezhuzhuangChat(); //堆叠柱状图
    this.duidiezhuzhuanghengChat(); //横向堆叠柱状图
    this.duidiezhexianChat(); //堆叠折线图
    this.crossManyDiffBarfn(); //条形图
    this.waterfallChat(); //瀑布图
    this.getMatrixTreeFn(); //矩阵树形图
    this.getRadarMapFn(); //雷达图
    this.getBubbleChartFn(); //气泡图
    this.getTargetFn(); //目标图
    this.getBiaxialFigureFn(); //双轴图
    this.getDiagramFn(); //同环比双轴图
    this.drawChart();
  },
  watch: {
    //监听类型的变化，设置图表的x轴和y轴
    componentType() {
      this.drawChart();
    },
  },
  methods: {
    /**
     * 世界地图和美国地图鼠标悬浮显示提示信息
     * @param {val} 提示信息
     */
    worldMapShowFormatter(val) {
      let recordNumber = this.$i18n.t("label.recordamount");
      if (val.name && val.value) {
        return `${val.name} <br /> ${recordNumber}: ${val.value}`;
      } else {
        return ` ${val.name}<br /> ${recordNumber} : 0`;
      }
    },
    // 渲染图表
    drawChart() {
      let worldLanguage = "";
      let usaLanguage = "";
      let myorigins="";
      let usaorigin ="";
      switch (this.componentType) {
        case "bar_0": // 1--type: "bar_0", //没有第二个分组的条形图
          this.changeAxisName(this.zhuzhuanghengchartOptions);
          break;
          case "funnel": // 1--type: "bar_0", //漏斗图
          this.loudouChat(); //漏斗图
          break;
        case "column_0": // 2--type: "column_0", //没有第二个分组的柱状图
          this.changeAxisName(this.zhuzhuangchartOptions);
          break;
        case "waterfall": // 3--type: "waterfall", //没有第二个分组的柱状图类型中的瀑布图
          this.changeAxisName(this.waterfallOptions);
          break;
        case "line_0": // 4--type: "line_0", //没有第二个分组的折线图
          this.changeAxisName(this.zhexianchartOptions);
          break;
        case "line_1": // 9--type: "line_1", //有第二个分组的折线图
          this.changeAxisName(this.zhexianDoubleOptions);
          break;
        case "column_duidie": // 10--type: "column_duidie", //柱状堆叠图
          this.changeAxisName(this.duidiezhuzhuangOptions);
          break;
        case "bar_duidie": // 11--type: "bar_duidie", //条形堆叠图
          this.changeAxisName(this.duidiezhuzhuanghengOPtions);
          break;
        case "area": // 12--type: "area", //面积堆叠图
          this.changeAxisName(this.duidiezhexianOPtions);
          break;
        case "heatMap": // 17--type: "heatMap", //没有第二个分组的热力图
          if (this.$store.state.userInfoObj.language == "zh") {
            this.getOptionsHeatMapFn(chinaChinese);
          } else {
            this.getOptionsHeatMapFn(chinaEnglish);
          }
          break;
        case "column_1": // 13--type: "column_1", //有第二个分组的柱状图
          this.morezhuzhuangChat(); //多个柱状图
          this.changeAxisName(this.morezhuzhuangOPtions);
          break;
        case "bar_1": // 14--type: "bar_1", //有第二个分组的条形图
          this.changeAxisName(this.crossManyDiffBar);
          break;
        case "worldMap": // 14--type: "bar_1", ////没有第二个分组的世界地图
          myorigins = window.location.origin;
          if (this.$store.state.userInfoObj.language == "zh") {
            worldLanguage = "/cloudcc-json/worldChinese.json";
          } else {
            worldLanguage = "/cloudcc-json/worldEnglish.json";
          }
          axios.get(myorigins + worldLanguage).then((res) => {
            this.getOptionsWordMapFn(res.data);
            this.$emit("maploading");
          });
          break;
        case "usaMap": // 15--type: "heatMap", //没有第二个分组的热力图
          usaorigin = window.location.origin;
          if (this.$store.state.userInfoObj.language == "zh") {
            usaLanguage = "/cloudcc-json/usaMapChinese.json";
          } else {
            usaLanguage = "/cloudcc-json/usaMapEnglish.json";
          }
          axios.get(usaorigin + usaLanguage).then((res) => {
            this.$emit("maploading");
            this.getOptionsUsaMapFn(res.data);
          });
          break;
        case "britainMap": //英国
          this.getMapCase("britainMap","UK")
          break;
        case "singaporeMap": //新加坡
          this.getMapCase("singaporeMap","Singapore")
          break;
        case "mexicoMap": //墨西哥
          this.getMapCase("mexicoMap","Mexico")
          break;
        case "canadaMap": //加拿大
          this.getMapCase("canadaMap","Canada")
          break;
        case "franceMap": //法国
          this.getMapCase("franceMap","FR")
        break;
        case "germanyMap": //德国
          this.getMapCase("germanyMap","DE")
        break;
        case "australiaMap": //澳大利亚
          this.getMapCase("australiaMap","Australia")
        break;
      }
    },
    changeAxisName(options) {
      if (
        this.componentType === "bar_duidie" ||
        this.componentType === "bar_1" ||
        this.componentType === "bar_0"
      ) {
        options.xAxis.name = this.$i18n.t("label.forecast.settings.sl");
        options.yAxis.name = this.$i18n.t("date");
      } else {
        options.xAxis.name = this.$i18n.t("date");
        options.yAxis.name = this.$i18n.t("label.forecast.settings.sl");
      }
    },
    headClass() {
      return "text-align: center;background:#788EE0;color:#FFF; height: 30px;padding:0;line-height: 30px;";
    },
    rowClass() {
      return "text-align: center; line-height: 30px;padding:5px;";
    },

    // 将数值转换为千分位
    changeMicrometerLevel(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
    // 瀑布图
    waterfallChat() {
      this.waterfallOptions = {
        color: [
          "#7F8DE1",
          "#FCB95C",
          "#4087F2",
          "#59B9FF",
          "#2CC7BE",
          "#93D053",
          "#F6CA25",
          "#DA4E2E",
          "#F3768A",
        ],
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {
            // 上升或下降
            let upOrDown;
            if (params[1].value !== "-") {
              upOrDown = params[1];
            } else {
              upOrDown = params[2];
            }

            // 总和
            let sum = params[0];
            sum.value =
              params[1].value !== "-" ? sum.value + upOrDown.value : sum.value;
            //第一个提示框只显示总和
            return sum.value - upOrDown.value === 0 //先用这个没找到合适的条件
              ? `${sum.name}<br />${sum.seriesName}:${sum.value}`
              : `${sum.name}<br />${sum.seriesName}:${sum.value}<br />${upOrDown.seriesName}:${upOrDown.value}`;
          },
        },

        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          nameGap: 25,
          splitLine: { show: false },
          // data: (function () {
          //   var list = [];
          //   for (var i = 1; i <= 10; i++) {
          //     list.push("11月" + i + "日");
          //   }
          //   return list;
          // })(),
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          nameLocation: "end",
          nameGap: 12,
          type: "value",
          nameTextStyle: {
            align: "left",
          },
        },
        series: [
          {
            name: this.$i18n.t("vue_label_total"),
            type: "bar",
            stack: "总量",
            itemStyle: {
              barBorderColor: "rgba(0,0,0,0)",
              color: "rgba(0,0,0,0)",
            },
            emphasis: {
              itemStyle: {
                barBorderColor: "rgba(0,0,0,0)",
                color: "rgba(0,0,0,0)",
              },
            },
            data: [0, 466, 651, 397, 397, 532, 710],
          },
          {
            name: this.$i18n.t("vue_label_rise"),
            type: "bar",
            stack: "总量",
            data: [466, 393, "-", "-", 135, 178, 286],
          },
          {
            name: this.$i18n.t("vue_label_fall"),
            type: "bar",
            stack: "总量",
            data: ["-", "-", 208, 254, "-", "-", "-"],
          },
        ],
      };
    },
    //   折线图
    zhexianChat() {
      this.zhexianchartOptions = {
        tooltip: {
          trigger: "axis",
          enterable: true,
          confine: true,
          axisPointer: {
            type: "shadow",
          },
        },
        //X轴
        xAxis: {
          type: "category",
          nameLocation: "middle",
          nameGap: 25,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        //Y轴，
        yAxis: {
          type: "value",
          nameLocation: "end", //设置上中下位置
          nameGap: 12, //距上距离
          nameTextStyle: {
            //y轴标题对齐方式
            align: "left",
          },
        },
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        series: [
          {
            data: [120, 280, 160, 360, 220, 397, 600],
            type: "line",
            itemStyle: {
              normal: {
                color: "#7F8DE1", //圈圈的颜色
                lineStyle: {
                  color: "#7F8DE1", //线的颜色
                },
              },
            },
          },
        ],
      };
    },
    // 柱状图
    zhuzhuangchart() {
      this.zhuzhuangchartOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          name: "",
          nameLocation: "middle",
          nameGap: 25,
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
          nameLocation: "end",
          nameGap: 12,
          nameTextStyle: {
            align: "left",
          },
        },
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: "bar",
            // center: ["20%", "60%"],
            showBackground: true,
            itemStyle: {
              normal: {
                color: "#FFB657", //圈圈的颜色
                lineStyle: {
                  color: "#FFB657", //线的颜色
                },
              },
            },
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };
    },
    //横向柱状图
    zhuzhuanghengChat() {
      this.zhuzhuanghengchartOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          name: "",
          nameLocation: "middle",
          nameGap: 25,
          type: "value",
          boundaryGap: [0, 0.01],
        },
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        yAxis: {
          type: "category",
          nameLocation: "end",
          nameGap: 12,
          nameTextStyle: {
            align: "left",
          },
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        series: [
          {
            type: "bar",
            center: ["50%", "60%"], //位于图表的位置
            data: [180, 489, 204, 170, 144, 430, 110],
            itemStyle: {
              normal: {
                color: "#788EE0", //圈圈的颜色
                lineStyle: {
                  color: "#788EE0", //线的颜色
                },
              },
            },
          },
        ],
      };
    },
    // 饼图
    bingzhuangChat() {
      this.bingzhuangOptions = {
        tooltip: {
          trigger: "item",
        },
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        color: [
          "#FCB95C",
          "#7F8DE1",
          "#2CC7BE",
          "#4087F2",
          "#93D053",
          "#F6CA25",
          "#875AE6",
          "#F3768A",
          "#DA4E2E",
        ],
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            labelLine: {
              length: 30,
            },
            data: [
              { value: 184, name: "Mon" },
              { value: 284, name: "Tue" },
              { value: 1048, name: "Wed" },
              { value: 235, name: "Thu" },
              { value: 480, name: "Fri" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    // 环状图
    huanzhuangChat() {
      this.huanzhuangOptions = {
        tooltip: {
          trigger: "item",
        },
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["30%", "50%"],
            labelLine: {
              length: 30,
            },
            color: [
              "#FCB95C",
              "#7F8DE1",
              "#2CC7BE",
              "#4087F2",
              "#93D053",
              "#F6CA25",
              "#F3768A",
              "#DA4E2E",
              "#875AE6",
            ],
            label: {},
            data: [
              { value: 122, name: "Mon" },
              { value: 735, name: "Tue" },
              { value: 580, name: "Wed" },
              { value: 484, name: "Thu" },
              { value: 948, name: "Fri" },
            ],
          },
        ],
      };
    },
    //漏斗图
    loudouChat() {
      this.funnelOptions = {
        chart: {
          type: "funnel",
          margin: [40, 90, 10, 30], //设置距离图表的上左下右
        },
        title: {
          text: "",
          x: -50,
        },
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        colors: [
          "#59B9FF",
          "#2CC7BE",
          "#7F8DE1",
          "#4087F2",
          "#F6CA25",
          "#93D053",
          "#F6CA25",
          "#F3768A",
          "#DA4E2E",
          "#875AE6",
        ],
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: "none",
              format: "<b>{point.name}</b> ({point.y:,.0f})",
              color:
                (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                "black",
              softConnector: true,
            },
            neckWidth: "30%",
            neckHeight: "25%",
          },
        },
        legend: {
          enabled: false,
        },
        series: [
          {
            name: this.$i18n.t("date"),
            data: [
              ["Mon", 654],
              ["Tue", 464],
              ["Wed", 187],
              ["Thu", 176],
            ],
          },
        ],
        credits: {
          enabled: false, //不显示logo
        }
      };
      this.$nextTick(() => {
        Highcharts.chart(this.$refs.funnleChartsRef, this.funnelOptions);
      });

      // let dataArr = [
      //   { name: "Mon", value: "654" },
      //   { name: "Tue", value: "464" },
      //   { name: "Wed", value: "187" },
      //   { name: "Thu", value: "176" },
      // ];
      // dataArr.sort((a, b) => {
      //   return a.value - b.value;
      // });
      // dataArr.forEach((list, index) => {
      //   if (dataArr.length === 1) {
      //     list.value = 100 * (index + 1);
      //   } else {
      //     list.value = index;
      //   }
      // });
      // let that = this;
      // this.funnelOptions = {
      //   title: {
      //     text: "",
      //   },
      //   tooltip: {
      //     trigger: "item",
      //     formatter: "",
      //   },
      //   color: [
      //     "#59B9FF",
      //     "#2CC7BE",
      //     "#7F8DE1",
      //     "#4087F2",
      //     "#93D053",
      //     "#F6CA25",
      //     "#F3768A",
      //     "#DA4E2E",
      //     "#875AE6",
      //   ],
      //   series: [
      //     {
      //       name: "",
      //       type: "funnel",
      //       left: "15%",
      //       top: "10%",
      //       width: "60%",
      //       height: "80%",
      //       min: 0,
      //       max: dataArr.length,
      //       minSize: "20%",
      //       maxSize: "80%",
      //       sort: "descending",
      //       data: dataArr,
      //       gap: 0.2,
      //       label: {
      //         show: true,
      //         position: "right",
      //         formatter: function (val) {
      //           return val.data.name + `(` + val.data.value + `)`;
      //         },
      //         color: "#666666",
      //         fontSize: 12,
      //       },
      //       labelLine: {
      //         length: 25,
      //         lineStyle: {
      //           width: 1,
      //           type: "solid",
      //         },
      //       },
      //       itemStyle: {
      //         borderColor: "#fff",
      //         borderWidth: 1,
      //       },
      //       emphasis: {
      //         label: {
      //           fontSize: 12,
      //         },
      //         focus: "global",
      //         blurScope: "coordinateSystem",
      //       },
      //     },
      //   ],
      // };
    },
    // 指针图
    zhizhenChat() {
      this.zhizhenOptions = {
        series: [
          {
            type: "gauge",
            radius: "100%",
            center: ["50%", "60%"],
            startAngle: 180, //开始角度
            endAngle: 0, //结束角度
            splitNumber: 10, //设置间隔区域的显示数量
            min: 0,
            max: 100,
            axisLine: {
              show: true,
              lineStyle: {
                width: 10,
                color: [
                  [0.3, "#FCB95C"],
                  [0.7, "#2CC7BE"],
                  [1, "#4087F2"],
                ],
                shadowBlur: 1.8,
                shadowColor: "rgba(113, 54, 54, 1)",
              },
            },
            axisTick: {
              distance: -30,
              length: 8,
              lineStyle: {
                color: "#fff",
                width: 2,
              },
            },
            splitLine: {
              distance: -10,
              length: 10,
              lineStyle: {
                color: "#fff",
                width: 2,
              },
            },
            axisLabel: {
              distance: 16,
              fontSize: 12,
              color: "#B9B8CE",
            },
            detail: {
              color: "auto",
              offsetCenter: ["0", "35%"], // x, y，单位px
              textStyle: {
                // 其余属性默认使用全局文本样式
                fontWeight: "bolder",
                fontSize: 18,
                color: "#4087F2",
              },
            },
            title: {
              //设置仪表盘中间显示文字样式
              offsetCenter: ["0", "20%"],
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                fontSize: 14,
                color: "#B9B8CE",
              },
            },
            data: [
              {
                value: 90,
                name: this.$i18n.t("label.forecast.settings.sl"),
              },
            ],
          },
        ],
      };
    },
    //双层折线图
    zhexianDoubleChat() {
      this.zhexianDoubleOptions = {
        tooltip: {
          trigger: "axis",
        },
        colors: [
          "#7F8DE1",
          "#FCB95C",
          "#4087F2",
          "#2CC7BE",
          "#93D053",
          "#F6CA25",
          "#F3768A",
        ],
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          name: "X",
          nameGap: 25,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
          nameLocation: "end",
          nameGap: 12,
          nameTextStyle: {
            align: "left",
          },
        },
        series: [
          {
            name: "",
            type: "line",
            data: [12, 6, 13, 7, 12, 10, 19],
            itemStyle: {
              normal: {
                color: "#7F8DE1", //圈圈的颜色
                lineStyle: {
                  color: "#7F8DE1", //线的颜色
                },
              },
            },
          },
          {
            name: "",
            type: "line",
            data: [8, 4, 12, 10, 8, 12, 20],
            itemStyle: {
              normal: {
                color: "#FCB95C", //圈圈的颜色
                lineStyle: {
                  color: "#FCB95C", //线的颜色
                },
              },
            },
          },
        ],
      };
    },
    // 堆叠柱状图
    duidiezhuzhuangChat() {
      var data = {
        area: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        // legend: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        data: [
          [634, 1390, 1330, 1320, 1000, 500],
          [320, 334, 390, 330, 320, 100, 50],
          [340, 234, 190, 130, 320, 500, 550],
          [120, 134, 390, 30, 320, 200, 50],
        ],
      };

      this.duidiezhuzhuangOptions = {
        color: [
          "#7F8DE1",
          "#4087F2",
          "#FCB95C",
          "#59B9FF",
          "#2CC7BE",
          "#93D053",
          "#F6CA25",
          "#F3768A",
        ],
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
          enterable: true,
        },
        // legend: {
        //   top: 10,
        //   left: 20,
        //   itemWidth: 10,
        //   itemHeight: 10,
        //   // padding: [5, 10],
        //   textStyle: {
        //     fontSize: 14,
        //     color: "#96A4F4",
        //     padding: [3, 0, 0, 0],
        //   },
        //   data: data.legend,
        // },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          nameGap: 25,
          data: data.area,
        },
        yAxis: {
          type: "value",
          nameLocation: "end",
          nameGap: 12,
          nameTextStyle: {
            align: "left",
          },
          splitLine: {
            lineStyle: {
              color: "rgba(150, 164, 244, 0.3)",
            },
          },
        },
        series: [],
      };
      for (var i = 0; i < data.data.length; i++) {
        this.duidiezhuzhuangOptions.series.push({
          // name: data.legend[i],
          type: "bar",
          stack: "总量",
          // barWidth: "45%",
          label: {
            show: false,
            position: "insideRight",
          },
          data: data.data[i],
        });
      }
    },
    // 横向堆叠柱状图
    duidiezhuzhuanghengChat() {
      this.duidiezhuzhuanghengOPtions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        color: [
          "#4087F2",
          "#59B9FF",
          "#F6CA25",
          "#2CC7BE",
          "#93D053",
          "#A391F2",
          "#F99B4A",
        ], // legend: {
        //   data: [
        //     "Direct",
        //     "Mail Ad",
        //     "Affiliate Ad",
        //     "Video Ad",
        //     "Search Engine",
        //   ],
        // },
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          name: "",
          nameLocation: "middle",
          nameGap: 25,
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          nameTextStyle: {
            align: "left",
          },
          nameLocation: "end",
          nameGap: 12,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        series: [
          {
            name: "Direct",
            type: "bar",
            stack: "total",
            emphasis: {
              focus: "series",
            },
            data: [320, 302, 301, 334, 390, 330, 320],
          },
          {
            name: "Mail Ad",
            type: "bar",
            stack: "total",
            emphasis: {
              focus: "series",
            },
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "Affiliate Ad",
            type: "bar",
            stack: "total",
            emphasis: {
              focus: "series",
            },
            data: [220, 182, 191, 234, 290, 330, 310],
          },
        ],
      };
    },
    //堆叠折线图
    duidiezhexianChat() {
      this.duidiezhexianOPtions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        colors: [
          "#7F8DE1",
          "#FCB95C",
          "#4087F2",
          "#59B9FF",
          "#2CC7BE",
          "#93D053",
          "#F6CA25",
          "#DA4E2E",
          "#F3768A",
        ],
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          name: "X",
          nameGap: 25,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
          nameTextStyle: {
            align: "left",
          },
          nameLocation: "end",
          nameGap: 12,
        },
        series: [
          {
            name: "",
            type: "line",
            stack: "总量",
            emphasis: {
              focus: "series",
            },
            areaStyle: {
              color: "#7F8DE1",
            },
            itemStyle: {
              normal: {
                color: "#7F8DE1",
              },
            },
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "",
            type: "line",
            stack: "总量",
            itemStyle: {
              normal: {
                color: "#FCB95C",
              },
            },
            areaStyle: {
              color: "#FCB95C",
            },
            emphasis: {
              focus: "series",
            },
            data: [320, 332, 301, 334, 390, 330, 320],
          },
        ],
      };
    },
    // 多个柱状图
    morezhuzhuangChat() {
      this.morezhuzhuangOPtions = {
        color: [
          "#A391F2",
          "#4087F2",
          "#59B9FF",
          "#2CC7BE",
          "#93D053",
          "#F6CA25",
          "#DA4E2E",
          "#F3768A",
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
        },
        xAxis: {
          type: "category",
          axisTick: { show: false },
          nameLocation: "middle",
          nameGap: 25,
          data: ["2012", "2013", "2014", "2015", "2016"],
        },

        yAxis: {
          type: "value",
          nameLocation: "end",
          nameGap: 12,
          nameTextStyle: {
            align: "left",
          },
        },

        series: [
          {
            name: "Steppe",
            type: "bar",
            itemStyle: {
              normal: {
                color: "#59B9FF", //圈圈的颜色
              },
            },
            data: [320, 182, 191, 234, 490],
          },
          {
            name: "Desert",
            type: "bar",
            itemStyle: {
              normal: {
                color: "#A391F2", //圈圈的颜色
              },
            },
            data: [150, 32, 201, 104, 190],
          },
          {
            name: "Wetland",
            type: "bar",
            itemStyle: {
              normal: {
                color: "#4087F2", //圈圈的颜色
              },
            },
            data: [98, 237, 101, 99, 40],
          },
        ],
      };
    },
    //条形图
    crossManyDiffBarfn() {
      this.crossManyDiffBar = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        color: [
          "#4087F2",
          "#2CC7BE",
          "#F6CA25",
          "#93D053",
          "#F3768A",
          "#A391F2",
          "#FCB95C",
        ],
        // legend: {
        //   data: ["2011年", "2012年"],
        // },
        grid: {
          top: "12%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          name: "",
          nameLocation: "middle",
          nameGap: 25,
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          nameTextStyle: {
            align: "left",
          },
          nameLocation: "end",
          nameGap: 12,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        },
        series: [
          {
            name: "",
            type: "bar",
            data: [112, 489, 234, 270, 231, 230],
            itemStyle: {
              normal: {
                color: "#4087F2", //圈圈的颜色
              },
            },
          },
          {
            name: "",
            type: "bar",
            data: [295, 238, 110, 194, 131, 187],
            itemStyle: {
              normal: {
                color: "#2CC7BE", //圈圈的颜色
              },
            },
          },
        ],
      };
    },
    // USA美国地图
    getOptionsUsaMapFn(usaJson) {
      let mapUsaData = [];
      if (this.$store.state.userInfoObj.language == "zh") {
        mapUsaData = [
          { name: "佛罗里达", value: 18 },
          { name: "乔治亚", value: 32 },
          { name: "爱达荷", value: 50 },
        ];
      } else {
        mapUsaData = [
          { name: "Alabama", value: 12 },
          { name: "Arizona", value: 19 },
          { name: "Arkansas", value: 23 },
          { name: "Florida", value: 50 },
        ];
      }
      if (this.$store.state.userInfoObj.language == "zh") {
        window.echarts.registerMap("USA", usaJson, {
          阿拉斯加: {
            // 把阿拉斯加移到美国主大陆左下方
            left: -131,
            top: 25,
            width: 15,
          },
          夏威夷: {
            left: -110,
            top: 28,
            width: 5,
          },
        });
      } else {
        window.echarts.registerMap("USA", usaJson, {
          Alaska: {
            // 把阿拉斯加移到美国主大陆左下方
            left: -131,
            top: 25,
            width: 15,
          },
          Hawaii: {
            left: -110,
            top: 28,
            width: 5,
          },
        });
      }

      this.getOptionsUsaMap = {
        title: {
          top: 20, // 定位 值: 'top', 'middle', 'bottom' 也可以是具体的值或者百分比
          left: "center", // 值: 'left', 'center', 'right' 同上
          textStyle: { fontSize: 12 }, //提示标签字体颜色
        },
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: (val) => this.worldMapShowFormatter(val),
        },
        visualMap: {
          min: 0, // minValue 视觉映射组件的最小值
          max: this.mapMax, //  maxValue 视觉映射组件的最大值
          hoverLink: true, //鼠标悬浮到visualMap 组件上时,鼠标位置对应的数值 在图表中对应的图形元素,会高亮
          type: "piecewise", //类型
          splitNumber: 10, //平均分成多少段
          itemSymbol: "rect", //每个图例的数据
          borderWidth: 0, //每个图例的边框
          borderColor: "none",
          textGap: 5, //每个图例距离标签的长度
          itemGap: -3, //每个图例之间的长度
          itemWidth: 15, //每个图例的宽
          itemHeight: 12, //每个图例的高
          left: "0",
          // eslint-disable-next-line no-dupe-keys
          hoverLink: true,
          color: ["#ff830d", "#90d153", "#72d9aa", "#72d9aa", "#3281ff"],
          pieces: [
            { gt: 0, lte: 5, label: "5" },
            { gt: 5, lte: 10, label: " " },
            { gt: 10, lte: 15, label: " " },
            { gt: 15, lte: 20, label: "20" },
            { gt: 20, lte: 25, label: " " },
            { gt: 25, lte: 30, label: " " },
            { gt: 30, lte: 35, label: "35" },
            { gt: 35, lte: 40, label: " " },
            { gt: 40, lte: 45, label: " " },
            { gt: 45, lte: 50, label: "50" },
          ],
        },
        series: [
          {
            type: "map",
            // roam: true,
            map: "USA",
            emphasis: {
              label: {
                show: true,
              },
            },
            data: mapUsaData,
            itemStyle: {
              normal: {
                areaColor: "#cecece", //区域背景颜色
                borderWidth: 0.8, //区域边框宽度
                borderColor: "#F6F6F6", //区域边框颜色
              },
              emphasis: {
                areaColor: "#E5ABA6", //鼠标悬停背景色
              },
            },
          },
        ],
      };
    },
    // 英国|新加坡|墨西哥|加拿大|法国|德国|澳大利亚地图
    getMapCase(caseVal,type){
      let origin = window.location.origin;
      let Language = "";
      if (this.$store.state.userInfoObj.language == "zh") {
        Language = "/cloudcc-json/"+caseVal+"Chinese.json";
      } else {
        Language = "/cloudcc-json/"+caseVal+"English.json";
      }
      axios.get(origin + Language).then((res) => {
        this.$emit("maploading");
        this.getOptionsOther(type,res.data);
      });
    },
    //英国|新加坡|墨西哥|加拿大|法国|德国|澳大利亚地图
    getOptionsOther(type,usaJson) {
      let data = [];
      window.echarts.registerMap(type, usaJson);
      this.getOptionsSevenMap = {
        title: {
          top: 20, // 定位 值: 'top', 'middle', 'bottom' 也可以是具体的值或者百分比
          left: "center", // 值: 'left', 'center', 'right' 同上
          textStyle: { fontSize: 12 }, //提示标签字体颜色
        },
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: (val) => this.worldMapShowFormatter(val),
        },
        visualMap: {
          min: 0, // minValue 视觉映射组件的最小值
          max: this.mapMax, //  maxValue 视觉映射组件的最大值
          hoverLink: true, //鼠标悬浮到visualMap 组件上时,鼠标位置对应的数值 在图表中对应的图形元素,会高亮
          type: "piecewise", //类型
          splitNumber: 10, //平均分成多少段
          itemSymbol: "rect", //每个图例的数据
          borderWidth: 0, //每个图例的边框
          borderColor: "none",
          textGap: 5, //每个图例距离标签的长度
          itemGap: -3, //每个图例之间的长度
          itemWidth: 15, //每个图例的宽
          itemHeight: 12, //每个图例的高
          left: "0",
          // eslint-disable-next-line no-dupe-keys
          hoverLink: true,
          color: ["#ff830d", "#90d153", "#72d9aa", "#72d9aa", "#3281ff"],
          pieces: [
            { gt: 0, lte: 1, label: "1" },
            { gt: 1, lte: 2, label: " " },
            { gt: 2, lte: 3, label: " " },
            { gt: 3, lte: 4, label: "4" },
            { gt: 4, lte: 5, label: " " },
            { gt: 5, lte: 6, label: " " },
            { gt: 6, lte: 7, label: "7" },
            { gt: 7, lte: 8, label: " " },
            { gt: 8, lte: 9, label: " " },
            { gt: 9, lte: 10, label: "10" },
          ],
        },
        series: [
          {
            type: "map",
            // roam: true,
            map: type,
            emphasis: {
              label: {
                show: true,
              },
            },
            data: data,
            itemStyle: {
              normal: {
                areaColor: "#cecece", //区域背景颜色
                borderWidth: 0.8, //区域边框宽度
                borderColor: "#F6F6F6", //区域边框颜色
              },
              emphasis: {
                areaColor: "#E5ABA6", //鼠标悬停背景色
              },
            },
          },
        ],
      };
    },
    // 世界地图
    getOptionsWordMapFn(worldJson) {
      window.echarts.registerMap("world", worldJson);
      let dataMap = [];
      if (this.$store.state.userInfoObj.language == "zh") {
        dataMap = [
          { name: "俄罗斯", value: 11 },
          { name: "加拿大", value: 10 },
          { name: "中国", value: 50 },
          { name: "日本", value: 30 },
          { name: "澳大利亚", value: 60 },
        ];
      } else {
        dataMap = [
          { name: "Russia", value: 11 },
          { name: "Canada", value: 10 },
          { name: "China", value: 25 },
          { name: "Japan", value: 30 },
          { name: "Australia", value: 60 },
        ];
      }
      this.getOptionsWordMap = {
        // backgroundColor: "#02AFDB",
        // 图表主标题
        title: {
          top: 20, // 定位 值: 'top', 'middle', 'bottom' 也可以是具体的值或者百分比
          left: "center", // 值: 'left', 'center', 'right' 同上
          textStyle: { fontSize: 12 }, //提示标签字体颜色
        },
        // 提示框组件
        tooltip: {
          trigger: "item", // 触发类型, 数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用
          // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式
          // 使用函数模板  传入的数据值 -> value: number | Array
          formatter: (val) => this.worldMapShowFormatter(val),
        },
        // 视觉映射组件
        visualMap: {
          min: 0, // minValue 视觉映射组件的最小值
          max: this.mapMax, //  maxValue 视觉映射组件的最大值
          hoverLink: true, //鼠标悬浮到visualMap 组件上时,鼠标位置对应的数值 在图表中对应的图形元素,会高亮
          type: "piecewise", //类型
          splitNumber: 10, //平均分成多少段
          itemSymbol: "rect", //每个图例的数据
          borderWidth: 0, //每个图例的边框
          borderColor: "none",
          textGap: 5, //每个图例距离标签的长度
          itemGap: -3, //每个图例之间的长度
          itemWidth: 15, //每个图例的宽
          itemHeight: 12, //每个图例的高
          left: "0",
          // eslint-disable-next-line no-dupe-keys
          hoverLink: true,
          color: ["#ff830d", "#90d153", "#72d9aa", "#72d9aa", "#3281ff"],
          pieces: [
            { gt: 0, lte: 6, label: "6" },
            { gt: 6, lte: 12, label: " " },
            { gt: 12, lte: 18, label: " " },
            { gt: 18, lte: 24, label: "24" },
            { gt: 24, lte: 30, label: " " },
            { gt: 30, lte: 36, label: " " },
            { gt: 36, lte: 42, label: "42" },
            { gt: 42, lte: 48, label: " " },
            { gt: 48, lte: 54, label: " " },
            { gt: 54, lte: 60, label: "60" },
          ],
        },
        series: [
          {
            name: "使用情况",
            type: "map",
            mapType: "world",
            // roam: true,//缩放事件
            // mapLocation: { y: 100 },
            data: dataMap, //绑定数据
            symbolSize: 12,
            label: {
              normal: {
                show: false,
                textStyle: {
                  color: "#000",
                },
              },
              emphasis: { show: true },
            },
            itemStyle: {
              normal: {
                areaColor: "#cecece", //区域背景颜色
                borderWidth: 0.8, //区域边框宽度
                borderColor: "#F6F6F6", //区域边框颜色
              },
              emphasis: {
                areaColor: "#E5ABA6", //鼠标悬停背景色
              },
            },
          },
        ],
      };
    },
    // 地图热力图
    getOptionsHeatMapFn(chinaMap) {
      window.echarts.registerMap("china", chinaMap, {});
      let mapdata = [];
      if (this.$store.state.userInfoObj.language == "zh") {
        mapdata = [
          { name: "黑龙江", value: 15 },
          { name: "辽宁", value: 110 },
          { name: "山东", value: 74 },
          { name: "山西", value: 109 },
          { name: "安徽", value: 35 },
          { name: "浙江", value: 88 },
        ];
      } else {
        mapdata = [
          { name: "Heilongjiang", value: 110 },
          { name: "Liaoning", value: 63 },
          { name: "Shandong", value: 20 },
          { name: "Shanxi", value: 67 },
          { name: "Anhui", value: 45 },
          { name: "Zhejiang", value: 5 },
        ];
      }
      
      this.getOptionsHeatMap = {
        tooltip: {
          trigger: "item",
          textStyle: { fontSize: 12 }, //提示标签字体颜色
          formatter: (val) => this.worldMapShowFormatter(val),
        },
        //视觉映射组件
        visualMap: {
          min: 0, // minValue 视觉映射组件的最小值
          max: this.mapMax, //  maxValue 视觉映射组件的最大值
          hoverLink: true, //鼠标悬浮到visualMap 组件上时,鼠标位置对应的数值 在图表中对应的图形元素,会高亮
          type: "piecewise", //类型
          splitNumber: 10, //平均分成多少段
          itemSymbol: "rect", //每个图例的数据
          borderWidth: 0, //每个图例的边框
          borderColor: "none",
          textGap: 5, //每个图例距离标签的长度
          itemGap: -3, //每个图例之间的长度
          itemWidth: 15, //每个图例的宽
          itemHeight: 12, //每个图例的高
          left: "0",
          // eslint-disable-next-line no-dupe-keys
          hoverLink: true,
          color: ["#ff830d", "#90d153", "#72d9aa", "#72d9aa", "#3281ff"],
          pieces: [
            { gt: 0, lte: 11, label: "11" },
            { gt: 11, lte: 22, label: " " },
            { gt: 22, lte: 33, label: " " },
            { gt: 33, lte: 44, label: "44" },
            { gt: 44, lte: 55, label: " " },
            { gt: 55, lte: 66, label: " " },
            { gt: 66, lte: 77, label: "77" },
            { gt: 77, lte: 88, label: " " },
            { gt: 88, lte: 99, label: " " },
            { gt: 99, lte: 110, label: "110" },
          ],
        },
        series: [
          {
            type: "map", //mapTypes可选"scatter"或"map",即选择散点图模式或地图模式
            mapType: "china", //"china",
            coordinateSystem: "geo",
            symbolSize: 14,
            label: {
              normal: {
                formatter: "", //"{b}:{c}"城市模式下在地图上显示该城市名称
                textStyle: {
                  color: "#000",
                },
                show: false,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#cecece", //区域背景颜色
                borderWidth: 0.8, //区域边框宽度
                borderColor: "#F6F6F6", //区域边框颜色
              },
              emphasis: {
                areaColor: "#E5ABA6", //鼠标悬停背景色
              },
            },

            data: mapdata,

            // [
            //   { name: "厦门", value: [118.1, 24.46, 183] },
            //   { name: "武汉", value: [114.31, 30.52, 199] },
            //   { name: "焦作", value: [113.21, 35.24, 56] },
            //   { name: "杭州", value: [120.19, 30.26, 98] },
            //   { name: "安阳", value: [114.35, 36.1, 109] },
            //   { name: "南阳", value: [112.53, 30.26, 33] },
            // ],
          },
        ],
      };
    },
    // 矩阵树形图
    getMatrixTreeFn() {
      let labelName = this.$i18n.t("label.tag.manage.record");
      this.matrixTreeOptions = {
        color: ["#7F8DE1", "#ECEFFF"],
        tooltip: {
          formatter: function (params) {
            return `
            ${params.data.name}</br>
            ${labelName}： <b> ${params.data.value}</b> `;
          },
        },
        // 视觉映射组件
        // visualMap: {
        //   max: 400,
        //   min: 0, // minValue 视觉映射组件的最小值
        //   type: "continuous", // continuous 类型为连续型  piecewise 类型为分段型
        //   inRange: {
        //     color: ["#ECEFFF", "#7F8DE1"],
        //   }, //定义 在选中范围中 的视觉元素
        //   hoverLink: true, //鼠标悬浮到visualMap 组件上时,鼠标位置对应的数值 在图表中对应的图形元素,会高亮
        //   realtime: false, // 拖拽时，是否实时更新
        //   calculable: false, // 是否显示拖拽用的手柄
        //   itemWidth: 16, //图形的宽度，即长条的宽度
        //   itemHeight: 100, //图形的高度，即长条的高度
        //   left: "4%",
        //   bottom: "4%",
        //   precision: 0, //显示小数位
        //   orient: "vertical", // visualMap 组件，水平（'horizontal'）或者竖直（'vertical'）。
        //   text: [400, 0], // 文本，默认为数值文本
        // },
        series: [
          {
            type: "treemap",
            roam: false, //鼠标滚动是否缩放
            width: "70%",
            height: "80%",
            top: "5%",
            label: {
              color: "#333333",
              fontSize: "12",
              borderColor: "#333",
              borderType: "solid",
            },
            select: {
              label: {
                borderColor: "#333",
                borderType: "solid",
              },
            },
            breadcrumb: {
              //是否显示图例
              show: false,
            },
            data: [
              {
                name: "员工2",
                value: 100,
              },
              {
                name: "员工1",
                value: 150,
              },
              {
                name: "系统管理员",
                value: 400,
              },
            ],
          },
        ],
      };
    },
    //雷达图
    getRadarMapFn() {
      this.radarMapOptions = {
        title: {
          text: "",
        },
        legend: {
          data: ["Cindy", "Tom"],
          symbolHeight: 10,
          symbolWidth: 10,
          symbolRadius: 0,
          top: "90%",
          width: "90%",
        },
        radar: {
          indicator: [
            { name: "Monday", max: 6500 },
            { name: "Tuesday", max: 16000 },
            { name: "Wednesday", max: 30000 },
            { name: "Thursday", max: 38000 },
            { name: "Friday", max: 52000 },
          ],
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: ["#ccc"], // 图表背景网格线的颜色
            },
          },
        },
        series: [
          {
            name: "",
            type: "radar",
            data: [
              {
                value: [4200, 3000, 20000, 35000, 50000, 18000],
                name: "Cindy",
              },
              {
                value: [5000, 14000, 28000, 26000, 42000, 21000],
                name: "Tom",
              },
            ],
          },
        ],
      };
    },
    // 气泡图
    getBubbleChartFn() {
      let labelName = this.$i18n.t("label.tag.manage.record");
      let nameData = ["系统管理员", "员工1"];
      const data = [[[40, 70, 179]], [[170, 150.7, 105]]];
      this.bubbleChartOptions = {
        tooltip: {
          position: "top",
          formatter: function (params) {
            return `
             ${nameData[params.componentIndex]}</br>
            ${labelName}： <b> ${params.data[2]}</b> `;
          },
        },
        xAxis: {},
        yAxis: {
          scale: true,
        },
        legend: {
          show: true,
          type: "scroll",
          orient: "horizontal",
          bottom: 10,
          width: "90%",
        },
        colorBy: "series",
        color: [
          "#7F8DE1",
          "#F99B4A",
          "#4087F2",
          "#59B9FF",
          "#2CC7BE",
          "#93D053",
          "#F6CA25",
          "#DA4E2E",
        ],
        series: [
          {
            name: nameData[0],
            data: data[0],
            type: "scatter",
            symbolSize: function () {
              return 15;
            },
            emphasis: {
              focus: "self",
            },
            labelLayout: {
              y: 20,
              align: "center",
            },
            labelLine: {
              show: true,
              length2: 5,
              lineStyle: {
                color: "#bbb",
              },
            },
          },
          {
            name: nameData[1],
            data: data[1],
            type: "scatter",
            symbolSize: function () {
              return 20;
            },
            emphasis: {
              focus: "self",
            },
            labelLayout: {
              y: 20,
              align: "center",
            },
            labelLine: {
              show: true,
              length2: 5,
              lineStyle: {
                color: "#bbb",
              },
            },
          },
        ],
      };
    },
    //目标图
    getTargetFn() {
      let numbers = this.$i18n.t("label.file.num");
      let tarGet = this.$i18n.t(
        "front-lightning-module-v1-dashborad-target-value"
      );
      this.targetOptions = {
        xAxis: {
          max: 500, //重点: 要比400大
        },
        yAxis: {
          type: "category",
          data: [numbers],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          right: "10%",
          left: "1%",
          top: "25%",
          bottom: "15%",
          containLabel: true,
        },
        series: [
          {
            name: numbers,
            type: "bar",
            itemStyle: {
              normal: {
                color: "#808CE1", //bar颜色
              },
            },
            data: [270],
            markLine: {
              data: [
                {
                  name: tarGet,
                  xAxis: 400,
                },
              ],
              lineStyle: {
                type: "solid",
                color: "#F99B4A",
              },
              silent: true,
              symbolSize: 1,
              label: {
                show: true,
                position: "end", //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                formatter: function () {
                  return `${tarGet}：270`;
                },
                rotate: 0,
                distance: 0,
              },
            },
          },
        ],
      };
    },
    //双轴图
    getBiaxialFigureFn() {
      // 金额，可能性
      let money = this.$i18n.t("label.stdashbord.xsld.business.jine");
      let possibility = this.$i18n.t("label.forecast.forehistory.possibility");
      this.biaxialFigureOptions = {
        title: {
          text: "",
        },
        grid: {
          top: "16%",
          left: "3%",
          right: "4%",
          bottom: "4%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: money,
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: possibility,
            min: 0,
            max: 25,
            interval: 5,
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            name: money,
            type: "bar",
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0,
              2.3,
            ],
          },
          {
            name: possibility,
            type: "line",
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#FCB95C", //圈圈的颜色
                lineStyle: {
                  color: "#FCB95C", //线的颜色
                },
              },
            },
            data: [
              2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
            ],
          },
        ],
      };
    },
    //同环比双轴图
    getDiagramFn() {
      let grow = this.$i18n.t("c12");
      let record = this.$i18n.t("label.tag.manage.record");
      this.diagramOptions = {
        title: {
          text: "",
        },
        grid: {
          top: "16%",
          left: "3%",
          right: "4%",
          bottom: "4%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["Wed", "Thu", "Fri", "Sat", "Sun"],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: record,
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
              formatter: "{value} ",
            },
          },
          {
            type: "value",
            name: grow,
            min: 0,
            max: 25,
            interval: 5,
            axisLabel: {
              formatter: "{value} %",
            },
          },
        ],
        series: [
          {
            name: "记录数",
            type: "bar",
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4,
              3.3,
            ],
          },
          {
            name: "同比增长",
            type: "line",
            yAxisIndex: 1,
            data: [
              2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
            ],
          },
          {
            name: "环比增长",
            type: "line",
            yAxisIndex: 1,
            data: [
              2.5, 1.2, 2.3, 3.5, 4.3, 15.2, 23.3, 24.4, 28.0, 15.5, 11.0, 7.2,
            ],
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: calc(100% - 20px);
  min-height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  // 同环比报表
  .sameTable {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 10px 0;
  }
}
.reference {
  font-weight: bold;
  color: #333333;
}
.headClass {
  background: red;
  color: #fff;
}
.myChart {
  width: 100%;
  height: 100%;
  // margin: 5%;
}
.myNumber {
  text-align: center;
  font-size: 60px;
  color: #7f8de1;
  height: 300px;
  width: 100%;
  line-height: 300px;
}
::v-deep .el-table {
  flex: 0 1 auto;
}
// 数字图（环比）样式
.digitalMap {
  width: 100%;
  height: 220px;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  .now-date,
  .last-date {
    padding: 0 10px;
    color: #6c7792;
    font-size: 20px;
  }
  .now-data {
    padding: 0 20px 0 10px;
    color: #333948;
    font-size: 30px;
    font-weight: bold;
  }
  .last-data {
    padding: 0 20px 0 10px;
    color: #6c7792;
    font-size: 20px;
  }
  .greenDiv {
    width: 0;
    height: 0;
    margin-top: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 18px solid #56ab53;
  }
  .nowMonth {
    display: flex;
    justify-content: center;
    .ratio-data {
      font-size: 18px;
      padding: 0 10px;
    }
  }
  .MonthClass {
    color: #6c7792;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
