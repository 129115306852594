<!--
  组件编辑器弹框：一共16种类型的图表
  表格图表类型又可以细分为两种类型：不带分组的tableDetail、带有分组的report_summary
  关于表格图表需要做的操作：
    1.在提交表单时需要判断是否有分组，然后转换成对应的类型report_summary；tableDetail => tableDetail、report_summary
    2.在新增或是修改成功之后编辑回显时，需要判断是否是类型为report_summary，此时需要转换为tableDetail；report_summary => tableDetail
    3.在显示图表时，直接根据后端返回的类型进行对应的回显即可。
-->
<template>
  <el-dialog
    :title="$t('label.dashboardSetup.title')"
    :visible="isShowComponentsEditorAlert"
    v-if="isShowComponentsEditorAlert"
    width="950px"
    :before-close="cancel"
    :close-on-click-modal="false"
  >
    <div class="component-edit-box">
      <div class="component-left">
        <!-- 数据源和格式化 -->
        <div class="bottom_infos">
          <!-- 数据源 -->
          <div class="data_source">
            <Com-template-data-source
              ref="ComTemplateDataSourceRef"
              :isAddToHomePage="isAddToHomePage"
              :moreConditionFlag="moreConditionFlag"
              :rules="rules"
              :isGetFocus="isGetFocus"
              :chartInfo="chartInfo"
              :useLocation="useLocation"
              :component-type="componentType"
              :sourceType="sourceType"
              :reportIdFromReport="reportIdFromReport"
              :reportInfo="reportInfo"
              :isShowSetUpComponents="isShowSetUpComponents"
              :recordnum="recordnums"
              @changeSourceTypes="changeSourceTypes"
              @modifyisGetFocus="modifyisGetFocus"
              @changeDateTime="changeDateTime"
              @tableDetailGetId="tableDetailGetId"
              @formDetailGetId="formDetailGetId"
              @chooseViewReal="chooseViewReal"
              @changeReportSource="changeReportSource"
            />
          </div>
          <!-- 格式化：热力图不需要格式化 -->
          <div
            class="formatting"
            v-show="
              componentType !== 'usaMap' &&
              componentType !== 'worldMap' &&
              componentType !== 'heatMap' &&
              componentType !== 'sequential' &&
              componentType !== 'britainMap' &&
              componentType !== 'singaporeMap' &&
              componentType !== 'mexicoMap' &&
              componentType !== 'canadaMap' &&
              componentType !== 'franceMap' &&
              componentType !== 'germanyMap' &&
              componentType !== 'australiaMap' &&
              moreConditionFlag
            "
          >
            <!-- <div class="top_title">
              {{ $t("label.dashboardSetup.geshihua") }}
            </div> -->
            <Com-template-format
              ref="ComTemplateFormatRef"
              :rules="rules"
              :component-type="componentType"
              :chartInfo="chartInfo"
              @chooseViewReal="chooseViewReal"
            />
          </div>
          <div
            class="more-condition"
            v-if="
              !moreConditionFlag &&
              componentType !== 'usaMap' &&
              componentType !== 'worldMap' &&
              componentType !== 'heatMap' &&
              componentType !== 'britainMap' &&
              componentType !== 'singaporeMap' &&
              componentType !== 'mexicoMap' &&
              componentType !== 'canadaMap' &&
              componentType !== 'franceMap' &&
              componentType !== 'germanyMap' &&
              componentType !== 'australiaMap' &&
              componentType !== 'sequential'
            "
            @click="moreCondition"
          >
            <!-- 更多条件 -->
            {{ $t("label.dashboard.more.condition") }}
          </div>
          <div
            class="more-condition"
            v-if="
              moreConditionFlag &&
              componentType !== 'usaMap' &&
              componentType !== 'worldMap' &&
              componentType !== 'heatMap' &&
              componentType !== 'britainMap' &&
              componentType !== 'singaporeMap' &&
              componentType !== 'mexicoMap' &&
              componentType !== 'canadaMap' &&
              componentType !== 'franceMap' &&
              componentType !== 'germanyMap' &&
              componentType !== 'australiaMap' &&
              componentType !== 'sequential'
            "
            @click="moreCondition"
          >
            {{ $t("label.dashboard.away.condition") }}
            <!-- 收起条件 -->
          </div>
        </div>
      </div>
      <div class="component-right">
        <!-- 选择组件类型 -->
        <div class="choose_type">
          <div class="chart-type">
            <div class="name">{{ $t("label.picandreporttype") }}</div>
            <div class="chart-tab">
              <el-tabs
                v-model="chartsTypeActive"
                type="card"
                @tab-click="handleClick"
              >
                <!-- 比较 ，地图，趋势，占比-->
                <el-tab-pane
                  :label="$t('label.dashboard.compare')"
                  name="compare"
                >
                </el-tab-pane>
                <el-tab-pane
                  :label="$t('label.icon.172')"
                  name="distribution"
                ></el-tab-pane>
                <el-tab-pane
                  :label="$t('label.dashboard.trend')"
                  name="trend"
                ></el-tab-pane>
                <el-tab-pane
                  :label="$t('label.dashboard.xsld.percent')"
                  name="proportion"
                ></el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="all_components">
            <!-- 以下情况不能使用部分图表：在报表中添加图表且图表类型为指针、统计数字、表格图表。其他情况下均可 -->
            <!--有些svg不能转成icon，分开处理  v-show: item.id === 'tableDetail'  -->
            <div
              class="each_component"
              v-for="(item, index) in allCharts"
              :key="index"
              @click="chooseComponen(item)"
              :class="{
                active:
                  componentType === item.id ||
                  (item.id == 'tableDetail' &&
                    componentType == 'report_summary'),
              }"
              v-show="
                !(
                  useLocation === 'report' &&
                  (item.id === 'gauge' || item.id === 'number')
                ) &&
                showChartstype(item) &&
                isShowDigital(item)
              "
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.label"
                placement="top"
              >
                <div>
                  <img
                    v-if="item.id === 'canadaMap'"
                    src="@/assets/images/map/Canada.svg"
                  />
                  <img
                    v-if="item.id === 'franceMap'"
                    src="@/assets/images/map/France.svg"
                  />
                  <img
                    v-if="item.id === 'heatMap'"
                    src="@/assets/images/map/heatMap.svg"
                  />
                  <svg class="icon" aria-hidden="true" v-if="item.id != 'canadaMap'&&item.id != 'franceMap'&&item.id != 'heatMap'">
                    <use :href="`#icon-${item.name}`"></use>
                  </svg>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
        <!-- 预览 参考图例 -->
        <div class="typeimg">
          <div class="top_title" v-if="!chartFlag">
            <!-- 图表展示 -->
            {{ $t("front-detail-module-v1-chart-display") }}
          </div>
          <div class="top_title" v-if="chartFlag">
            {{ $t("label.dashboardSetup.reference.legend") }}
          </div>
          <div class="real-chart">
            <CompreviewChat
              v-loading="
                componentType == 'worldMap' ||
                componentType == 'usaMap' ||
                componentType == 'britainMap' ||
                componentType == 'singaporeMap' ||
                componentType == 'mexicoMap' ||
                componentType == 'canadaMap' ||
                componentType == 'franceMap' ||
                componentType == 'germanyMap' ||
                componentType == 'australiaMap'
                  ? worldAndUsaMapLoading
                  : false
              "
              v-if="chartFlag"
              :componentType="componentType"
              @maploading="maploading"
            />
            <!-- 同环比图 -->
            <sequential-chart
              v-if="!chartFlag && componentType === 'sequential'"
              v-loading="realLoading"
              :sequentialChartId="sequentialReportId"
              @getloading="getloading"
            />
            <!-- 动态图表组件 -->
            <chartsComponent
              v-if="
                !chartFlag &&
                componentType !== 'tableDetail' &&
                componentType !== 'report_summary' &&
                componentType !== 'digital' &&
                componentType !== 'sequential' &&
                componentType !== 'number'
              "
              v-loading="
                componentType != 'worldMap' &&
                componentType != 'usaMap' &&
                componentType != 'britainMap' &&
                componentType !== 'singaporeMap' &&
                componentType !== 'mexicoMap' &&
                componentType !== 'canadaMap' &&
                componentType !== 'franceMap' &&
                componentType !== 'germanyMap' &&
                componentType !== 'australiaMap'
                  ? realLoading
                  : realLoading && worldAndUsaMapLoading
              "
              :dashboardtype="showChartInfos.dashboardtype"
              :isShowComponentsEditorAlert="isShowComponentsEditorAlert"
              :groupOneArr="showChartInfos.groupOneArr"
              :listChartData="showChartInfos.listChartData"
              :valueDataArr="showChartInfos.valueDataArr"
              :dataArr="showChartInfos.dataArr"
              :seriesArr="showChartInfos.seriesArr"
              :dimensionsArr="showChartInfos.dimensionsArr"
              :sourceArr="showChartInfos.sourceArr"
              :min="showChartInfos.min"
              :max="showChartInfos.max"
              :mid1="showChartInfos.mid1"
              :mid2="showChartInfos.mid2"
              :useLocation="useLocation"
              :isshowpercent="showChartInfos.isshowpercent"
              :isshowvalue="showChartInfos.isshowvalue"
              :mapGroupOneFieldLabelName="
                showChartInfos.mapGroupOneFieldLabelName
              "
              :mapGroupOneField="showChartInfos.mapGroupOneField"
              :mapSummaryFieldLabelName="
                showChartInfos.mapSummaryFieldLabelName
              "
              :mapSummaryWay="showChartInfos.mapSummaryWay"
              :objectLable="showChartInfos.objectLable"
              :reportName="showChartInfos.reportName"
              :unit="showChartInfos.unit"
              :showData="showData"
              @maploading="maploading"
            />
            <!-- 统计数字图表 -->
            <statistics-chart
              v-if="!chartFlag && componentType === 'number'"
              v-loading="realLoading"
              :statisticsValue="changeMicrometerLevel(showData.statisticsValue)"
              :colorValue="showData.colorValue"
              :unit="showData.chartInfo.unit"
            />
            <!-- 数字图 -->
            <digital-chart
              v-if="!chartFlag && componentType === 'digital'"
              v-loading="realLoading"
              :currentData="
                showData.digitalData ? showData.digitalData.currentData : ''
              "
              :growthRate="
                showData.digitalData ? showData.digitalData.growthRate : ''
              "
              :lastData="
                showData.digitalData ? showData.digitalData.lastData : ''
              "
              :chartInfo="showData.chartInfo"
            />

            <!-- 表格图表：分为两种，不带分组的tableDetail和带有分组的report -->
            <form-chart
              v-if="
                !chartFlag &&
                (componentType === 'tableDetail' ||
                  componentType === 'report_summary')
              "
              v-loading="realLoading"
              :dashboardtype="componentType"
              :tableData="showData.tableData"
              :colData="showData.colData"
              :mapGroupOneField="showData.mapGroupOneField || {}"
              :mapGroupTwoField="showData.mapGroupTwoField"
              :sourceType="showData.sourceType"
            />
          </div>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">{{ $t("button_partner_cancel") }}</el-button>
      <el-button
        type="primary"
        @click="affirm('confirm')"
        :loading="loadings"
        >{{ $t("label.ems.confirm") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import ComTemplateFormat from "./ComTemplateFormat";
import StatisticsChart from "./StatisticsChart"; // 统计数字图表
import FormChart from "./FormChart"; // 表格图表
import ComTemplateDataSource from "./ComTemplateDataSource";
import SequentialChart from "./SequentialChart"; // 同环比图
import CompreviewChat from "./CompreviewChat";
import chartsComponent from "@/components/Chart/chartsComponent.vue"; // 图表
import DigitalChart from "./DigitalChart"; // 数字图
import * as request from "../../api.js";
import debounce from "lodash.debounce";

export default {
  components: {
    ComTemplateFormat,
    ComTemplateDataSource,
    CompreviewChat,
    chartsComponent,
    StatisticsChart,
    FormChart,
    DigitalChart,
    SequentialChart,
  },
  props: {
    isShowSetUpComponents: {
      type: Boolean,
      default: false,
    },
    isShowComponentsEditorAlert: {
      type: Boolean,
      default: false,
    },
    // 从报表生成、从列表视图生成，决定了数据源
    sourceType: {
      type: String,
      default: "report",
    },
    temReportId: {
      // 报表id
      type: String,
      default: "",
    },
    dashboardy: {
      // y轴
      type: [String, Number],
      default: "",
    },
    dashboardid: {
      // 仪表板id
      type: String,
      default: "",
    },
    chartInfo: {
      // 图表基本信息
      type: Object,
      default: () => ({}),
    },
    // index页传递的页面布局，添加组件时判断最多添加组件数量使用
    watchLayoutArr: {
      type: Array,
      default: () => [],
    },
    // 在报表列表页添加仪表盘时传的报表id
    reportIdFromReport: {
      type: String,
      default: "",
    },
    reportInfo: {
      // 在报表编辑页添加图表时直接传过来图表信息
      type: Object,
      default: () => {},
    },
    chartInfos: {
      // 根据报表获取的图表已选择的数据
      type: Object,
      default: () => {},
    },
    useLocation: {
      // 图表使用位置：报表、仪表板
      type: String,
      default: "",
    },
    isAddToHomePage: {
      // 是否将图表添加至主页
      type: Boolean,
      default: false,
    },
  },
  data() {
    // 对颜色范围的校验，两个输入框共用一个校验
    var validateColor = (rule, value, callback) => {
      if (
        !this.ComTemplateFormatObject.form.minValue ||
        !this.ComTemplateFormatObject.form.breakpointOneValue
      ) {
        // 两个值中只要有一个未填则校验不通过
        callback(
          new Error(
            this.$i18n.t("vue_label_dashboard_pleaseenterthecolorrange")
          )
        );
      } else {
        callback();
      }
    };
    // 对显示最大数值的校验
    var validateDisplayMaxValue = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$i18n.t("vue_label_dashboard_maximumvalue")));
      } else if (value > 100) {
        callback(new Error(this.$i18n.t("label.componentEditor.upper.limit")));
      } else {
        callback();
      }
    };
    // 最小值校验
    var validateMinValue = (rule, value, callback) => {
      const a = parseInt(this.ComTemplateFormatObject.form.breakpointOneValue);
      if (!value) {
        callback(this.$i18n.t("vue_label_dashboard_minimum"));
      } else if (value > 0 && value > a) {
        // callback('该值必须是正数且要小于断点1值');
        callback(this.$i18n.t("label.instrumentPanel.minLabelname"));
      } else {
        callback();
      }
    };
    // 断点1值校验
    var validateBreakpointOneValue = (rule, value, callback) => {
      const a = parseInt(this.ComTemplateFormatObject.form.minValue);
      if (!value) {
        callback(
          this.$i18n.t("vue_label_dashboard_pleaseenterabreakpoint1value")
        );
      } else if (value <= a) {
        // callback('该值要大于最小值');
        callback(this.$i18n.t("label.instrumentPanel.breakpointOneLabelname"));
      } else {
        callback();
      }
    };
    // 断点2值校验
    var validateBreakpointTwoValue = (rule, value, callback) => {
      const a = parseInt(this.ComTemplateFormatObject.form.breakpointOneValue);
      if (!value) {
        callback(
          this.$i18n.t("vue_label_dashboard_pleaseenterabreakpoint2value")
        );
      } else if (value <= a) {
        // callback('该值要大于最小值及断点1值');
        callback(this.$i18n.t("label.instrumentPanel.breakpointTwoLabelname"));
      } else {
        callback();
      }
    };
    // 最大值校验
    var validateMaxValue = (rule, value, callback) => {
      const a = parseInt(this.ComTemplateFormatObject.form.breakpointTwoValue);
      if (!value) {
        callback(this.$i18n.t("vue_label_dashboard_maximum"));
      } else if (value <= a) {
        // callback('该值要大于最小值、断点1值及断点2值');
        callback(this.$i18n.t("label.instrumentPanel.maxLabelname"));
      } else {
        callback();
      }
    };
    return {
      moreConditionFlag: false,
      chartsTypeActive: "compare", //图表类型
      realDataParams: {}, //实时动态传参
      allCharts: [
        // 比较类型图表
        {
          // 1没有第二个分组的条形图
          id: "bar_0",
          name: "crossBar",
          label: this.$i18n.t("label.projectManagement.bar.chart"), //'条形图'
        },
        {
          // 2有第二个分组的条形图
          id: "bar_1",
          name: "crossManyDiffBar",
          label: this.$i18n.t("c573"), //'二维条形图'
        },
        {
          // 3条形堆叠图
          id: "bar_duidie",
          name: "crossManySameBar",
          label: this.$i18n.t("c574"), //'堆砌条形图'//
        },
        {
          // 4没有第二个分组的柱状图
          id: "column_0",
          name: "verticalBar",
          label: this.$i18n.t("c575"), //'柱形图'//
        },
        {
          // 5有第二个分组的柱状图
          id: "column_1",
          name: "verticalManyDiffBar",
          label: this.$i18n.t("c576"), //'二维柱形图'
        },
        {
          // 6柱状堆叠图
          id: "column_duidie",
          name: "verticalManySameBar",
          label: this.$i18n.t("c577"), //'堆砌柱形图'//
        },
        {
          // 7 -- 数字图（同环比）
          id: "digital",
          name: "digitalMap",
          label: this.$i18n.t("c578"), //'同环比数字图'//
        },
        {
          // 8 -- 雷达图
          id: "radarMap",
          name: "radarMap",
          label: this.$i18n.t("c579"), //'雷达图'//
        },
        {
          // 9 -- 气泡图
          id: "bubble",
          name: "bubbleChart",
          label: this.$i18n.t("c580"), //'气泡图'//
        },
        {
          // 10 -- 同环比报表
          id: "sequential",
          name: "sequentialStatement",
          label: this.$i18n.t("c581"), //'同环比表格'//
        },
        // 地图
        {
          // 13世界地图
          id: "worldMap",
          name: "a-worldmap",
          label: this.$i18n.t("c582"), //'世界地图'//
        },
        // 地图类型
        {
          // 12热力图
          id: "heatMap",
          name: "heatMap",
          label: this.$i18n.t("c583"), //'中国地图'//
        },
        {
          // 12美国地图
          id: "usaMap",
          name: "USA",
          label: this.$i18n.t("c584"), //'美国地图'//
        },
        {
          // 英国地图
          id: "britainMap",
          name: "UK",
          label: this.$i18n.t("c585"), //'英国地图'//
        },
        {
          // 新加坡地图
          id: "singaporeMap",
          name: "Singapore",
          label: this.$i18n.t("c586"), //'新加坡地图'//
        },
        {
          // 墨西哥地图
          id: "mexicoMap",
          name: "Mexico",
          label: this.$i18n.t("c587"), //'墨西哥地图'//
        },
        {
          // 加拿大地图
          id: "canadaMap",
          name: "Canada",
          label: this.$i18n.t("c588"), //'加拿大地图'//
        },
        {
          // 法国地图
          id: "franceMap",
          name: "FR",
          label: this.$i18n.t("c589"), //'法国地图'//
        },
        {
          // 德国地图
          id: "germanyMap",
          name: "Germany",
          label: this.$i18n.t("c590"), //'德国地图'//
        },
        {
          // 澳大利亚地图
          id: "australiaMap",
          name: "Australia",
          label: this.$i18n.t("c591"), //'澳大利亚地图'//
        },
        // 趋势类型
        {
          // 11漏斗图
          id: "funnel",
          name: "funnel",
          label: this.$i18n.t("vue_label_commonobjects_view_chart_funnel"), //'漏斗图'//
        },
        {
          // 13没有第二个分组的柱状图类型中的瀑布图
          id: "waterfall",
          name: "waterfall",
          label: this.$i18n.t("c592"), //'瀑布图'//
        },
        {
          // 14 没有第二个分组的折线图
          id: "line_0",
          name: "oneLine",
          label: this.$i18n.t("c593"), //'折线图 '//
        },
        {
          // 15有第二个分组的折线图
          id: "line_1",
          name: "manyLine",
          label: this.$i18n.t("c594"), //'二维折线图'//
        },
        {
          // 16面积堆叠图
          id: "area",
          name: "backgroundLine",
          label: this.$i18n.t("c595"), //'面积图'//
        },
        {
          // 17 -- 双轴图
          id: "biaxialFigure",
          name: "biaxialFigure",
          label: this.$i18n.t("c596"), //'双轴图'//
        },
        {
          // 18 -- 同环比双轴图
          id: "biaxial",
          name: "simultaneousDiagram",
          label: this.$i18n.t("c603"), //'同环比双轴图'//
        },
        // 占比
        {
          // 19指针图
          id: "gauge",
          name: "halfRing",
          label: this.$i18n.t("c597"), //'目标仪表'//
        },
        {
          // 20饼图
          id: "pie",
          name: "pie",
          label: this.$i18n.t("label.pie"), //'饼图'//
        },
        {
          // 21圆环图
          id: "donut",
          name: "ring",
          label: this.$i18n.t("c598"), //'圆环图'//
        },
        {
          // 22表格图表
          id: "tableDetail",
          name: "list",
          label: this.$i18n.t("c599"), //'表格图'//
        },
        {
          // 23数字统计图
          id: "number",
          name: "word",
          label: this.$i18n.t("c600"), //'数字图'//
        },
        {
          // 24 -- 目标图
          id: "target",
          name: "targetFigure",
          label: this.$i18n.t("c601"), //'目标图'//
        },
        {
          // 25 -- 矩阵树形图
          id: "matrixTree",
          name: "matrixTree",
          label: this.$i18n.t("c602"), //'矩阵树图'//
        },
      ],
      componentType: "bar_0",
      chartId: "", // 图表id
      dashboardys: "", // y轴
      rules: {
        sourceTypeForm: [
          //请输入数据源,
          {
            required: true,
            message: this.$i18n.t("label_home_page_source"),
            trigger: "blur",
          },
        ],
        componentName: [
          //请输入组件名称,
          {
            required: true,
            message: this.$i18n.t("vue_label_homepage_componentname"),
            trigger: "blur",
          },
        ],
        // 目标图 -- 目标值
        targetValue: [
          {
            required: true,
            message: "请输入目标值",
            trigger: "blur",
          },
        ],
        reportId: [
          // 报表id
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_selectreport"),
            trigger: "blur",
          },
        ],
        objectId: [
          // 对象id
          {
            required: true,
            message: this.$i18n.t("vue_label_notice_pleaseselectobject"),
            trigger: "blur",
          },
        ],
        viewId: [
          // 视图id
          {
            required: true,
            message: this.$i18n.t("vue_label_notice_pleaseselecview"),
            trigger: "blur",
          },
        ],
        groupOne: [
          // 一级分组
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_group"),
            trigger: "blur",
          },
        ],
        groupOnedatetypeone: [
          // 一级分组的日期分组依据
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_dategruop"),
            trigger: "blur",
          },
        ],
        valueData: [
          // 统计值
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticalvalue"),
            trigger: "blur",
          },
        ],
        valueDataType: [
          // 统计类型
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticaltype"),
            trigger: "blur",
          },
        ],
        // 气泡图 统计值
        "moreBubbleData[0].moreValue": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticalvalue"),
            trigger: "blur",
          },
        ],
        "moreBubbleData[1].moreValue": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticalvalue"),
            trigger: "blur",
          },
        ],
        "moreBubbleData[2].moreValue": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticalvalue"),
            trigger: "blur",
          },
        ],
        // 气泡图 统计类型
        "moreBubbleData[0].moreType": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticaltype"),
            trigger: "blur",
          },
        ],
        "moreBubbleData[1].moreType": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticaltype"),
            trigger: "blur",
          },
        ],
        "moreBubbleData[2].moreType": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticaltype"),
            trigger: "blur",
          },
        ],
        // 双轴图 统计值
        "biaxialFigureMapData[0].moreValue": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticalvalue"),
            trigger: "blur",
          },
        ],
        "biaxialFigureMapData[1].moreValue": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticalvalue"),
            trigger: "blur",
          },
        ],
        // 双轴图 统计类型
        "biaxialFigureMapData[0].moreType": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticaltype"),
            trigger: "blur",
          },
        ],
        "biaxialFigureMapData[1].moreType": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticaltype"),
            trigger: "blur",
          },
        ],
        // 矩阵树形图 统计值
        "matrixTreeMapData[0].moreValue": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticalvalue"),
            trigger: "blur",
          },
        ],
        "matrixTreeMapData[1].moreValue": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticalvalue"),
            trigger: "blur",
          },
        ],
        // 矩阵树形图 统计类型
        "matrixTreeMapData[0].moreType": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticaltype"),
            trigger: "blur",
          },
        ],
        "matrixTreeMapData[1].moreType": [
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_statisticaltype"),
            trigger: "blur",
          },
        ],
        groupTwo: [
          // 二级分组
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_secondarygroup"),
            trigger: "blur",
          },
        ],
        groupTwodatetypetwo: [
          // 二级分组的日期分组依据
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_dategruop"),
            trigger: "blur",
          },
        ],
        unit: [
          // 显示单位
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_displayunit"),
            trigger: "blur",
          },
        ],
        colorRange: [
          // 颜色范围
          { required: true, validator: validateColor, trigger: "blur" },
        ],
        sortingCondition: [
          // 排序条件、排序字段
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_selectsortfield"),
            trigger: "blur",
          },
        ],
        sortingWay: [
          // 排序方式
          {
            required: true,
            message: this.$i18n.t("vue_label_dashboard_sortingmethod"),
            trigger: "blur",
          },
        ],
        displayMaxValue: [
          // 显示最大数值
          {
            required: true,
            validator: validateDisplayMaxValue,
            trigger: "blur",
          },
        ],
        minValue: [
          // 最小值
          {
            required: true,
            type: "number",
            validator: validateMinValue,
            trigger: ["blur", "change"],
          },
        ],
        breakpointOneValue: [
          // 断点1值
          {
            required: true,
            type: "number",
            validator: validateBreakpointOneValue,
            trigger: ["blur", "change"],
          },
        ],
        breakpointTwoValue: [
          // 断点2值
          {
            required: true,
            type: "number",
            validator: validateBreakpointTwoValue,
            trigger: ["blur", "change"],
          },
        ],
        maxValue: [
          // 最大值
          {
            required: true,
            type: "number",
            validator: validateMaxValue,
            trigger: ["blur", "change"],
          },
        ],
      },
      dashboardxs: "0", // x轴，默认为0，编辑时需要获取到再更改
      widths: "4", // 图表宽度，默认为4，编辑时需要获取到再更改
      heights: "4", // 图表高度，默认为4，编辑时需要获取到再更改
      linkageids: "", // 设置的所有联动图表id
      ComTemplateDataSourceObject: {}, // 数据源ref对象
      ComTemplateFormatObject: {}, // 格式化ref对象
      formDataSourceObject: {}, // 表格图表数据源ref对象
      loadings: false, // loading
      isGetFocus: false, //编辑时是否获取焦点
      chartFlag: true, //切换静态图表和实时图表
      realLoading: false, //实时图表loading
      worldAndUsaMapLoading: false, //世界地图和美国地图的loading
      // 图表展示所需要的信息数据
      showChartInfos: {
        dashboardtype: "", // 图表类型
        groupOneArr: [], // 一级分组数据
        listChartData: [], // 同环比双轴图返回数据
        listMaxFields: [], //双轴图最大值
        listMinFields: [], //双轴图最小值
        listSumFields: [], //双轴图总和
        mapCountField: [], //双轴图统计值数组
        valueDataArr: [], // 统计数据
        dataArr: [], // data数据
        seriesArr: [], // series数据
        dimensionsArr: [], // dimensions数据
        sourceArr: [], // source数据
        min: "", // 指针图的最小值
        max: "", // 指针图的最大值
        mid1: "", // 指针图的断点1值
        mid2: "", // 指针图的断点2值
        isshowpercent: "false", // 是否显示百分比
        isshowvalue: "false", // 是否显示数值
        mapGroupOneFieldLabelName: "", // 一级分组的字段label
        mapSummaryFieldLabelName: "", // 统计字段的label
        mapSummaryWay: "", // 统计类型
        objectLable: "", // 列表视图数据源对象名称
        reportName: "", // 报表数据源报表名称
        unit: "", // 图表选择的单位
      }, // 图表数据
      showData: {
        name: "", // 图表名称
        options: {}, // 图表options
        chartInfo: {}, // 图表基本信息
        reportInfo: {}, // 图表所选择的报表信息
        objectInfo: {}, // 图表所选择的对象信息
        viewInfo: {}, // 图表所选择的视图信息
        mapGroupOneField: {}, // 一级分组字段信息
        mapGroupTwoField: {}, // 二级分组字段信息
        mapSummaryField: {}, //=======
        statisticsValue: "", // number图表类型的统计值
        colorValue: "", // number图表类型具体的色值
        tableData: [], // 表格图表类型的表格行数据
        colData: [], // 表格图表类型的表格列数据
        sourceType: "", // 图表所使用的数据源：报表report、列表视图listView
        digitalData: {}, // 数字图类型的数据
        listMaxFields: [], //双轴图最大值
        listMinFields: [], //双轴图最小值
        listSumFields: [], //双轴图总和
        mapCountField: [], //双轴图统计值数组
      },
      reporttype: "Summary", //报表类型
      recordnums: "", // 目标值
      sequentialReportId: "", //同环比报表id
      // 报表生成方式
      dataSourceType:'report',
    };
  },
  computed: {
    // 判断显示是否显示 数字图(同环比) + 同环比报表图 + 同环比双轴图
    isShowDigital: function () {
      return (item) => {
        // 从报表生成 不显示 数字图(同环比)
        if (this.sourceType == "report" ||(this.dataSourceType == 'report'&&!this.sourceType)) {
          // 在报表中显示新增图表：
          // 1：1. 矩阵树形图2. 雷达图3. 气泡图4. 双轴图5. 目标图:屏蔽同环比报表
          // 2：同环比图表，同环比双轴图 ：同环比报表
          // 3: 报表图表中不显示同环比数字图
          // 4：新增世界地图，美国地图在报表中不显示
          if (this.reportInfo && this.reportInfo.reporttype === "ratio") {
            if (
              item.id == "matrixTree" ||
              item.id == "radarMap" ||
              item.id == "bubble" ||
              item.id == "biaxialFigure" ||
              item.id == "target" ||
              item.id == "digital" ||
              item.id == "usaMap" ||
              item.id == "worldMap" ||
              item.id == "britainMap" ||
              item.id == "singaporeMap" ||
              item.id == "mexicoMap" ||
              item.id == "canadaMap" ||
              item.id == "franceMap" ||
              item.id == "germanyMap" ||
              item.id == "australiaMap"
            ) {
              return false;
            } else {
              return true;
            }
          } else if (
            this.reportInfo &&
            this.reportInfo.reporttype !== "ratio"
          ) {
            if (
              item.id == "sequential" ||
              item.id == "biaxial" ||
              item.id == "digital" ||
              item.id == "usaMap" ||
              item.id == "worldMap" ||
              item.id == "britainMap" ||
              item.id == "singaporeMap" ||
              item.id == "mexicoMap" ||
              item.id == "canadaMap" ||
              item.id == "franceMap" ||
              item.id == "germanyMap" ||
              item.id == "australiaMap"
            ) {
              return false;
            } else {
              return true;
            }
          } else if (!this.reportInfo) {
            if (item.id == "digital") {
              return false;
            } else {
              return true;
            }
          }
        }
        // 从列表视图生成 不显示同环比报表图 + 同环比双轴图
        else if (this.sourceType == "listView" ||(this.dataSourceType == "listView"&&!this.sourceType)) {
          if (item.id == "sequential" || item.id == "biaxial") {
            return false;
          } else {
            return true;
          }
        } else {
          //从主页添加组件，显示图标
          return true;
        }
      };
    },
    // 当类型为表格图表时，判断具体有无分组
    tableDetailType() {
      if (
        this.componentType !== "tableDetail" &&
        this.componentType !== "report_summary"
      )
        return;
      if (
        JSON.stringify(this.$store.getters.getTableDetailGroupOneData) === "{}"
      ) {
        return "tableDetail";
      } else {
        return "report_summary";
      }
    },
    // 根据图表类型展示图表
    showChartstype: function (item) {
      item.constructor !== Object ? item : {};
      return (item) => {
        if (this.chartsTypeActive == "compare") {
          // 比较
          if (
            item.id == "bar_0" ||
            item.id == "bar_1" ||
            item.id == "bar_duidie" ||
            item.id == "column_0" ||
            item.id == "column_1" ||
            item.id == "column_duidie" ||
            item.id == "digital" ||
            item.id == "radarMap" ||
            item.id == "bubble" ||
            item.id == "sequential"
          ) {
            return true;
          }
        } else if (this.chartsTypeActive == "distribution") {
          //分布
          if (
            item.id == "heatMap" ||
            item.id == "usaMap" ||
            item.id == "worldMap" ||
            item.id == "britainMap" ||
            item.id == "singaporeMap" ||
            item.id == "mexicoMap" ||
            item.id == "canadaMap" ||
            item.id == "franceMap" ||
            item.id == "germanyMap" ||
            item.id == "australiaMap"
          ) {
            return true;
          }
        } else if (this.chartsTypeActive == "trend") {
          //趋势
          if (
            item.id == "funnel" ||
            item.id == "waterfall" ||
            item.id == "line_0" ||
            item.id == "line_1" ||
            item.id == "area" ||
            item.id == "biaxialFigure" ||
            item.id == "biaxial"
          ) {
            return true;
          }
        } else if (this.chartsTypeActive == "proportion") {
          //占比
          if (
            item.id == "gauge" ||
            item.id == "pie" ||
            item.id == "donut" ||
            item.id == "tableDetail" ||
            item.id == "report_summary" ||
            item.id == "number" ||
            item.id == "target" ||
            item.id == "matrixTree"
          ) {
            return true;
          }
        }
      };
    },
  },
  watch: {
    isShowComponentsEditorAlert: function () {
      if (this.isShowComponentsEditorAlert) {
        // 给全局数据源、格式化实例对象赋值
        setTimeout(() => {
          this.ComTemplateDataSourceObject =
            this.$refs.ComTemplateDataSourceRef;
          this.ComTemplateFormatObject = this.$refs.ComTemplateFormatRef;
          this.formDataSourceObject =
            this.$refs.ComTemplateDataSourceRef.$refs.formDataSourceRef;
        }, 0);
        // 先清空下拉内容
        setTimeout(() => {
          this.ComTemplateDataSourceObject.groupList = []; // 分组字段
          this.ComTemplateDataSourceObject.listView = []; // 视图
          this.ComTemplateDataSourceObject.statisticsFields = []; // 统计字段
          this.ComTemplateDataSourceObject.statisticsTypes = []; // 统计字段类型
          this.ComTemplateFormatObject.sortFields = []; // 排序字段
          this.ComTemplateFormatObject.sortingConditions = []; // 排序字段
          this.formDataSourceObject.chooseGroupFields = [];
          this.formDataSourceObject.chooseValueDataFileds = [];
        }, 0);
        this.$store.commit("setChartInfoObject", {});
        let e = {
          type: "empty",
        };
        this.$store.commit("setTableDetailGroupOneData", {});
        this.$store.commit("setTableDetailStatistical", e);
        this.$store.commit("setGroupOneLabelName", null);
        this.$store.commit("setValueDataLabelName", null);
        // 在清空排序字段之后，需要添加记录数
        setTimeout(() => {
          this.formDataSourceObject.statisticalRecordCount();
        }, 0);
        // 新建图表
        if (!this.chartInfo.id) {
          if (this.chartInfos && this.chartInfos.dashboardtype) {
            // 判断是否属于从报表添加图表时的回显，此时不具有图表id
            this.componentType = this.chartInfos.dashboardtype; //图表的类型
            this.chartsTypeChooseView(this.componentType);
            // this.chartInfos.dashboardtype === "report_summary"
            //   ? "tableDetail"
            //   : this.chartInfos.dashboardtype; //图表的类型
            setTimeout(() => {
              this.ComTemplateDataSourceObject.getSelectDatas(); // 获取图表选项内容
              if (this.chartInfos.dashboardtype === "report_summary") {
                // 若是有分组的表格图表，则存储图表数据
                this.$store.commit("setChartInfoObject", this.chartInfos);
                this.formDataSourceObject.groupFields =
                  this.reportInfo.groupList; //一级分组下拉数据
                // this.formDataSourceObject.changeGroupField(this.chartInfos.ycon); //已选分组字段
                //排序方式
                this.ComTemplateFormatObject.form.sortingCondition =
                  this.reportInfo.groupList[0].id;
                //统计字段
                this.formDataSourceObject.valueDataFileds =
                  this.reportInfo.DashboardListSummaryField;
              }
              if (
                this.chartInfos.dashboardtype === "report_summary" ||
                this.chartInfos.dashboardtype === "tableDetail"
              ) {
                // 排序字段对象赋值,默认选中第一个
                this.$bus.$emit(
                  "sortFieldsfn",
                  this.reportInfo.DashboardListCheckedFields
                );
              }

              if (this.chartInfos.dashboardtype === "tableDetail") {
                this.formDataSourceObject.groupFields =
                  this.reportInfo.groupList; //一级分组下拉数据

                //排序条件，排序字段
                this.ComTemplateFormatObject.form.sortingCondition =
                  this.reportInfo.dashboardsortcondition;
                //统计字段集合
                this.formDataSourceObject.valueDataFileds =
                  this.reportInfo.DashboardListSummaryField;
              }
              if (
                this.componentType !== "tableDetail" &&
                this.componentType !== "report_summary"
              ) {
                this.ComTemplateDataSourceObject.changeGroupOne(
                  this.chartInfos.ycon
                ); // 手动切换一级分组
                this.ComTemplateDataSourceObject.changeStatisticsField(
                  this.chartInfos.xcon
                ); // 手动切换统计字段
                this.ComTemplateDataSourceObject.changeGroupTwo(
                  this.chartInfos.xgather
                ); // 手动切换二级分组
              }
              // refs不是响应式的
              let form = this.ComTemplateDataSourceObject.form;
              this.$set(form, "groupOne", this.chartInfos.ycon); // 一级分组
              this.$set(form, "valueData", this.chartInfos.xcon); // 统计值
              this.$set(form, "groupTwo", this.chartInfos.xgather); // 二级分组
              this.$set(
                form,
                "groupOnedatetypeone",
                this.chartInfos.onegroupdatetype
              ); // 一级分组的日期分组依据
              this.$set(form, "valueDataType", this.chartInfos.summaryway); // 统计值类型
              this.$set(
                form,
                "groupTwodatetypetwo",
                this.chartInfos.twogroupdatetype
              ); // 二级分组的日期分组依据
              this.ComTemplateFormatObject.form = {
                unit: this.chartInfos.unit, //显示单位 ，aoto:selected;integral:整数单位;ten thousand:万;ten million:千万;billion:亿;
                sortingCondition: this.chartInfos.dashboardsortcondition, //排序条件，label:标签;value:数值
                sortingWay: this.chartInfos.dashboardsorttype, //排序方式，asc:升序;desc:降序;
                maxValue: this.chartInfos.max, //指针图表区域的最大值
                breakpointOneValue: this.chartInfos.mid1, //指针图表区域的第一中间值
                breakpointTwoValue: this.chartInfos.mid2, //指针图表区域的第二中间值
                minValue: this.chartInfos.min, //指针图表区域的最小值
                displayMaxValue: this.chartInfos.recordnum
                  ? this.chartInfos.recordnum
                  : "5", //显示最大记录数
                isshowvalue: this.chartInfos.isshowvalue, //格式化里面的显示数值，true:显示;false:不显示。
                isshowpercent: this.chartInfos.isshowpercent, //格式化里面的显示百分比，true:显示;false:不显示。
                dataLabel: ["1"], //数据标签，可多选，默认显示数值
              };
              this.recordnums =
                this.ComTemplateFormatObject.form.displayMaxValue;
              // 根据是否显示数值和百分比给dataLabel赋值
              this.ComTemplateFormatObject.form.dataLabel = [];
              if (
                this.chartInfos.isshowvalue === "true" ||
                this.chartInfos.isshowvalue === true
              ) {
                this.ComTemplateFormatObject.form.dataLabel.push("1");
              }
              if (
                this.chartInfos.isshowpercent === "true" ||
                this.chartInfos.isshowpercent === true
              ) {
                this.ComTemplateFormatObject.form.dataLabel.push("2");
              }
            }, 0);
            return;
          }
          this.isGetFocus = true;
          this.dashboardxs = "0";
          this.dashboardys = this.dashboardy;
          this.widths = "4";
          this.heights = "4";
          this.linkageids = "";
          this.componentType = "bar_0";
          setTimeout(() => {
            // refs不是响应式的
            let form = this.ComTemplateDataSourceObject.form;
            this.$set(form, "reportId", ""); // 报表id
            this.$set(form, "objectId", ""); // 对象id
            this.$set(form, "viewId", ""); // 视图id
            this.$set(form, "groupOne", ""); // 一级分组
            this.$set(form, "valueData", ""); // 统计值
            this.$set(form, "groupTwo", ""); // 二级分组
            this.$set(form, "groupOnedatetypeone", "month"); // 一级分组的日期分组依据
            this.$set(form, "valueDataType", ""); // 统计值类型
            this.$set(form, "groupTwodatetypetwo", "month"); // 二级分组的日期分组依据
            this.$set(form, "componentName", ""); // 图表名称
            this.ComTemplateFormatObject.form = {
              unit: "integral", // 显示单位
              sortingCondition: "label", // 排序条件value
              sortingWay: "desc", // 排序方式
              displayMaxValue: "5", // 显示最大数值
              minValue: "", //最小值
              breakpointOneValue: "", //断点1值
              breakpointTwoValue: "", //断点2值
              maxValue: "", //最大值
              dataLabel: ["1"], //数据标签，可多选，默认显示数值
            };
            this.chartId = "";
          }, 0);
          if (this.reportIdFromReport) {
            setTimeout(() => {
              // 从报表列表页将报表添加至仪表板
              this.ComTemplateDataSourceObject.form.reportId =
                this.reportIdFromReport;
              this.ComTemplateDataSourceObject.changeReportSource(
                this.reportIdFromReport
              );
            }, 0);
          }
        } else {
          // 回显图表
          this.dashboardxs = this.chartInfo.dashboardx;
          this.dashboardys = this.chartInfo.dashboardy;
          this.widths = this.chartInfo.width;
          this.heights = this.chartInfo.height;
          this.linkageids = this.chartInfo.linkageid;
          this.componentType = this.chartInfo.dashboardtype; //图表的类型
          // this.chartInfo.dashboardtype === "report_summary"
          //   ? "tableDetail"
          //   : this.chartInfo.dashboardtype; //图表的类型
          this.chartsTypeChooseView(this.componentType);
          setTimeout(async () => {
            // refs不是响应式的
            this.ComTemplateDataSourceObject.form.sourceTypeForm = this
              .chartInfo.reportid
              ? "report"
              : "listView";
            if (this.chartInfo.reportid) {
              // 属于报表数据源
              await this.ComTemplateDataSourceObject.changeReportSource(
                this.chartInfo.reportid
              ); // 手动切换报表获取分组字段和统计值
              if (this.componentType !== "tableDetail") {
                this.ComTemplateDataSourceObject.changeGroupOne(
                  this.chartInfo.ycon
                ); // 手动切换一级分组
                this.ComTemplateDataSourceObject.changeStatisticsField(
                  this.chartInfo.xcon
                ); // 手动切换统计字段
                this.ComTemplateDataSourceObject.changeGroupTwo(
                  this.chartInfo.xgather
                ); // 手动切换二级分组
              }
            } else {
              await this.ComTemplateDataSourceObject.changeObject(
                this.chartInfo.objectid
              ); // 手动切换对象获取对应对象下的所有视图
              await this.ComTemplateDataSourceObject.changeView(
                this.chartInfo.objectid
              ); // 手动切换视图获取对应对象下的分组字段和统计值
              this.ComTemplateDataSourceObject.changeGroupOne(
                this.chartInfo.ycon
              ); // 手动切换一级分组
              this.ComTemplateDataSourceObject.changeStatisticsField(
                this.chartInfo.xcon
              ); // 手动切换统计字段
            }
            if (this.chartInfo.dashboardtype === "report_summary") {
              // 若是有分组的表格图表，则存储图表数据
              this.$store.commit("setChartInfoObject", this.chartInfo);
            }
            // 同环比报表回显，调取接口数据
            if (this.componentType === "sequential") {
              this.sequentialReportId = this.chartInfo.reportid;
              this.chartFlag = false;
              this.realLoading = true;
            }
            let form = this.ComTemplateDataSourceObject.form;
            this.$set(
              form,
              "sourceTypeForm",
              this.chartInfo.reportid ? "report" : "listView"
            );
            this.$set(form, "reportId", this.chartInfo.reportid); // 报表id
            this.$set(form, "objectId", this.chartInfo.objectid); // 对象做为数据源时，存储对象的id。
            this.$set(form, "viewId", this.chartInfo.viewid); // 对象做为数据源时，存储对象视图的id。
            this.$set(form, "groupOne", this.chartInfo.ycon); // 一级分组
            this.$set(
              form,
              "groupOnedatetypeone",
              this.chartInfo.onegroupdatetype
            ); // 一级分组的日期分组依据
            this.$set(form, "valueData", this.chartInfo.xcon); // 统计值
            this.$set(form, "groupTwo", this.chartInfo.xgather); // 二级分组
            this.$set(
              form,
              "groupTwodatetypetwo",
              this.chartInfo.twogroupdatetype
            ); // 二级分组的日期分组依据
            this.$set(form, "valueDataType", this.chartInfo.summaryway); // 统计值类型
            this.$set(form, "componentName", this.chartInfo.name); // 报表名
            this.isGetFocus = false; //编辑时回显赋值时,不获取焦点，手动更改获取焦点
            this.ComTemplateFormatObject.form = {
              unit: this.chartInfo.unit, // 显示单位
              sortingCondition: this.chartInfo.dashboardsortcondition, // 排序条件
              sortingWay: this.chartInfo.dashboardsorttype, // 排序方式
              displayMaxValue: this.chartInfo.recordnum
                ? this.chartInfo.recordnum
                : "5", // 显示最大数值
              minValue: this.chartInfo.min, //最小值
              breakpointOneValue: this.chartInfo.mid1, //断点1值
              breakpointTwoValue: this.chartInfo.mid2, //断点2值
              maxValue: this.chartInfo.max, //最大值
              dataLabel: [], //数据标签，可多选
              biaxialFigureCheck:
                this.chartInfo.isagreement == "true" ? true : false,
              columnData:this.chartInfo.isshowvalue == "true" ? true : false,// 柱状图添加显示数据
            };
            this.recordnums = this.ComTemplateFormatObject.form.displayMaxValue;
            this.ComTemplateFormatObject.form.dataLabel = [];
            if (this.chartInfo.isshowvalue === "true"&&(
              this.chartInfo.dashboardtype=='gauge'||
              this.chartInfo.dashboardtype=='pie'||
              this.chartInfo.dashboardtype=='donut'
            )) {
              this.ComTemplateFormatObject.form.dataLabel.push("1");
            }
            if (this.chartInfo.isshowpercent === "true") {
              this.ComTemplateFormatObject.form.dataLabel.push("2");
            }
            this.chartId = this.chartInfo.id;
          }, 0);
        }
      }
    },
  },
  mounted() {
    // 表格图表清空排序字段时，清空一级分组数据
    this.$bus.$on("clearSortingCondition", this.ebFn1);
    // 表格图表清空排序字段时，修改表格图表类型
    this.$bus.$on("deleteGroup", this.ebFn2);
    // 动态刷新图表
    this.$bus.$on("formChooseViewReal", this.ebFn3);
    // 监听是否是新建报表时，使用组件编辑器，获取示例动态样式
    this.$bus.$on("setDynamicChartInfoDatas",this.ebFn4);
  },
  destroyed() {
    this.$bus.$off("clearSortingCondition",this.ebFn1);
    this.$bus.$off("deleteGroup",this.ebFn2);
    this.$bus.$off("formChooseViewReal",this.ebFn3);
    this.$bus.$off("setDynamicChartInfoDatas",this.ebFn4);
  },
  methods: {
    ebFn1(){
      this.$store.commit("setTableDetailGroupOneData", {}); //清空一级分组数据
    },
    ebFn2(){
      this.componentType = "tableDetail";
    },
    ebFn3(){
      this.realLoading = true;
      this.chooseViewReal();
    },
    ebFn4(value){
      this.chartFlag = true;
      // 回显tab
      this.setChartData(value);
    },
    // 在主页选择图表生成方式
    changeSourceTypes(value){
      this.dataSourceType=value;
    },
    // 修改isGetFocus值
    modifyisGetFocus() {
      this.isGetFocus = true;
    },
    // 地图需要加载完json才能去除loading
    maploading() {
      this.worldAndUsaMapLoading = false;
    },
    // 同环比接口请求完成取消loading
    getloading() {
      this.realLoading = false;
    },
    //切换日期范围时，周和月为环比，年为同比
    changeDateTime(value) {
      if (value == "year") {
        this.ComTemplateFormatObject.digitalValue = "同比";
      } else {
        this.ComTemplateFormatObject.digitalValue =
          this.$i18n.t("label_ring_ratio");
      }
    },
    // 数据源为报表时，此时报表类型 ratio:'同环比报表'
    changeReportSource(type) {
      this.reporttype = type;
    },
    // 在number图表类型中根据返回颜色范围获取对应颜色
    getColor(rangeOne, rangeTwo, valueData) {
      // 均转换为数字比较
      if (Number(valueData) < Number(rangeOne)) {
        // 小于第一个数值
        return "#7F8DE1";
      } else if (
        Number(valueData) >= Number(rangeOne) &&
        Number(valueData) < Number(rangeTwo)
      ) {
        // 大于等于第一个数值，小于第二个数值
        return "#2CC7BE";
      } else {
        // 大于等于第二个数值
        return "#4087F2";
      }
    },
    // 将数值转换为千分位
    changeMicrometerLevel(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
    // 新建图表，选择视图后触发,防抖
    chooseViewReal: debounce(function () {
      this.realLoading = true;
      if (this.componentType !== "sequential") {
        this.affirm();
      } else {
        // 同环比报表图，如果报表类型等于同环比报表且图表类型为同环比则执行传参，调取同环比接口否则显示假数据
        if (
          this.reporttype === "ratio" &&
          this.componentType === "sequential" &&
          this.ComTemplateDataSourceObject.form.reportId
        ) {
          this.sequentialReportId =
            this.ComTemplateDataSourceObject.form.reportId;
          this.chartFlag = false;
          this.realLoading = true;
          return;
        } else {
          this.chartFlag = true;
          this.sequentialReportId = "";
        }
      }
    }, 1000),
    // 实时数据接口
    async getChartDynamicDisplay() {
      let { data } = await request
        .getChartDynamicDisplay(this.realDataParams)
        .catch(() => {
          this.chartFlag = true;
          this.realLoading = false;
        });
      if (data) {
        this.setChartData(data);
      }
    },
    // 数据源不同时，对图表数据统一赋值
    setChartData(data) {
      let chartInfo = {}; // 图表信息
      // 动态获取数据有两种情况：仪表板和报表编辑中使用新接口获取图表数据
      // 报表的新建使用报表图表的接口获取图表数据
      if (this.useLocation === "report" && data.reportInfo && !data.chartInfo) {
        chartInfo = data.reportInfo;
        this.showData.reportInfo = data.reportInfo; // 获取报表基本信息
      } else {
        chartInfo = data.chartInfo;
        this.showData.chartInfo = data.chartInfo; // 获取图表基本信息
        this.showData.name = data.chartInfo.name; // 获取图表名称
      }
      this.componentType = chartInfo.dashboardtype;
      this.showData.refreshDate = data.refreshDate ? data.refreshDate : ""; // 获取图表刷新时间
      this.showData.objectInfo = data.objectInfo ? data.objectInfo : ""; // 获取对象基本信息
      this.showData.viewInfo = data.viewInfo ? data.viewInfo : ""; // 获取视图基本信息
      this.showData.mapGroupOneField = data.mapGroupOneField
        ? data.mapGroupOneField
        : ""; // 一级分组字段信息
      this.showData.mapGroupTwoField = data.mapGroupTwoField
        ? data.mapGroupTwoField
        : {}; // 二级分组字段信息
      this.showData.mapSummaryField = data.mapSummaryField
        ? data.mapSummaryField
        : "";
      this.showData.digitalData = data.digitalData; // 数字图的数据
      // 13种图表展示所需要的数据
      this.showChartInfos = {
        dashboardtype: chartInfo.dashboardtype,
        groupOneArr: data.groupOneArr,
        listChartData: data.listChartData,
        valueDataArr: data.valueDataArr,
        dataArr: data.data,
        seriesArr: data.series,
        dimensionsArr: data.dimensions,
        sourceArr: data.source,
        min: chartInfo.min,
        max: chartInfo.max,
        mid1: chartInfo.mid1,
        mid2: chartInfo.mid2,
        isshowpercent: chartInfo.isshowpercent,
        isshowvalue: chartInfo.isshowvalue,
        mapGroupOneFieldLabelName: data.mapGroupOneField
          ? data.mapGroupOneField.labelName
          : "",
        mapGroupOneField: data.mapGroupOneField,
        mapSummaryFieldLabelName: data.mapSummaryField
          ? data.mapSummaryField.labelName
          : "",
        mapSummaryWay: data.mapSummaryField
          ? data.mapSummaryField.summaryWay
          : "",
        objectLable: data.objectInfo ? data.objectInfo.label : "",
        reportName: data.reportInfo ? data.reportInfo.name : "",
        unit: chartInfo.unit,
      };
      this.chartFlag = false;
      this.realLoading = false;
      // 数字类型
      if (this.componentType === "number") {
        this.showData.statisticsValue = data.valueData;
        // 获取具体色值
        this.showData.colorValue = this.getColor(
          chartInfo.min,
          chartInfo.mid1,
          data.valueData
        );
        return;
      }
      // 双轴图类型
      if (this.componentType === "biaxialFigure") {
        this.showData.listAvgFields = data.listAvgFields;
        this.showData.listMaxFields = data.listMaxFields;
        this.showData.listMinFields = data.listMinFields;
        this.showData.listSumFields = data.listSumFields;
        this.showData.mapCountField = data.mapCountField;
        this.showData.mapGroupOneField = data.mapGroupOneField;
      }
      // 表格图表类型：没有分组tableDetail
      if (this.componentType === "tableDetail") {
        if (data.reportInfo && data.reportInfo.listCheckedFields) {
          // 报表数据源
          this.showData.sourceType = "report";
          // 无分组表格图表不显示记录数
          let objParses = [];
          data.reportInfo.listCheckedFields.map((item) => {
            if (item.schemefieldName !== "totalrecord") {
              objParses.push(item);
            }
          });
          this.showData.colData = objParses;
          // 判断表头中字段是否有复选框类型（B）字段，把对应字段的值转为是否
          let filedTypeB = []; //复选框字段
          data.reportInfo.listCheckedFields.map((item) => {
            if (item.schemefieldType == "B") {
              filedTypeB.push(item.schemefield_name_alias);
            }
          });
          if (filedTypeB?.length > 0) {
            let reportdata = [];
            data.listChartData.map((item) => {
              filedTypeB.map((filedItem) => {
                if (
                  item[filedItem] === "false" ||
                  item[filedItem] === "0" ||
                  item[filedItem] === "" ||
                  item[filedItem] === null ||
                  item[filedItem] == this.$i18n.t("label.tabpage.noz")
                ) {
                  item[filedItem] = this.$i18n.t("label.tabpage.noz");
                } else if (
                  item[filedItem] === "true" ||
                  item[filedItem] === "1" ||
                  item[filedItem] == this.$i18n.t("label.tabpage.yesz")
                ) {
                  item[filedItem] = this.$i18n.t("label.tabpage.yesz");
                }
              });
              reportdata.push(item);
            });
            this.showData.tableData = reportdata;
          } else {
            this.showData.tableData = data.listChartData;
          }
        } else {
          let dateField = []; //日期字段数组
          data.listViewFields?.map((item) => {
            if (item.schemefieldType == "D") {
              dateField.push(item.schemefieldName);
            }
          });
          if (dateField?.length > 0) {
            data.listChartData.map((item) => {
              dateField.map((fieldItem) => {
                if (item[fieldItem]) {
                  item[fieldItem] = item[fieldItem].substring(0, 11);
                }
              });
            });
          }
          // 列表视图数据源
          this.showData.sourceType = "listView";
          this.showData.colData = data.listViewFields;
          this.showData.tableData = data.listChartData;
        }
        return;
      }
      // 表格图表类型：带有分组：report
      if (this.componentType === "report_summary") {
        // schemefieldType == "B"：复选框类型字段把表格中的值转为是||否
        // schemefieldType == "D": 日期类型字段，接口返回值可能包含时间，去掉时间
        if (
          data.mapGroupOneField?.schemefieldType == "B" ||
          data.mapGroupTwoField?.schemefieldType == "B"
        ) {
          let fieldType = [];
          if (data.mapGroupOneField?.schemefieldType == "B") {
            fieldType.push(data.mapGroupOneField.schemefield_name_alias);
          }
          if (data.mapGroupTwoField?.schemefieldType == "B") {
            fieldType.push(data.mapGroupTwoField.schemefield_name_alias);
          }
          let reportdata = [];
          data.listChartData.map((item) => {
            fieldType.map((fielditem) => {
              if (
                item[fielditem] === "false" ||
                item[fielditem] === "0" ||
                item[fielditem] === null ||
                item[fielditem] === "" ||
                item[fielditem] === this.$i18n.t("label.tabpage.noz")
              ) {
                item[fielditem] = this.$i18n.t("label.tabpage.noz");
                reportdata.push(item);
              } else if (
                item[fielditem] === "true" ||
                item[fielditem] === "1" ||
                item[fielditem] === this.$i18n.t("label.tabpage.yesz")
              ) {
                item[fielditem] = this.$i18n.t("label.tabpage.yesz");
                reportdata.push(item);
              }
            });
          });
          this.showData.tableData = reportdata;
        } else if (
          data.mapGroupOneField?.schemefieldType == "D" ||
          data.mapGroupTwoField?.schemefieldType == "D"
        ) {
          let filedType = [];
          if (data.mapGroupOneField?.schemefieldType == "D") {
            filedType.push(data.mapGroupOneField.schemefield_name_alias);
          }
          if (data.mapGroupTwoField?.schemefieldType == "D") {
            filedType.push(data.mapGroupTwoField.schemefield_name_alias);
          }
          data.listChartData.map((item) => {
            filedType.map((filedItem) => {
              item[filedItem] = item[filedItem]?.substring(0, 11);
            });
          });
          this.showData.tableData = data.listChartData;
        } else if (data.reportInfo && data.reportInfo.listCheckedFields) {
          // 从报表生成仪表板
          // 判断表头中字段是否有复选框类型（B）字段，把对应字段的值转为是否
          let filedTypeB = []; //复选框字段
          data.reportInfo.listCheckedFields.map((item) => {
            if (item.schemefieldType == "B") {
              filedTypeB.push(item.schemefield_name_alias);
            }
          });
          if (filedTypeB?.length > 0) {
            let reportdata = [];
            data.listChartData.map((item) => {
              filedTypeB.map((filedItem) => {
                if (
                  item[filedItem] === "false" ||
                  item[filedItem] === "" ||
                  item[filedItem] === null ||
                  item[filedItem] === "0" ||
                  item[filedItem] == this.$i18n.t("label.tabpage.noz")
                ) {
                  item[filedItem] = this.$i18n.t("label.tabpage.noz");
                } else if (
                  item[filedItem] === "true" ||
                  item[filedItem] === "1" ||
                  item[filedItem] == this.$i18n.t("label.tabpage.yesz")
                ) {
                  item[filedItem] = this.$i18n.t("label.tabpage.yesz");
                }
              });
              reportdata.push(item);
            });
            this.showData.tableData = reportdata;
          } else {
            this.showData.tableData = data.listChartData;
          }
          this.showData.mapGroupOneField = {};
        } else {
          this.showData.tableData = data.listChartData;
        }
        // 分组类型表格图表，统计类型中记录数设置为不显示时，数据中不显示记录数
        if (data.reportInfo) {
          this.showData.colData = [];
          this.showData.colData.push(
            data.mapGroupOneField,
            data.mapGroupTwoField
          );
          if(data.reportInfo.summaryway.indexOf('count')==-1&&data.reportInfo.xcon.indexOf('totalrecord')==-1){
            let datalistSumFields=[];
            data.listSumFields&&data.listSumFields.map((item)=>{                     
              if(item.id!="totalrecord"){
                datalistSumFields.push(item)
              }
            })
            let datalistAvgFields=[];
            data.listAvgFields&&data.listAvgFields.map((item)=>{
              if(item.id!="totalrecord"){
                datalistAvgFields.push(item)
              }
            })
            let datalistMaxFields=[];
            data.listMaxFields&&data.listMaxFields.map((item)=>{
              if(item.id!="totalrecord"){
                datalistMaxFields.push(item)
              }
            })
            let datalistMinFields=[];
            data.listMinFields&&data.listMinFields.map((item)=>{
              if(item.id!="totalrecord"){
                datalistMinFields.push(item)
              }
          })
          this.showData.colData = this.showData.colData
            .concat(datalistAvgFields)
            .concat(datalistMaxFields)
            .concat(datalistMinFields)
            .concat(datalistSumFields);
        }else{
          this.showData.colData = this.showData.colData
            .concat(data.listAvgFields)
            .concat(data.listMaxFields)
            .concat(data.listMinFields)
            .concat(data.listSumFields);
        }
          // 报表数据源
          this.showData.sourceType = "report";
          this.showData.colData = this.showData.colData.filter((item) => item); // 过滤掉空值
        } else {
          // 列表视图数据源
          this.showData.sourceType = "listView";
          this.showData.colData = [];
          this.showData.colData.push(
            data.mapGroupOneField,
            data.mapGroupTwoField
          );
          this.showData.colData = this.showData.colData
            .concat(data.listAvgFields)
            .concat(data.listMaxFields)
            .concat(data.listMinFields)
            .concat(data.listSumFields)
            .concat(data.mapCountField);
          this.showData.colData = this.showData.colData.filter((item) => item); // 过滤掉空值
        }
        return;
      }
    },
    // 表格图表：从列表视图生成，排序方式默认创建时间
    tableDetailGetId(id) {
      this.ComTemplateFormatObject.form.sortingCondition = id;
    },
    // 表格图表：从报表生成，排序方式默认第一个字段
    formDetailGetId(id) {
      this.ComTemplateFormatObject.form.sortingCondition = id;
    },
    // 显示更多条件
    moreCondition() {
      this.moreConditionFlag = !this.moreConditionFlag;
    },
    // 根据图表类型区分，tab选中状态
    chartsTypeChooseView(data) {
      // 比较，分布，趋势，占比类型
      if (
        data == "bar_0" ||
        data == "bar_1" ||
        data == "bar_duidie" ||
        data == "column_0" ||
        data == "column_1" ||
        data == "column_duidie" ||
        data == "digital" ||
        data == "radarMap" ||
        data == "bubble" ||
        data == "sequential"
      ) {
        this.chartsTypeActive = "compare";
      } else if (
        data == "heatMap" ||
        data == "worldMap" ||
        data == "usaMap" ||
        data == "britainMap" ||
        data == "singaporeMap" ||
        data == "mexicoMap" ||
        data == "canadaMap" ||
        data == "franceMap" ||
        data == "germanyMap" ||
        data == "australiaMap"
      ) {
        this.chartsTypeActive = "distribution";
      } else if (
        data == "waterfall" ||
        data == "line_0" ||
        data == "line_1" ||
        data == "area" ||
        data == "biaxialFigure" ||
        data == "biaxial"
      ) {
        this.chartsTypeActive = "trend";
      } else if (
        data == "gauge" ||
        data == "pie" ||
        data == "donut" ||
        data == "tableDetail" ||
        data == "number" ||
        data == "target" ||
        data == "matrixTree" ||
        data == "report_summary"
      ) {
        this.chartsTypeActive = "proportion";
      }
    },
    // 切换tab,设置默认类型
    handleClick() {
      let data = {};
      // 比较，分布，趋势，占比类型
      if (this.chartsTypeActive == "compare") {
        data = {
          id: "bar_0",
          name: "crossBar",
        };
      } else if (this.chartsTypeActive == "distribution") {
        data = {
          id: "worldMap",
          name: "a-worldmap",
        };
      } else if (this.chartsTypeActive == "trend") {
        data = {
          id: "funnel",
          name: "funnel",
        };
      } else if (this.chartsTypeActive == "proportion") {
        // 如果是报表内打开组件编辑器，没有指针图，需要默认饼图
        if (this.useLocation === "report") {
          data = {
            id: "pie",
            name: "pie",
          };
        } else {
          data = {
            id: "gauge",
            name: "halfRing",
          };
        }
      }
      this.realLoading = true;
      this.chooseComponen(data);
    },
    // 选择组件类型
    chooseComponen(item) {
      this.componentType = item.id;
      if (
        this.componentType == "worldMap" ||
        this.componentType == "usaMap" ||
        this.componentType == "britainMap" ||
        this.componentType == "singaporeMap" ||
        this.componentType == "mexicoMap" ||
        this.componentType == "canadaMap" ||
        this.componentType == "franceMap" ||
        this.componentType == "germanyMap" ||
        this.componentType == "australiaMap"
      ) {
        this.worldAndUsaMapLoading = true;
      } else {
        this.worldAndUsaMapLoading = false;
      }
      /*
       * 报表列表数据过滤：
       * 1、屏蔽同环比报表：
       * 矩阵树形图、雷达图、气泡图、双轴图、目标图
       * 2、仅可选同环比报表：
       * 同环比图、同环比双轴图
       */
      if (
        this.reporttype === "ratio" &&
        (this.componentType === "matrixTree" ||
          this.componentType === "radarMap" ||
          this.componentType === "bubble" ||
          this.componentType === "biaxialFigure" ||
          this.componentType === "target")
      ) {
        this.ComTemplateDataSourceObject.form.reportId = "";
        this.ComTemplateDataSourceObject.form.componentName = "";
      }
      if (
        this.reporttype != "ratio" &&
        (this.componentType == "biaxial" || this.componentType === "sequential")
      ) {
        this.ComTemplateDataSourceObject.form.reportId = "";
        this.ComTemplateDataSourceObject.form.componentName = "";
      }
      // 同环比报表图，如果报表类型等于同环比报表且图表类型为同环比则执行传参，调取同环比接口否则显示假数据
      if (
        this.reporttype === "ratio" &&
        this.componentType === "sequential" &&
        this.ComTemplateDataSourceObject.form.reportId
      ) {
        this.sequentialReportId =
          this.ComTemplateDataSourceObject.form.reportId;
        this.chartFlag = false;
        this.realLoading = true;
        return;
      } else {
        this.chartFlag = true;
        this.sequentialReportId = "";
      }
      // 切换组件类型时，表格图表默认为tableDetail无分组
      if (this.componentType === "report_summary") {
        this.componentType = "tableDetail";
      }
      // 同环比报表不执行获取动态接口，直接调取同环比接口
      if (this.componentType !== "sequential") {
        this.realLoading = true;
        this.chartFlag = false;
        this.chooseViewReal();
      }
      // refs不是响应式的
      setTimeout(() => {
        // 每次切换类型清空格式化中的数据，根据类型对应赋值
        this.ComTemplateFormatObject.form = {
          unit: "integral", // 显示单位
          sortingCondition: "label", // 排序条件
          sortingWay: "desc", // 排序方式
          displayMaxValue: "5", // 显示最大数值
          minValue: "", //最小值
          breakpointOneValue: "", //断点1值
          breakpointTwoValue: "", //断点2值
          maxValue: "", //最大值
          dataLabel: ["1"], //数据标签，可多选，默认显示数值
        };
        // 从列表视图生成
        if (this.ComTemplateDataSourceObject.sourceTypeData === "listView") {
          // 切换图表类型时，如果选择对象为空自动获取焦点
          if (this.ComTemplateDataSourceObject.form.objectId === "") {
            this.$refs["ComTemplateDataSourceRef"].$refs[
              "chooseObject"
            ].focus();
          }
          // 二级分组图表,默认二级分组为创建时间,否则清空二级分组数据
          if (
            item.name == "manyLine" ||
            item.name == "verticalManySameBar" ||
            item.name == "crossManySameBar" ||
            item.name == "backgroundLine" ||
            item.name == "crossManyDiffBar" ||
            item.name == "verticalManyDiffBar"
          ) {
            this.ComTemplateDataSourceObject.groupList.map((item) => {
              if (item.schemefieldName == "createdate") {
                this.ComTemplateDataSourceObject.form.groupTwo = item.id;
                this.ComTemplateDataSourceObject.form.groupTwodatetypetwo =
                  "month";
              }
            });
          } else {
            this.ComTemplateDataSourceObject.form.groupTwo = "";
            this.ComTemplateFormatObject.form.groupOnedatetypeone = "";
          }
          //除了指针图和数值图，地图,一级分组默认所有人,排序条件=一级分组
          if (
            item.name != "word" &&
            item.name != "halfRing" &&
            item.name != "list" &&
            item.id != "heatMap" &&
            item.id != "worldMap" &&
            item.id != "usaMap" &&
            item.id != "britainMap" &&
            item.id != "singaporeMap" &&
            item.id != "mexicoMap" &&
            item.id != "canadaMap" &&
            item.id != "franceMap" &&
            item.id != "germanyMap" &&
            item.id != "australiaMap"
          ) {
            this.ComTemplateDataSourceObject.groupList.find((item) => {
              if (
                item.schemefieldName == "ownerid" &&
                !this.ComTemplateDataSourceObject.form.groupOne
              ) {
                // 当一级分组字段为空，获取一级分组字段集合及选择的一级分组字段id，存储进state中
                let e = {
                  groupList: this.ComTemplateDataSourceObject.groupList,
                  id: item.id,
                };
                this.$store.commit("setGroupOneLabelName", e);
                return (this.ComTemplateDataSourceObject.form.groupOne =
                  item.id);
              }
            });
          }

          // 从列表视图生成的时候排序字段=一级分组
          if (
            item.name != "word" &&
            item.name != "halfRing" &&
            item.name != "list" &&
            item.id != "heatMap" &&
            item.id != "worldMap" &&
            item.id != "usaMap" &&
            item.id != "britainMap" &&
            item.id != "singaporeMap" &&
            item.id != "mexicoMap" &&
            item.id != "canadaMap" &&
            item.id != "franceMap" &&
            item.id != "germanyMap" &&
            item.id != "australiaMap"
          ) {
            this.ComTemplateFormatObject.form.sortingCondition = "label";
          }
          // 数字图默认一级分组默认创建时间
          if (item.id === "digital") {
            this.ComTemplateDataSourceObject.groupList.map((item) => {
              if (item.schemefieldName == "createdate") {
                this.ComTemplateDataSourceObject.form.groupOne = item.id;
              }
            });
          }
          // 所有类型统计类型和统计值，默认记录数和总和
          if (
            this.ComTemplateDataSourceObject.statisticsFields &&
            this.ComTemplateDataSourceObject.statisticsFields.length > 0
          ) {
            this.ComTemplateDataSourceObject.statisticsFields.map((item) => {
              if (item.id === "totalrecord") {
                this.ComTemplateDataSourceObject.form.valueData = item.id;
                this.ComTemplateDataSourceObject.changeStatisticsField(item.id);
              }
            });
          }
          // 表格图表，排序字段默认创建时间倒序
          if (item.name == "list") {
            this.formDataSourceObject.chooseGroupFields = [];
            this.formDataSourceObject.chooseValueDataFileds = [];
            this.$store.commit("setTableDetailGroupOneData", {});
            this.ComTemplateFormatObject.sortFields.find((item) => {
              if (item.schemefieldName == "createdate") {
                return (this.ComTemplateFormatObject.form.sortingCondition =
                  item.id || item.schemefield_name_alias);
              }
            });
          }
          // 非表格图表：设置排序字段==一级分组
          if (
            item.name != "list" &&
            item.name != "word" &&
            item.name != "halfRing"
          ) {
            this.ComTemplateFormatObject.sortingConditions.find((item) => {
              if (item.schemefieldName == "ownerid") {
                return (this.ComTemplateFormatObject.form.sortingCondition =
                  item.id);
              }
            });
          }
        } else if (
          this.ComTemplateDataSourceObject.sourceTypeData === "report"
        ) {
          //从报表生成,没有选择报表数据源获取焦点
          if (
            this.ComTemplateDataSourceObject.form.reportId === "" &&
            this.ComTemplateDataSourceObject.form.sourceTypeForm === "report" &&
            this.temReportId &&
            this.useLocation != "report"
          ) {
            this.$refs["ComTemplateDataSourceRef"].$refs[
              "reportDataSource"
            ].focus();
          }
          // 当一级分组为空，一级分组默认第一个可选分组值，二级分组默认第二个可选分组值
          if (
            this.ComTemplateDataSourceObject.groupList &&
            this.ComTemplateDataSourceObject.groupList.length > 0 &&
            !this.ComTemplateDataSourceObject.form.groupOne &&
            item.name != "heatMap" &&
            item.name != "a-worldmap" &&
            item.name != "usaMap" &&
            item.name != "britainMap" &&
            item.id != "singaporeMap" &&
            item.id != "mexicoMap" &&
            item.id != "canadaMap" &&
            item.id != "franceMap" &&
            item.id != "germanyMap" &&
            item.id != "australiaMap"
          ) {
            this.ComTemplateDataSourceObject.form.groupOne =
              this.ComTemplateDataSourceObject.groupList[0].id;
            // 获取一级分组字段集合及选择的一级分组字段id，存储进state中
            let e = {
              groupList: this.ComTemplateDataSourceObject.groupList,
              id: this.ComTemplateDataSourceObject.groupList[0].id,
            };
            this.$store.commit("setGroupOneLabelName", e);
            // 带有二级分组的设置默认值
            if (
              (this.componentType == "line_1" ||
                this.componentType == "column_duidie" ||
                this.componentType == "bar_duidie" ||
                this.componentType == "area" ||
                this.componentType == "column_1" ||
                this.componentType == "bar_1") &&
              this.ComTemplateDataSourceObject.groupList.length > 1
            ) {
              this.ComTemplateDataSourceObject.form.groupTwo =
                this.ComTemplateDataSourceObject.groupList[1].id;
            }
          }
          // 统计值和统计类型
          if (
            this.ComTemplateDataSourceObject.statisticsFields &&
            this.ComTemplateDataSourceObject.statisticsFields.length > 0
          ) {
            this.ComTemplateDataSourceObject.form.valueData =
              this.ComTemplateDataSourceObject.statisticsFields[0].id;
            this.ComTemplateDataSourceObject.changeStatisticsField(
              this.ComTemplateDataSourceObject.form.valueData
            );
          }

          // 表格图表，分组字段
          if (item.name == "list") {
            this.$store.commit("setTableDetailGroupOneData", {}); //一级分组数据
            this.ComTemplateFormatObject.sortFields = []; // 排序字段
            this.ComTemplateFormatObject.sortingConditions = []; // 排序字段
            this.formDataSourceObject.chooseGroupFields = [];
            this.formDataSourceObject.chooseValueDataFileds = [];
            // 默认第一个字段倒序
            if (
              this.ComTemplateFormatObject.sortFields &&
              this.ComTemplateFormatObject.sortFields.length > 0
            ) {
              if (this.componentType === "tableDetail") {
                this.ComTemplateFormatObject.form.sortingCondition =
                  this.ComTemplateFormatObject.sortFields[0].id;
              }
              if (this.componentType === "report_summary") {
                this.ComTemplateFormatObject.form.sortingCondition =
                  this.ComTemplateFormatObject.sortFields[0].schemefield_name_alias;
              }
            }
          }
        }
        // 从报表或者列表视图生成，地图类型设置不同的一级分组
        // 1、热力地图默认为第一个地址省或者市字段
        // 2、世界地图默认第一个国家字段
        // 3、美国地图默认第一个地址省字段
        if (
          (this.ComTemplateDataSourceObject.sourceTypeData === "report" ||
            this.ComTemplateDataSourceObject.sourceTypeData === "listView") &&
          this.ComTemplateDataSourceObject.groupList.length > 0
        ) {
          this.ComTemplateDataSourceObject.groupList.find((heatitem) => {
            if (
              item.id == "heatMap" &&
              (heatitem.address == "province" || heatitem.address == "city")
            ) {
              this.ComTemplateDataSourceObject.form.groupOne = heatitem.id;
              return true;
            }
            if (item.id == "worldMap" && heatitem.address === "country") {
              this.ComTemplateDataSourceObject.form.groupOne = heatitem.id;
              return true;
            }
            if (
              (item.id == "usaMap" ||
                item.id == "britainMap" ||
                item.id == "singaporeMap" ||
                item.id == "mexicoMap" ||
                item.id == "canadaMap" ||
                item.id == "franceMap" ||
                item.id == "germanyMap" ||
                item.id == "australiaMap") &&
              heatitem.address == "province"
            ) {
              return (this.ComTemplateDataSourceObject.form.groupOne =
                heatitem.id);
            }
          });
        }

        // 指针图
        if (item.name == "halfRing") {
          this.ComTemplateFormatObject.form.minValue = "0"; //最小值
          this.ComTemplateFormatObject.form.breakpointOneValue = 100; //断点1值
          this.ComTemplateFormatObject.form.breakpointTwoValue = 1000; //断点2值
          this.ComTemplateFormatObject.form.maxValue = 10000; //断点2值
        }
        // 数字图
        if (item.name == "word") {
          this.ComTemplateFormatObject.form.minValue = 100; //最小值
          this.ComTemplateFormatObject.form.breakpointOneValue = 1000; //断点1值
        }
        // 多个统计类型和统计值
        let valueDataArr = "";
        let valueDataTypeArr = "";
        if (
          this.ComTemplateDataSourceObject.form.valueData &&
          this.ComTemplateDataSourceObject.form.valueData.indexOf(",") > -1
        ) {
          valueDataArr =
            this.ComTemplateDataSourceObject.form.valueData.split(",");
        }
        if (
          this.ComTemplateDataSourceObject.form.valueDataType &&
          this.ComTemplateDataSourceObject.form.valueDataType.indexOf(",") > -1
        ) {
          valueDataTypeArr =
            this.ComTemplateDataSourceObject.form.valueDataType.split(",");
        }
        // 雷达图
        if (this.componentType === "radarMap") {
          this.ComTemplateDataSourceObject.moreRadarMapData.map(
            (item, index) => {
              if (valueDataArr[index]) {
                this.$set(item, "moreValue", valueDataArr[index]);
                this.$set(
                  item,
                  "statisticsTypes",
                  this.ComTemplateDataSourceObject.getSelectValueTypeFn(
                    item.moreValue
                  )
                );
                this.$set(item, "moreType", valueDataTypeArr[index]);
              } else {
                this.$set(item, "moreValue", "");
                this.$set(item, "statisticsTypes", "");
                this.$set(item, "moreType", []);
              }
            }
          );
        }
        // 气泡图
        if (this.componentType === "bubble") {
          // 对form.moreBubbleData赋值
          this.ComTemplateDataSourceObject.form.moreBubbleData.map(
            (item, index) => {
              if (valueDataArr[index]) {
                this.$set(item, "moreValue", valueDataArr[index]);
                this.$set(
                  item,
                  "statisticsTypes",
                  this.ComTemplateDataSourceObject.getSelectValueTypeFn(
                    item.moreValue
                  )
                );
                this.$set(item, "moreType", valueDataTypeArr[index]);
              } else {
                this.$set(item, "moreValue", "");
                this.$set(item, "statisticsTypes", "");
                this.$set(item, "moreType", []);
              }
            }
          );
        }
        // 双轴图
        if (this.componentType === "biaxialFigure") {
          // 对form.moreBubbleData赋值
          this.ComTemplateDataSourceObject.form.biaxialFigureMapData.map(
            (item, index) => {
              if (valueDataArr[index]) {
                this.$set(item, "moreValue", valueDataArr[index]);
                this.$set(
                  item,
                  "statisticsTypes",
                  this.ComTemplateDataSourceObject.getSelectValueTypeFn(
                    item.moreValue
                  )
                );
                this.$set(item, "moreType", valueDataTypeArr[index]);
              } else {
                this.$set(item, "moreValue", "");
                this.$set(item, "statisticsTypes", "");
                this.$set(item, "moreType", []);
              }
            }
          );
          return;
        }
        // 矩阵树形图
        if (this.componentType === "matrixTree") {
          // 对form.moreBubbleData赋值
          this.ComTemplateDataSourceObject.form.matrixTreeMapData.map(
            (item, index) => {
              if (valueDataArr[index]) {
                this.$set(item, "moreValue", valueDataArr[index]);
                this.$set(
                  item,
                  "statisticsTypes",
                  this.ComTemplateDataSourceObject.getSelectValueTypeFn(
                    item.moreValue
                  )
                );
                this.$set(item, "moreType", valueDataTypeArr[index]);
              } else {
                this.$set(item, "moreValue", "");
                this.$set(item, "statisticsTypes", "");
                this.$set(item, "moreType", []);
              }
            }
          );
          return;
        }

        // /*
        //  * 报表
        //  * 1、有分组的表格图表，对分组字段赋值;
        //  * 2、无分组表格图表，对排序字段赋值;
        //  */
        if (
          (this.componentType === "report_summary" ||
            this.componentType === "tableDetail") &&
          this.useLocation === "report"
        ) {
          // 先清空数据，然后设置默认值
          this.$store.commit("setTableDetailGroupOneData", {}); //清空一级分组数据
          let e = {
            type: "empty",
          };
          this.$store.commit("setTableDetailStatistical", e);
          this.formDataSourceObject.chooseGroupFields = []; //清空已选字段
          this.formDataSourceObject.valueDataFileds = []; //清空统计字段
          this.formDataSourceObject.chooseValueDataFileds = []; //已选统计字段
          this.formDataSourceObject.statisticalRecordCount();
          // 默认排序字段，
          // 无分组：默认报表数据
          // 分组：选择分组后，为一级分组和统计类型
          this.$bus.$emit(
            "sortFieldsfn",
            this.reportInfo.DashboardListCheckedFields
          );
          // 分组表格
          if (this.componentType === "report_summary") {
            this.formDataSourceObject.groupFields = this.reportInfo.groupList; //一级分组下拉数据
            this.formDataSourceObject.changeGroupField(this.chartInfos.ycon); //已选分组字段
            this.ComTemplateFormatObject.form.sortingCondition =
              this.reportInfo.dashboardsortcondition; //排序方式
            this.formDataSourceObject.valueDataFileds =
              this.reportInfo.DashboardListSummaryField; //统计字段
          }
          // 无分组表格
          if (this.componentType === "tableDetail") {
            this.formDataSourceObject.groupFields = this.reportInfo.groupList; //一级分组下拉数据
            // 排序字段对象赋值,默认选中第一个
            this.ComTemplateFormatObject.form.sortingCondition =
              this.reportInfo.DashboardListCheckedFields[0].id;
            this.formDataSourceObject.valueDataFileds =
              this.reportInfo.DashboardListSummaryField; //统计字段
          }
        }
      }, 0);
    },
    //关闭组件弹框
    cancel() {
      // 清空校验
      this.$refs["ComTemplateFormatRef"].$refs["form"].clearValidate();
      this.$refs["ComTemplateDataSourceRef"].$refs["form"].clearValidate();
      this.isGetFocus = false;
      this.moreConditionFlag = false;
      this.chartFlag = true;
      this.chartsTypeActive = "compare";
      // 清空图表
      this.showChartInfos = {};
      this.showData = {};
      this.$emit("cancel");
      this.$bus.$emit("radio");
    },
    //组件确认
    affirm(value) {
      if (value === "confirm") {
        this.$bus.$emit("isShowComPersonAddAssemblyBus");
        this.$bus.$emit("radio");
      }
      // 当前弹框form校验
      // const p1 = new Promise((resolve, reject) => {
      //   this.$refs["form"].validate((valid) => {
      //     if (valid) resolve();
      //     else {
      //       reject(); // 如果调用reject就会使Promise.all()进行失败回调
      //     }
      //   });
      // });
      // 数据源组件form校验：需要满足表单必填、有分组的表格图表统计字段和记录计数至少需要一个
      let p2 = new Promise((resolve, reject) => {
        this.$refs["ComTemplateDataSourceRef"].$refs["form"].validate(
          (valid) => {
            // 执行校验的条件
            // 1、点击确定按钮，执行校验提示
            // 2、满足校验条件，执行动态接口
            if (value === "confirm" || valid) {
              if (
                valid &&
                (this.tableDetailType !== "report_summary" ||
                  (this.tableDetailType === "report_summary" &&
                    (this.formDataSourceObject.chooseValueDataFileds.length >
                      0 ||
                      this.formDataSourceObject.showRecordCountTo)))
              ) {
                resolve();
              } else {
                reject(); // 如果调用reject就会使Promise.all()进行失败回调
              }
            } else {
              this.$refs["ComTemplateDataSourceRef"].$refs[
                "form"
              ].clearValidate();
              this.chartFlag = true;
            }
          }
        );
      });
      // 格式化组件form校验
      let p3 = new Promise((resolve, reject) => {
        this.$refs["ComTemplateFormatRef"].$refs["form"].validate((valid) => {
          if (value === "confirm" || valid) {
            if (valid) {
              resolve();
            } else {
              reject(); // 如果调用reject就会使Promise.all()进行失败回调
            }
          } else {
            this.$refs["ComTemplateFormatRef"].$refs["form"].clearValidate();
            this.chartFlag = true;
          }
        });
      });
      Promise.all([p2, p3])
        .then(async () => {
          let params = {};
          // 判断当前选择的一级分组以及二级分组是否存在且属于时间类型
          let onegroupdatetype = ""; // 第一个分组的日期分组依据
          let twogroupdatetype = ""; // 第二个分组的日期分组依据
          if (
            this.ComTemplateDataSourceObject.form.groupOne &&
            this.ComTemplateDataSourceObject.isTimeTypeField(
              this.ComTemplateDataSourceObject.form.groupOne
            )
          ) {
            onegroupdatetype =
              this.ComTemplateDataSourceObject.form.groupOnedatetypeone;
          } else {
            onegroupdatetype = "";
          }
          if (
            this.ComTemplateDataSourceObject.form.groupTwo &&
            this.ComTemplateDataSourceObject.isTimeTypeField(
              this.ComTemplateDataSourceObject.form.groupTwo
            )
          ) {
            twogroupdatetype =
              this.ComTemplateDataSourceObject.form.groupTwodatetypetwo;
          } else {
            twogroupdatetype = "";
          }
          let ycon = "",
            xgather = "",
            xconArr = [],
            xcon = "",
            summarywayArr = [],
            summaryway = "",
            orderbyfieldtype = "";
          // 排序字段
          let orderbyfield = "";
          // 判断是否属于选择有分组的表格图表
          if (this.tableDetailType === "report_summary") {
            this.componentType = "report_summary";
            ycon = this.formDataSourceObject.chooseGroupFields[0].id;
            xgather =
              this.formDataSourceObject.chooseGroupFields.length > 1
                ? this.formDataSourceObject.chooseGroupFields[1].id
                : "";
            this.formDataSourceObject.chooseValueDataFileds.map((item) => {
              item.summaryWays.map((item1) => {
                xconArr.push(item.id);
                summarywayArr.push(item1);
              });
            });
            // 判断是否需要显示记录计数
            if (this.formDataSourceObject.showRecordCountTo) {
              xconArr.push("totalrecord");
              summarywayArr.push("count");
            }
            xcon = xconArr.join(",");
            summaryway = summarywayArr.join(",");
            // 在report_summary图表类型中获取选择的排序字段的类型
            let dataItem = this.ComTemplateFormatObject.sortFields.find(
              (item) =>
                item.schemefield_name_alias ===
                this.ComTemplateFormatObject.form.sortingCondition
            );
            // 公式字段的expressionType有值，在排序传参时需要拼接上
            if (dataItem.expressionType == "true") {
              orderbyfieldtype = `${dataItem.schemefieldType},${dataItem.expressionType}`;
            } else {
              orderbyfieldtype = dataItem.schemefieldType;
            }
          }
          // 气泡图，有多个统计值及统计类型，需要拼接字符串
          else if (this.componentType == "bubble") {
            let xconArr = [];
            let summarywayArr = [];
            this.ComTemplateDataSourceObject.form.moreBubbleData.map((item) => {
              xconArr.push(item.moreValue);
              summarywayArr.push(item.moreType);
            });
            xcon = xconArr.join(",");
            summaryway = summarywayArr.join(",");
          }
          // 矩阵树形图 多个统计值+统计类型 拼接传入
          else if (this.componentType == "matrixTree") {
            let xconArr = [];
            let summarywayArr = [];
            this.ComTemplateDataSourceObject.form.matrixTreeMapData.map(
              (item) => {
                xconArr.push(item.moreValue);
                summarywayArr.push(item.moreType);
              }
            );
            xcon = xconArr.join(",");
            summaryway = summarywayArr.join(",");
          } else if (this.tableDetailType == "tableDetail") {
            this.componentType = "tableDetail";
          }
          // 双轴图 多个统计值+统计类型 拼接传入，排序字段传id
          else if (this.componentType == "biaxialFigure") {
            let xconArr = [];
            let summarywayArr = [];
            // summarywayArr：当统计类型为记录数时，汇总方式传count
            this.ComTemplateDataSourceObject.form.biaxialFigureMapData.map(
              (item) => {
                if (item.moreValue != "totalrecord") {
                  xconArr.push(item.moreValue);
                  summarywayArr.push(item.moreType);
                } else {
                  xconArr.push(item.moreValue);
                  summarywayArr.push("count");
                }
              }
            );
            xcon = xconArr.join(",");
            summaryway = summarywayArr.join(",");
            let order = ""; //字段名
            // 排序方式取值分为：统计值、一级分组字段
            order = this.ComTemplateFormatObject.form.sortingCondition;
            // 统计值：
            // 从列表视图生成，schemefield_name_alias中没有汇总方式,截取$前的字段进行匹配，获取数值类型
            // 从报表生成，schemefield_name_alias中包含汇总方式，直接匹配数值类型
            let typeFilednum = order.indexOf("$");
            let typeFiled = order.slice(0, typeFilednum);
            // 记录数单独处理
            if (order === "totalrecord") {
              orderbyfield = "totalrecord";
              orderbyfieldtype = "N";
            } else {
              // 排序方式为统计值
              this.ComTemplateDataSourceObject.statisticsFields.map((item) => {
                if (
                  this.ComTemplateDataSourceObject.sourceTypeData === "report"
                ) {
                  if (order == item.schemefield_name_alias) {
                    orderbyfield = order;
                    orderbyfieldtype = item.schemefieldType;
                  }
                } else {
                  if (typeFiled == item.schemefield_name_alias) {
                    orderbyfield = order;
                    orderbyfieldtype = item.schemefieldType;
                  }
                }
              });
              // 排序方式为一级分组字段
              if (!orderbyfield) {
                this.ComTemplateDataSourceObject.groupList.map((item) => {
                  if (order == item.schemefield_name_alias) {
                    orderbyfield = item.schemefield_name_alias;
                    orderbyfieldtype = item.schemefieldType;
                  }
                });
              }
              // 在报表中显示双轴图，报表图表接口需要修改排序方式和排序方式的类型
              if (this.useLocation === "report") {
                this.$bus.$emit(
                  "setBiaxialSortOrder",
                  orderbyfield,
                  orderbyfieldtype
                );
              }
            }
          }
          // 雷达图，有多个统计值及统计类型，需要拼接字符串
          if (this.componentType == "radarMap") {
            let xconArr = [];
            let summarywayArr = [];
            this.ComTemplateFormatObject.form.sortingCondition = "";
            this.ComTemplateDataSourceObject.moreRadarMapData.map((item) => {
              if (item.moreValue) {
                xconArr.push(item.moreValue);
                summarywayArr.push(item.moreType);
              }
            });

            if (summarywayArr.length >= 1) {
              xcon = xconArr.join(",");
              summaryway = summarywayArr.join(",");
            } else {
              this.chartFlag = true;
              this.$message(
                this.$i18n.t(
                  "front-lightning-module-v1-dashborad-one-statistic"
                )
              );
              // 请至少选择一个统计值和统计类型
              return;
            }
          }
          ycon = ycon ? ycon : this.ComTemplateDataSourceObject.form.groupOne;

          xgather = xgather
            ? xgather
            : this.ComTemplateDataSourceObject.form.groupTwo;
          xcon = xcon ? xcon : this.ComTemplateDataSourceObject.form.valueData;
          summaryway = summaryway
            ? summaryway
            : this.ComTemplateDataSourceObject.form.valueDataType;
          // 判断图表类型是热力图,最大限制数传空
          let recordnum = this.ComTemplateFormatObject.form.displayMaxValue;
          if (
            this.componentType == "heatMap" ||
            this.componentType == "worldMap" ||
            this.componentType == "usaMap" ||
            this.componentType == "britainMap" ||
            this.componentType == "singaporeMap" ||
            this.componentType == "mexicoMap" ||
            this.componentType == "canadaMap" ||
            this.componentType == "franceMap" ||
            this.componentType == "germanyMap" ||
            this.componentType == "australiaMap"
          ) {
            recordnum = "";
          }
          if (this.componentType == "target") {
            recordnum = this.ComTemplateDataSourceObject.form.targetValue;
          }
          /**
           * 参数名	类型	说明
           * id  string  图表id
            dashboardid  string  仪表板ID
            reportid  string  存储报表ID
            objectid  string  对象做为数据源时，存储对象的id。
            viewid  string  对象做为数据源时，存储对象视图的id。
            name  string  图表的标题
            dashboardtype  string  图表的类型
            xcon  string  存储统计的字段ID
            summaryway  string  汇总方式，avg：求平均值;max:求最大值; min:最小值 sum:求和。
            xgather  string  存储第二个分组字段的ID
            ycon  string  存储第一个分组字段的ID
            onegroupdatetype  string  第一个分组的日期分组依据
            twogroupdatetype  string  第二个分组的日期分组依据
            unit  string  显示单位，aoto:selected;integral:整数单位;ten thousand:万;ten million:千万;billion:亿;
            dashboardsortcondition  string  排序条件，label:标签;value:数值
            orderbyfield  string  排序字段
            orderbyfieldtype  string  排序字段的类型
            dashboardsorttype  string  排序方式，asc:升序;desc:降序;
            max  string  指针图表区域的最大值
            mid1  string  指针图表区域的第一中间值
            mid2  string  指针图表区域的第二中间值
            min  string  指针图表区域的最小值
            recordnum  string  显示最大记录数
            isshowvalue  boolean  格式化里面的显示数值，true:显示;false:不显示。
            isshowpercent  boolean  格式化里面的显示百分比，true:显示;false:不显示。
            dashboardx  string  x轴的图表位置
            dashboardy  string  y轴的图表位置
            width  string  图表宽度
            height  string  图表高度
            islightning  string  是否是仪表板的图表，true:是。
            isurl  string  是否跳转到报表(穿透)，1：跳转到报表，设置为默认穿透状态
            linkageid  string  存储图表联动的图表ID，多个图表ID用逗号分隔。
            isagreement:string 双轴图是否统一刻度
           */
          let isshowvalue='';
          if(this.componentType=="bar_0"||this.componentType=="column_0"){
            isshowvalue=this.ComTemplateFormatObject.form.columnData===true?'true':'false'
          }else{
            isshowvalue=this.ComTemplateFormatObject.form.dataLabel.indexOf("1") > -1?'true':'false'
          }
          params = {
            id: this.chartId,
            dashboardid: this.isAddToHomePage ? "" : this.dashboardid,
            //数据改成动态后，如果是在报表打开组件编辑器，动态接口传报表id。
            reportid:
              // this.useLocation === "report"
              //   ? this.temReportId
              //   :
              this.ComTemplateDataSourceObject.form.reportId,
            objectid: this.ComTemplateDataSourceObject.form.objectId,
            viewid: this.ComTemplateDataSourceObject.form.viewId,
            name: this.ComTemplateDataSourceObject.form.componentName,
            dashboardtype: this.componentType,
            xcon: xcon,
            summaryway: summaryway,
            xgather: xgather,
            ycon: ycon,
            onegroupdatetype: onegroupdatetype,
            twogroupdatetype: twogroupdatetype,
            unit: this.ComTemplateFormatObject.form.unit,
            dashboardsortcondition:
              this.componentType === "biaxialFigure"
                ? orderbyfield
                : this.ComTemplateFormatObject.form.sortingCondition,
            orderbyfield:
              this.componentType === "biaxialFigure"
                ? orderbyfield
                : this.ComTemplateFormatObject.form.sortingCondition,
            orderbyfieldtype: orderbyfieldtype,
            dashboardsorttype: this.ComTemplateFormatObject.form.sortingWay,
            max: this.ComTemplateFormatObject.form.maxValue,
            mid1: this.ComTemplateFormatObject.form.breakpointOneValue,
            mid2: this.ComTemplateFormatObject.form.breakpointTwoValue,
            min:
              this.ComTemplateFormatObject.form.minValue !== ""
                ? Number(this.ComTemplateFormatObject.form.minValue)
                : "",
            recordnum: recordnum,
            isshowvalue:isshowvalue,
            isshowpercent:
              this.ComTemplateFormatObject.form.dataLabel.indexOf("2") > -1
                ? "true"
                : "false",
            dashboardx: this.dashboardxs,
            dashboardy: this.dashboardys,
            width: this.widths,
            height: this.heights,
            islightning: "true",
            isurl: "1",
            linkageid: this.linkageids,
            isagreement:
              this.componentType === "biaxialFigure" &&
              this.ComTemplateFormatObject.form.biaxialFigureCheck == true
                ? "true"
                : "false",
          };
          this.realDataParams = params;
          // return
          if (value === "confirm") {
            this.loadings = true;
            if (!this.dashboardid && !this.isAddToHomePage) {
              // 没有仪表板id且不是在主页中添加图表，表示在报表里添加图表
              this.$bus.$emit("getReportDataByDetailsBus", params);
              this.$emit("affirm", false);
              this.loadings = false;
              return;
            }
            // 在主页、仪表板中添加、编辑图表均使用该接口，区别在于是否有仪表板id，主页中添加图表仪表板id设置为空
            if (this.chartId) {
              // 编辑后的保存
              await request.updateChart(params);
              let e = {
                id: this.chartId,
              };
              this.isGetFocus = false;
              this.moreConditionFlag = false;
              this.chartsTypeActive = "compare";
              this.$set(e, "DashboardassemblyType", this.componentType); // 添加图表类型
              this.$emit(
                "affirm",
                false,
                e,
                this.ComTemplateDataSourceObject.form.componentName
              );
            } else {
              /* 
              新建时的保存：
                仪表板控制组件上限（15个），超出时全局提示，无法添加
                别的添加组件的方法都要加上此限制，（把此部分复制粘贴即可）
            */
              if (this.watchLayoutArr.length >= 15) {
                this.$message.warning(
                  this.$i18n.t("label.componenteditor.Limit.number.components")
                );
                // 关闭新增组件弹窗单个页面最多添加15个组件
                this.$emit("affirm", false);
                return;
              }
              let { data } = await request.addChart(params);
              this.isGetFocus = false;
              this.moreConditionFlag = false;
              this.chartsTypeActive = "compare";
              this.$set(data, "DashboardassemblyType", this.componentType); // 添加图表类型
              await this.$emit(
                "affirm",
                true,
                data,
                this.ComTemplateDataSourceObject.form.componentName
              );
              await this.$emit("affirmReport"); // 图表添加成功之后将方法传回报表列表
            }
            this.loadings = false;
            this.chartFlag = true;
          } else {
            this.realLoading = true;
            // 在新建报表中操作组件编辑器通过报表图表接口获取动态参数&& !this.temReportId
            if (this.useLocation === "report") {
              this.$bus.$emit(
                "getReportDataByDetailsBus",
                this.realDataParams,
                "dynamic"
              );
              return;
            } else {
              // 其他情况根据新接口获取图表数据
              this.getChartDynamicDisplay();
            }
          }
        })
        .catch((error) => {
          this.loadings = false;
          this.chartFlag = true;
          this.realLoading = false;
          if (typeof error == "undefined") {
            this.$message.info(
              //有必填项未填写或不符合规则，不可提交！
              this.$i18n.t("vue.dashboard.notRequired.notConformToTheRules")
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 30px !important;
  border-radius: 4px !important;
  width: 100% !important;
  border: 1px solid #dcdfe6 !important;
}
::v-deep .el-dialog {
  margin-top: 5vh !important;
}
::v-deep .el-dialog__body {
  padding: 0;
  overflow: initial;
  .component-edit-box {
    height: 550px;
    display: flex;
    .component-left {
      width: 400px;
      padding: 15px 10px 7px 25px;
      overflow-y: auto;
      border-right: 1px solid #dcdcdc;
    }
    .component-right {
      width: 550px;
      padding: 5px 16px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .typeimg {
        // height: 300px;
        flex: 1;
        .top_title {
          font-size: 14px;
          font-weight: 400;
          color: #080707;
        }
        .real-chart {
          width: 94%;
          min-height: 280px;
          margin: 15px 3% 0;
          height: 90%;
          max-height: 350px;
          ::v-deep .content {
            height: 90%;
          }
        }
      }
    }
  }
  ::v-deep .el-form-item__label {
    text-align: left;
    padding: 0 10px 0 0;
  }

  .component_name {
    margin: 10px 0;
    display: flex;
    align-items: flex-end;
    .el-form {
      // width: 70%;
    }
    .el-form-item {
      margin-bottom: 0;
      height: 30px;
    }
    ::v-deep .el-input {
      width: 249px;
      margin-left: 2px;
    }

    .el-form-item__label {
      font-weight: bold;
      color: #606266;
    }
    .el-form-item__content {
      display: flex;
    }
    .el-form-item__error {
      padding-top: 0;
    }
  }
  .choose_type {
    // margin-bottom: 15px;
    .all_components {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .each_component {
        border-radius: 3px;
        // border: 2px solid #e2e2e2;
        margin: 0 14px 15px 0;
        cursor: pointer;
        svg,
        img {
          width: 50px;
          height: 50px;
        }
      }
      .active {
        border: 2px solid #006dcc;
      }
    }
  }
  .chart-type {
    display: flex;
    margin-top: 16px;
    .name {
      margin-right: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #080707;
      height: 34px;
      line-height: 34px;
      min-width: 73px;
    }
    .chart-tab {
      .el-tabs__header {
        margin: 0;
      }
      .el-tabs__item {
        height: 34px;
        line-height: 34px;
        min-width: 80px;
        text-align: center;
        padding: 0 10px;
      }
      ::v-deep .el-tabs__item {
        padding: 0 10px;
      }
      .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
        background: #006dcc;
        border-bottom-color: #e4e7ed;
        color: #fff;
      }
    }
  }
  .more-condition {
    font-size: 14px;
    color: #006dcc;
    cursor: pointer;
    margin-top: 10px;
    min-width: 60px;
  }
  ::v-deep .el-input__inner {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #979797;
    height: 20px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 0;
    height: 30px;
  }
  ::v-deep .el-form-item__label {
    color: #606266;
  }
  ::v-deep .el-form-item__content {
    display: flex;
  }
}
</style>
