<!--
  报表编辑页回显数值的统计类型
-->
<template>
  <div class="numerical_statistical">
    <div class="div_top">
      <div class="div_header">{{ $t("vue_label_report_editcolumn") }}</div>
      <div class="all_types">
        <div class="checkbox_title">{{ $t("label.sso.summary") }}</div>
        <el-checkbox-group v-model="chooseTypes">
          <el-checkbox
            v-for="(item, index) in statisticsTypes"
            :key="index"
            :label="item.value"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </div>
    <div class="bottom_butts">
      <el-button @click="cancelSummary" size="mini">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="applySummary"
        :disabled="isDisableApply"
        >{{ $t("label.apply") }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { statisticsTypes } from "@/config/reportDataType/unitData"; // 统计类型

export default {
  props: {
    indexData: {
      // 报表中选择的字段信息
      type: Object,
      default: () => {},
    },
    statisticsDatas: {
      // 统计信息
      type: Object,
      default: () => {},
    },
    summaryWays: {
      // 仪表板表格图表中需要回显的字段统计类型
      type: Array,
      default: () => [],
    },
    useLocation: {
      // 组件使用位置，默认为空
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chooseTypes: [], // 选择的统计类型
      statisticsTypes: statisticsTypes(),
    };
  },
  computed: {
    // 是否禁止应用：未选择任一统计类型且非报表使用位置时禁止点击应用
    isDisableApply() {
      if (this.chooseTypes.length === 0 && this.useLocation !== "report")
        return true;
      return false;
    },
  },
  watch: {
    indexData: function () {
      this.chooseTypes = [];
      // 总和
      if (
        this.statisticsDatas.gatherfieldname.indexOf(this.indexData.id) > -1
      ) {
        this.chooseTypes.push(this.statisticsTypes[0].value);
      }
      // 平均值
      if (this.statisticsDatas.avgfield.indexOf(this.indexData.id) > -1) {
        this.chooseTypes.push(this.statisticsTypes[1].value);
      }
      // 最大值
      if (this.statisticsDatas.maxfield.indexOf(this.indexData.id) > -1) {
        this.chooseTypes.push(this.statisticsTypes[2].value);
      }
      // 最小值
      if (this.statisticsDatas.minfield.indexOf(this.indexData.id) > -1) {
        this.chooseTypes.push(this.statisticsTypes[3].value);
      }
    },
    // 监听字段已应用的统计类型，赋值
    summaryWays: {
      handler() {
        this.chooseTypes = this.summaryWays;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted(){
    // 设置统计类型的最大值为5，超过5个进行提示。
    this.$bus.$on("cancelChooseType",this.cancelChooseTypefn);
  },
  destroyed() {
    this.$bus.$off("cancelChooseType",this.cancelChooseTypefn);
  },
  methods: {
    // $bus性能优化
    cancelChooseTypefn(value){
      this.chooseTypes=value;
    },
    // 点击应用
    applySummary() {
      this.$emit("applySummary", this.chooseTypes);
    },
    // 点击取消
    cancelSummary() {
      this.$emit("cancelSummary");
    },
  },
};
</script>

<style lang="scss" scoped>
.numerical_statistical {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .div_top {
    .div_header {
      margin-bottom: 20px;
      color: #1b5297;
    }
    .all_types {
      .checkbox_title {
        margin-bottom: 17px;
        font-size: 14px;
        color: #333333;
      }
      ::v-deep .el-checkbox-group {
        display: flex;
        flex-direction: column;
        .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #333333;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: #1b5297;
          border-color: #1b5297;
        }
        .el-checkbox__label {
          font-size: 12px;
        }
      }
    }
  }
  .bottom_butts {
    display: flex;
    justify-content: flex-end;
  }
}
</style>