<!--
  组件模板的格式化
-->
<template>
  <div class="comTemplateFormat">
    <el-form ref="form" :model="form" :rules="rules" label-width="">
      <!-- 显示单位 -->
      <el-form-item
        :label="$t('label.dashboardSetup.showdw')"
        prop="unit"
        v-if="
          componentType !== 'tableDetail' &&
          componentType !== 'biaxialFigure' &&
          componentType !== 'bubble' &&
          componentType !== 'radarMap' &&
          componentType !== 'matrixTree'
        "
      >
        <el-select
          v-model="form.unit"
          @change="showUnit"
          clearable
          :placeholder="$t('label.dashboardSetup.showdw')"
        >
          <el-option
            v-for="(item, index) in units"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 数字图（同环比）--- 分析维度 -->
      <el-form-item
        :label="$t('front-lightning-module-v1-dashborad-analysis-dimension')"
        v-if="componentType == 'digital'"
      >
        <el-input disabled :value="digitalValue"></el-input>
      </el-form-item>
      <!-- 颜色范围 -->
      <div class="color_range" v-if="componentType === 'number'">
        <el-form-item
          :label="$t('vue_label_dashboard_colorrange')"
          prop="colorRange"
        >
          <div class="color-line">
            <el-input
              v-model="form.minValue"
              @input.native="showUnit"
              :placeholder="$t('vue_label_dashboard_pleaseenterthecolorrange')"
            ></el-input>
            <i class="el-icon-caret-right"></i>
          </div>
          <div class="color-line">
            <el-input
              v-model="form.breakpointOneValue"
              @input.native="showUnit"
              :placeholder="$t('vue_label_dashboard_pleaseenterthecolorrange')"
            ></el-input>
            <i class="el-icon-caret-right"></i>
          </div>
        </el-form-item>
        <!-- 右侧三种颜色显示 -->
        <div class="color_show">
          <div v-for="i in 3" :key="i"></div>
        </div>
      </div>
      <!-- 排序方式、升降序 -->
      <div
        v-if="
          componentType == 'bar_0' ||
          componentType == 'column_0' ||
          componentType == 'waterfall' ||
          componentType == 'line_0' ||
          componentType == 'pie' ||
          componentType == 'donut' ||
          componentType == 'funnel' ||
          componentType == 'line_1' ||
          componentType == 'column_duidie' ||
          componentType == 'bar_duidie' ||
          componentType == 'area' ||
          componentType == 'column_1' ||
          componentType == 'bar_1' ||
          componentType == 'tableDetail' ||
          componentType == 'biaxialFigure' ||
          componentType == 'report_summary'
        "
      >
        <!-- 排序方式 -->
        <el-form-item :label="$t('label.orderby')" prop="sortingCondition">
          <!-- 除表格图表外其他图表类型 -->
          <el-select
            v-if="
              componentType !== 'tableDetail' &&
              componentType !== 'report_summary'
            "
            v-model="form.sortingCondition"
            clearable
            :placeholder="$t('label.orderby')"
            class="order-condition"
            @change="sortOrder"
          >
            <el-option
              v-for="(item, index) in sortingConditions"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <!-- 表格图表 -->
          <div
            v-if="
              componentType === 'tableDetail' ||
              componentType === 'report_summary'
            "
          >
            <!-- 无分组的表格图表：tableDetail -->
            <el-select
              v-if="tableDetailType === 'tableDetail'"
              v-model="form.sortingCondition"
              @change="sortOrder"
              :class="
                componentType == 'tableDetail' ||
                componentType == 'report_summary'
                  ? 'order-condition'
                  : ''
              "
              :placeholder="$t('vue_label_dashboard_selectsortfield')"
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in sortFields"
                :key="index"
                :label="item.labelName"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 有分组的表格图表：report_summary -->
            <el-select
              v-if="tableDetailType === 'report_summary'"
              v-model="form.sortingCondition"
              @change="sortOrder"
              :class="
                componentType == 'tableDetail' ||
                componentType == 'report_summary'
                  ? 'order-condition'
                  : ''
              "
              :placeholder="$t('vue_label_dashboard_selectsortfield')"
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in sortFields"
                :key="index"
                :label="item.labelName"
                :value="item.schemefield_name_alias"
              ></el-option>
            </el-select>
          </div>
          <!-- 升降序 -->
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              form.sortingWay == 'desc'
                ? $t('label.sortdesc')
                : $t('label.sortasc')
            "
            placement="top"
          >
            <el-button
              :class="
                form.sortingWay == 'desc' ? 'el-icon-bottom' : 'el-icon-top'
              "
              class="order-desc"
              @click="orderChange"
            ></el-button>
          </el-tooltip>
        </el-form-item>
      </div>
      <!-- 显示最大数值 -->
      <el-form-item
        :label="$t('label.dashboardSetup.showmaxval')"
        prop="displayMaxValue"
        v-if="
          componentType !== 'gauge' &&
          componentType !== 'number' &&
          componentType !== 'biaxial' &&
          componentType !== 'digital' &&
          componentType !== 'target'
        "
      >
        <el-input
          @input.native="showUnit"
          v-model="form.displayMaxValue"
        ></el-input>
      </el-form-item>
      <!-- 柱状图是否显示数据 -->
      <div class="unified-box" v-if="componentType == 'bar_0'||componentType == 'column_0'">
        <el-checkbox @change="changeBarData" v-model="form.columnData" >显示数据 </el-checkbox>
      </div>
      <!-- 最小值、断点1值、断点2值、最大值 -->
      <div v-if="componentType == 'gauge'">
        <!-- 最小值 -->
        <el-form-item :label="$t('label.minimun')" prop="minValue">
          <el-input @input.native="showUnit" v-model="form.minValue"></el-input>
        </el-form-item>
        <!-- 断点1值 -->
        <el-form-item
          :label="$t('label.dashboardSetup.d1')"
          prop="breakpointOneValue"
        >
          <el-input
            @input.native="showUnit"
            v-model="form.breakpointOneValue"
          ></el-input>
        </el-form-item>
        <!-- 断点2值 -->
        <el-form-item
          :label="$t('label.dashboardSetup.d2')"
          prop="breakpointTwoValue"
        >
          <el-input
            @input.native="showUnit"
            v-model="form.breakpointTwoValue"
          ></el-input>
        </el-form-item>
        <!-- 最大值 -->
        <el-form-item :label="$t('label.maximum')" prop="maxValue">
          <el-input @input.native="showUnit" v-model="form.maxValue"></el-input>
        </el-form-item>
      </div>
      <!-- 双轴图的统一左右数值 c708-->
      <div v-if="componentType == 'biaxialFigure'" class="unified-box" >
        <el-checkbox @change="changeBiaxialFigureCheck" v-model="form.biaxialFigureCheck" >{{$t("c708")}} </el-checkbox>
      </div>
      <!-- 数据标签 -->
      <el-form-item
        :label="$t('label.dashboard.data.labels')"
        prop="dataLabel"
        v-if="
          componentType == 'pie' ||
          componentType == 'gauge' ||
          componentType == 'donut'
        "
      >
        <el-checkbox-group v-model="form.dataLabel" @change="showUnit">
          <el-checkbox label="1">{{
            $t("label.dashboard.show.value")
          }}</el-checkbox>
          <el-checkbox label="2">{{
            $t("label.dashboard.show.percent")
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { units } from "@/config/reportDataType/unitData";

export default {
  props: {
    // 图表类型
    componentType: {
      type: String,
      default: "bar_0",
    },
    rules: {
      // 校验规则
      type: Object,
      default: ()=>({}),
    },
    // chartInfo: {
    //   // 图表基本信息
    //   type: Object,
    //   default: () => ({}),
    // },
  },
  data() {
    return {
      // 根据选择的一级分组、统计字段以及统计方式对排序字段显示的label赋值
      sortingConditions:[],
      form: {
        unit: "integral", // 显示单位
        sortingCondition: "value", // 排序条件，排序字段
        sortingWay: "desc", // 排序方式
        displayMaxValue: "5", // 显示最大数值
        minValue: "", //最小值，颜色区域1
        breakpointOneValue: "", //断点1值，颜色区域2
        breakpointTwoValue: "", //断点2值
        biaxialFigureCheck:"",//双轴图左右一致复选框按钮
        columnData:'',//柱状图添加显示数据参数
        maxValue: "", //最大值
        dataLabel: ["1"], //数据标签，可多选
      },
      digitalValue: this.$i18n.t("label_ring_ratio"), //分析维度,周，月默认环比，年是同比
      units: units(),
      sortFieldsData: [], // 不带分组表格图表的排序字段
      BiaxialFigureData: [], //双轴图排序字段数据
      // sortingWays: [
      //   {
      //     value: "asc",
      //     label: this.$i18n.t("label.sortasc"),
      //   },
      //   {
      //     value: "desc",
      //     label: this.$i18n.t("label.sortdesc"),
      //   },
      // ],
    };
  },
  computed: {
    // 带有分组的表格图表的话，需要计算排序字段集合
    sortFields: {
      get() {
        let data = [];
        // 判断表格图表是否带有分组
        if (this.tableDetailType === "tableDetail") {
          data = this.sortFieldsData;
        } else if (this.tableDetailType === "report_summary") {
          // 有选择分组
          data.push(this.$store.getters.getTableDetailGroupOneData);
          data = data.concat(this.$store.getters.getTableDetailStatistical);
        }
        return data;
      },
      set(newValue) {
        return newValue;
      },
    },
    // 当类型为表格图表时，判断具体有无分组
    tableDetailType() {
      if (
        this.componentType !== "tableDetail" &&
        this.componentType !== "report_summary"
      )
        return;
      if (
        JSON.stringify(this.$store.getters.getTableDetailGroupOneData) === "{}"
      ) {
        return "tableDetail";
      } else {
        return "report_summary";
      }
    },
  },
  methods: {
    /**
     * 根据选择的一级分组、统计字段以及统计方式对排序字段显示的label赋值
     */
    getsortingConditions(){
      /* 
        排序条件设置对应label：
          按标签类型排序表示按一级分组排序；
          按数值类型排序表示按统计字段排序；
      */
      // 双轴图
        if (this.componentType == "biaxialFigure") {
          if (this.BiaxialFigureData.length > 1) {
            // 修改统计值后，如果排序方式不在统计值中，默认第一个统计值为排序方式
            let sortingConditionId = "";
            this.BiaxialFigureData.map((item) => {
              if (item.value == this.form.sortingCondition) {
                sortingConditionId = item.value;
              }
            });
            if (!sortingConditionId) {
              this.form.sortingCondition = this.BiaxialFigureData[0].value;
            }
          }
          this.sortingConditions= this.BiaxialFigureData;
        } else {
          // 除表格图表与双轴图外图表
          let data = [
            {
              value: "label",
              label: this.$store.getters.getGroupOneLabelName,
            },
            {
              value: "value",
              label: `${this.$store.getters.getValueDataLabelName}${this.$store.getters.getValueDataTypeLabelName}`,
            },
          ]; // 排序条件下拉内容
          // 判断当两个选项只要有一个为空时，则清空选择项、下拉内容设置为空。
          if (!data[0].label || !data[1].label) {
            this.form.sortingCondition = "";
            this.sortingConditions= [];
          }
          // 否则设置默认值为label。
          this.form.sortingCondition = this.form.sortingCondition || "label";
          this.sortingConditions= data;
        }
    },
    // 柱形图是否显示数据
    changeBarData(value){
      this.$bus.$emit("ChangeBarData", value);
    },
    // 修改是否统一刻度按钮
    changeBiaxialFigureCheck(value){
      this.$bus.$emit("changeBiaxialCheck", value);
    },
    //显示单位,最小值、断点1值、断点2值、最大值,颜色范围,显示最大数值，触发change和input事件，返回值不为空，即触发接口请求
    showUnit(value) {
      if (value) {
        this.$emit("chooseViewReal");
      }
    },
    sortOrder(value) {
      if (value) {
        this.$emit("chooseViewReal");
      }
    },
    // 升序降序
    orderChange() {
      if (this.form.sortingWay == "desc") {
        this.form.sortingWay = "asc";
      } else {
        this.form.sortingWay = "desc";
      }
      this.$emit("chooseViewReal");
    },
    ebFn1(val){
      this.BiaxialFigureData = val;
      // 更新sortingConditions值
      this.getsortingConditions()
    },
    ebFn2(){
      // 更新sortingConditions值
      this.getsortingConditions()
    },
    ebFn3(val){
      this.sortFieldsData = val;
    },
    ebFn4(){
      this.form.sortingCondition = "";
    },
  },
  watch: {
    // 监听选择的图表类型
    componentType: {
      handler(newVal) {
        if (
          newVal !== "tableDetail" &&
          newVal !== "report_summary" &&
          newVal !== "biaxialFigure"
        ) {
          // 切换回非表格图表类型
          if (
            this.form.sortingCondition !== "value" &&
            this.form.sortingCondition !== "label"
          ) {
            this.form.sortingCondition = "value";
          }
        }
        // 初始化sortingConditions内容
        this.getsortingConditions()
      },
      immediate: true, // 第一次就执行
    },
  },
  mounted() {
    // 双轴图统计字段数据
    this.$bus.$on("BiaxialFigureValue", this.ebFn1);
    // x,y轴数据变化，更新sortingConditions值
    this.$bus.$on("GetsortingConditions",this.ebFn2);
    this.$bus.$on("sortFieldsfn",this.ebFn3);
    // 清空排序字段的选择
    this.$bus.$on("clearSortingCondition", this.ebFn4);
  },
  destroyed() {
    this.$bus.$off("sortFieldsfn",this.ebFn1);
    this.$bus.$off("clearSortingCondition",this.ebFn2);
    this.$bus.$off("BiaxialFigureValue",this.ebFn3);
    this.$bus.$off("clearSortingCondition", this.ebFn4);
  },
};
</script>

<style lang="scss" scoped>
.comTemplateFormat {
  ::v-deep .el-form {
    .el-form-item {
      margin-bottom: 0px;
      margin-left: 0 !important;
      display: flex;
      margin-bottom: 15px;
      .el-form-item__error {
        padding-top: 0;
      }
      .el-form-item__content {
        display: flex;
        align-items: center;
        margin-left: 0 !important;
        .el-button {
          margin-top: 3px;
        }
      }
      .el-select,
      .el-input {
        width: 254px;
        margin-left: 0 !important;
        padding-left: 0 !important;
      }
      .el-form-item__label {
        text-align: left;
        // height: 20px;
        color: #606266;
        font-weight: normal;
        width: 100px!important;
        padding: 0;
      }
      .el-input__inner {
        height: 30px;
      }
    }
  }
}
::v-deep .unified-box{
  margin-bottom: 15px;
  font-size: 14px;
  text-align: left;
  color: #606266;
  font-weight: normal;
  padding: 0;
  .el-checkbox .el-checkbox__label{
    font-size: 14px!important;
    color: #606266;
  }
}
.color_range {
  display: flex;
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
    flex-direction: column;
  }
  ::v-deep .el-select,
  ::v-deep .el-input {
    width: 249px;
  }

  .color_show {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    div {
      width: 8px;
      height: 22px;
      margin-bottom: 8px;
    }
    div:nth-child(1) {
      background-color: #7F8DE1;
    }
    div:nth-child(2) {
      background-color: #2CC7BE;
    }
    div:nth-child(3) {
      background-color: #4087F2;
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 5px;
  display: flex;
  height: auto !important;
}
::v-deep .el-select {
  width: 254px;
}
::v-deep .el-input__inner {
  height: 30px !important;
  border-radius: 4px !important;
  width: 254px !important;
  border: 1px solid #dcdfe6 !important;
}
.order-condition {
  width: 215px !important;
  ::v-deep .el-select,
  ::v-deep .el-input,
  ::v-deep .el-input__inner {
    width: 100% !important;
  }
}
.color-line {
  display: flex;
  vertical-align: middle;
  align-items: center;
  ::v-deep .el-input {
    width: 221px !important;
  }
}
.order-asc,
.order-desc {
  padding: 6px 6px !important;
  margin-left: 9px;
}
::v-deep .el-button {
  font-size: 15px !important;
  font-weight: bolder;
}
::v-deep .el-input.is-disabled .el-input__inner {
  font-size: 12px;
}
</style>