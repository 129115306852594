<!--
  组件模板的数据源
-->
<template>
  <div class="comTemplateDataSource">
    <el-form ref="form" :model="form" :rules="rules" label-width>
      <!-- 数据来源-设置组件 -->
      <el-form-item
        :label="$t('label.dashboardSetup.shujuyuan')"
        v-if="isAddToHomePage"
        prop="sourceTypeForm"
      >
        <el-select
          v-model="form.sourceTypeForm"
          ref="chooseSourceType"
          :placeholder="$t('label_home_page_source')"
          @change="changeSourceType"
        >
          <el-option
            v-for="item in sourceTypeForms"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 从报表生成 -->
      <div v-if="sourceTypeData === 'report'">
        <el-form-item
          :label="$t('report_reportdatasource_label')"
          prop="reportId"
          v-if="!reportInfo"
        >
          <el-select
            v-model="form.reportId"
            ref="reportDataSource"
            :placeholder="$t('message.choosereportobj')"
            filterable
            clearable
            @change="changeReportSource"
            :disabled="reportIdFromReport !== ''"
          >
            <el-option
              v-for="(item, index) in listReport"
              :key="index"
              :label="item.fname"
              :value="item.id"
              v-show="isShowRatioReport(item)"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- 从列表视图生成：选择对象、选择视图 -->
      <div v-if="sourceTypeData === 'listView'" class="choose-object">
        <el-form-item :label="$t('label.selectobject')" prop="objectId">
          <el-select
            ref="chooseObject"
            v-model="form.objectId"
            :placeholder="$t('label.selectobject')"
            filterable
            clearable
            @change="changeObject"
          >
            <el-option
              v-for="(item, index) in listObject"
              :key="index"
              :label="item.labelname"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="viewId">
          <el-select
            ref="chooseView"
            v-model="form.viewId"
            :placeholder="$t('vue_label_homepage_select_view')"
            filterable
            clearable
            @change="changeView(form.objectId)"
          >
            <el-option
              v-for="(item, index) in listView"
              :key="index"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- 仪表板名称 -->
      <div class="component-Name">
        <el-form-item
          :label="$t('homepage.components.name')"
          prop="componentName"
          v-if="!reportInfo"
        >
          <el-input
            v-model="form.componentName"
            :placeholder="$t('label_tabpage_contentz')"
          ></el-input>
        </el-form-item>
      </div>
      <!-- 一级分组：选择分组、选择日期分组依据 -->
      <div
        v-if="
          componentType !== 'tableDetail' && componentType !== 'report_summary'
        "
      >
        <el-form-item
          :label="groupOneLabel"
          prop="groupOne"
          v-if="groupOneLabel"
        >
          <el-select
            v-model="form.groupOne"
            :placeholder="$t('vue_label_dashboard_group')"
            filterable
            ref="groupOneRef"
            clearable
            @change="changeGroupOne"
          >
            <el-option
              v-for="(item, index) in groupList"
              :key="index"
              :label="item.labelName"
              :value="item.id"
              v-show="isShowGroupFiled(item) && isDateTypeField(item)"
              :disabled="isDisabledGroupFiled(item)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('label.dategrouprelyon')"
          prop="groupOnedatetypeone"
          v-if="form.groupOne && isTimeTypeField(form.groupOne)"
        >
          <el-select
            v-model="form.groupOnedatetypeone"
            filterable
            clearable
            @change="changeDate"
            :placeholder="$t('vue_label_dashboard_dategruop')"
            :disabled="sourceTypeData === 'report'"
          >
            <el-option
              v-for="(item, index) in dateTypes"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- 统计值：选择统计字段、选择统计类型 -->
      <div
        v-if="
          valueDataLabel &&
          componentType !== 'tableDetail' &&
          componentType !== 'report_summary' &&
          componentType !== 'radarMap' &&
          componentType !== 'bubble' &&
          componentType !== 'matrixTree'
        "
        class="statistics-type"
      >
        <el-form-item :label="valueDataLabel" prop="valueData">
          <el-select
            v-model="form.valueData"
            :placeholder="$t('vue_label_dashboard_statisticalvalue')"
            filterable
            clearable
            @change="changeStatisticsField"
          >
            <el-option
              v-for="(item, index) in statisticsFields"
              :disabled="form.groupOne === item.id || form.groupTwo === item.id"
              :key="index"
              :label="item.labelName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="valueDataType">
          <el-select
            v-model="form.valueDataType"
            filterable
            clearable
            :placeholder="$t('vue_label_dashboard_statisticaltype')"
            @change="changeValueDataType"
          >
            <el-option
              v-for="(item, index) in statisticsTypes"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- 气泡图：三个统计值 -->
      <template  v-if="componentType === 'bubble'">
          <div
       
        class="statistics-type"
        v-for="(item, index) in form.moreBubbleData"
        :key="`${item.label}${index}`"
      >
        <el-form-item
          :label="item.label"
          :prop="`moreBubbleData[${index}].moreValue`"
        >
          <el-select
            v-model="item.moreValue"
            :placeholder="$t('vue_label_dashboard_statisticalvalue')"
            filterable
            clearable
            @change="(value) => changeBubbleXData(value, index)"
          >
            <el-option
              v-for="(items, indexs) in statisticsFields"
              :key="indexs"
              :label="items.labelName"
              :value="items.id"
              :disabled="
                form.moreBubbleData.some((item) => item.moreValue === items.id)
              "
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :prop="`moreBubbleData[${index}].moreType`">
          <el-select
            v-model="item.moreType"
            filterable
            clearable
            @change="(value) => changeBubbleXType(value, index)"
          >
            <el-option
              v-for="(items, indexs) in item.statisticsTypes"
              :key="indexs"
              :label="items.label"
              :value="items.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      </template>
    
      <!-- 双轴图 多个统计值 统计类型 -->
      <template v-if="componentType === 'biaxialFigure'">
        <div
        
        class="radarMap-type"
        v-for="(item, index) in form.biaxialFigureMapData"
        :key="`${item.label}${index}`"
      >
        <el-form-item
          :class="index == '0' ? '' : 'radarMapType'"
          :label="index == '0' ? 'Y轴' : ''"
          :prop="`biaxialFigureMapData[${index}].moreValue`"
        >
          <el-select
            v-model="item.moreValue"
            :placeholder="$t('vue_label_dashboard_statisticalvalue')"
            filterable
            clearable
            @change="(value) => changeValueBiaxialFigure(value, index)"
          >
            <el-option
              v-for="(items, indexs) in statisticsFields"
              :key="indexs"
              :label="items.labelName"
              :value="items.id"
              :disabled="
                form.biaxialFigureMapData.some(
                  (item) => item.moreValue === items.id
                )
              "
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :prop="`biaxialFigureMapData[${index}].moreType`">
          <el-select
            v-model="item.moreType"
            @change="(value) => changeTypeBiaxialFigure(value, index)"
            filterable
            clearable
          >
            <el-option
              v-for="(items, indexs) in item.statisticsTypes"
              :key="indexs"
              :label="items.label"
              :value="items.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      </template>
      
      <!-- 雷达图 多个统计值 统计类型 -->
      <template  v-if="componentType === 'radarMap'">
        <div
        class="radarMap-type"
        v-for="(item, index) in moreRadarMapData"
        :key="index"
      >
        <el-form-item
          :class="index == '0' ? '' : 'radarMapType'"
          :label="
            index == '0'
              ? $t('front-lightning-module-v1-dashborad-statistical-dimension')
              : ''
          "
        >
          <el-select
            v-model="item.moreValue"
            :placeholder="$t('vue_label_dashboard_statisticalvalue')"
            filterable
            clearable
            @change="(value) => changeRadarMapValue(value, index)"
          >
            <el-option
              v-for="(items, indexs) in statisticsFields"
              :key="indexs"
              :label="items.labelName"
              :value="items.id"
              :disabled="
                moreRadarMapData.some((item) => item.moreValue === items.id) ||
                form.groupOne === items.id ||
                form.groupTwo === items.id
              "
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="item.moreType"
            filterable
            clearable
            @change="(value) => changeRadarMapType(value, index)"
          >
            <el-option
              v-for="(items, indexs) in item.statisticsTypes"
              :key="indexs"
              :label="items.label"
              :value="items.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      </template>
      
      <!-- 矩阵树形图：两个统计值 -->
      <template  v-if="componentType === 'matrixTree'">
         <div
       
        class="statistics-type"
        v-for="(item, index) in form.matrixTreeMapData"
        :key="`${item.label}${index}`"
      >
        <el-form-item
          :label="item.label"
          :prop="`matrixTreeMapData[${index}].moreValue`"
        >
          <el-select
            v-model="item.moreValue"
            :placeholder="$t('vue_label_dashboard_statisticalvalue')"
            filterable
            clearable
            @change="(value) => changeMatrixTreeData(value, index)"
          >
            <el-option
              v-for="(items, indexs) in statisticsFields"
              :key="indexs"
              :label="items.labelName"
              :value="items.id"
              :disabled="
                form.matrixTreeMapData.some(
                  (item) => item.moreValue === items.id
                )
              "
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :prop="`matrixTreeMapData[${index}].moreType`">
          <el-select
            v-model="item.moreType"
            filterable
            clearable
            @change="(value) => changeMatrixTreeType(value, index)"
          >
            <el-option
              v-for="(items, indexs) in item.statisticsTypes"
              :key="indexs"
              :label="items.label"
              :value="items.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      </template>
     
      <!-- 目标图 -- 目标值 -->
      <div v-if="componentType === 'target'" class="component-Name">
        <el-form-item
          :label="$t('front-lightning-module-v1-dashborad-target-value')"
          prop="targetValue"
        >
          <el-input
            size="mini"
            type="number"
            v-model="form.targetValue"
            @change="changeTargetValue"
          ></el-input>
        </el-form-item>
      </div>
      <!-- 二级分组：选择分组、选择日期分组依据 -->
      <div v-if="groupTwoShow">
        <el-form-item :label="$t('label.grouping')" prop="groupTwo">
          <el-select
            v-model="form.groupTwo"
            filterable
            clearable
            :placeholder="$t('vue_label_dashboard_secondarygroup')"
            @change="changeGroupTwo"
          >
            <el-option
              v-for="(item, index) in groupList"
              :key="index"
              :label="item.labelName"
              :value="item.id"
              :disabled="
                item.id === form.groupOne || item.id === form.valueData
              "
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('label.dategrouprelyon')"
          prop="groupTwodatetypetwo"
          v-if="form.groupTwo && isTimeTypeField(form.groupTwo)"
        >
          <el-select
            v-model="form.groupTwodatetypetwo"
            filterable
            clearable
            :placeholder="$t('vue_label_dashboard_dategruop')"
            :disabled="sourceTypeData === 'report'"
          >
            <el-option
              v-for="(item, index) in dateTypes"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <!-- 表格图表的数据源选择组件：包含分组和统计字段选择 -->
      <formDataSource
        ref="formDataSourceRef"
        v-show="
          componentType === 'tableDetail' || componentType === 'report_summary'
        "
        :sourceType="sourceTypeData"
        :useLocation="useLocation"
        :statisticsFields="statisticsFields"
      />
    </el-form>
  </div>
</template>

<script>
import * as DashboardApi from "../../api.js";
import { dateTypes, statisticsTypes } from "@/config/reportDataType/unitData";
import FormDataSource from "./FormDataSource"; // 汇总式表格图表的数据源选择组件

export default {
  components: {
    FormDataSource,
  },
  props: {
    componentType: {
      type: String,
      default: "",
    },
    // 从报表生成、从列表视图生成，决定了数据源
    sourceType: {
      type: String,
      // default: "report",
      default: "",
    },
    rules: {
      // 校验规则
      type: Object,
      default: ()=>({}),
    },
    // 在报表列表页添加仪表盘时传的报表id
    reportIdFromReport: {
      type: String,
      default: "",
    },
    // 图表使用位置
    useLocation: {
      type: String,
      default: "",
    },
    reportInfo: {
      // 在报表编辑页添加图表时直接传过来图表信息，不需选择报表数据源
      type: Object,
      default: () => {},
    },
    isAddToHomePage: {
      // 是否显示控制设置组件选项是否显示
      type: Boolean,
      default: false,
    },
    isGetFocus: {
      // 是否获取焦点
      type: Boolean,
      default: false,
    },
    chartInfo: {
      // 图表基本信息
      type: Object,
      default: ()=>({}),
    },
    // 表示目标图的目标值
    recordnum: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 双轴图
      moreBiaxialFigureData: [
        {
          moreValue: "",
          moreType: "",
        },
        {
          moreValue: "",
          moreType: "",
        },
      ],
      // 雷达图
      moreRadarMapData: [
        {
          moreValue: "",
          moreType: "",
          statisticsTypes: [],
        },
        {
          moreValue: "",
          moreType: "",
          statisticsTypes: [],
        },
        {
          moreValue: "",
          moreType: "",
          statisticsTypes: [],
        },
        {
          moreValue: "",
          moreType: "",
          statisticsTypes: [],
        },
        {
          moreValue: "",
          moreType: "",
          statisticsTypes: [],
        },
      ],
      sourceTypeForms: [
        {
          value: "report",
          label: this.$i18n.t("vue_label_dashboard_generatefromreport"),
        },
        {
          value: "listView",
          label: this.$i18n.t("vue_label_dashboard_generatefromlistview"),
        },
      ], // 数据源范围
      form: {
        sourceTypeForm: "report", // 选择的数据源表单
        reportId: "", // 报表id
        objectId: "", // 对象id
        viewId: "", // 视图id
        groupOne: "", // 一级分组
        valueData: "", // 统计值
        groupTwo: "", // 二级分组
        groupOnedatetypeone: "day", // 一级分组的日期分组依据
        valueDataType: "", // 统计值类型
        groupTwodatetypetwo: "month", // 二级分组的日期分组依据
        componentName: "", //组件名称
        targetValue: "", //目标图--目标值
        bubbleYType: "", // 气泡图 Y轴 统计类型
        moreBubbleData: [
          {
            label: this.$i18n.t("label.Xaxis"),
            moreValue: "",
            moreType: "",
            statisticsTypes: [],
          },
          {
            label: this.$i18n.t("label.Yaxis"),
            moreValue: "",
            moreType: "",
            statisticsTypes: [],
          },
          {
            label: this.$i18n.t(
              "front-lightning-module-v1-dashborad-bubble-size"
            ), //"气泡大小"
            moreValue: "",
            moreType: "",
            statisticsTypes: [],
          },
        ], // 气泡图
        matrixTreeMapData: [
          {
            label: this.$i18n.t(
              "front-lightning-module-v1-dashbord-rectangle-size"
            ), //"矩形大小
            moreValue: "",
            moreType: "",
            statisticsTypes: [],
          },
          {
            label: this.$i18n.t(
              "front-lightning-module-v1-dashborad-color-depth"
            ), //"颜色深度"
            moreValue: "",
            moreType: "",
            statisticsTypes: [],
          },
        ], // 矩阵树形图
        biaxialFigureMapData: [
          {
            moreValue: "",
            moreType: "",
            statisticsTypes: [],
          },
          {
            moreValue: "",
            moreType: "",
            statisticsTypes: [],
          },
        ], // 双轴图
      },
      groupTwoShow: false, // 是否有二级分组
      groupOneLabel: this.$i18n.t("label.Yaxis"), // 一级分组的label值
      valueDataLabel: this.$i18n.t("label.Xaxis"), // 统计值的label值，所有图表类型都有统计值
      listReport: [], // 从报表生成，获取的报表对象列表
      reporttype: "Summary", //报表的类型（根据此参数可以判断该报表是不是同环比报表）
      listObject: [], // 从列表视图生成，获取的对象列表
      listView: [], // 从列表视图生成，获取的对象下的视图列表
      groupList: [], // 分组的下拉选项
      statisticsFields: [], // 统计字段的下拉选项
      dateTypes: dateTypes(), // 日期分组依据的下拉选项
      statisticsTypes: statisticsTypes(), // 统计类型的下拉选项
      listCheckedFields: [], // 表格图表类型的表头字段：排序字段集合
      reportInfoData: {}, // 选择报表数据源时的报表信息
    };
  },
  computed: {
    // 根据是否需要选择数据源来控制数据源表单的选择
    sourceTypeData: function () {
      if (this.isAddToHomePage) {
        return this.form.sourceTypeForm;
      } else {
        return this.sourceType;
      }
    },
    /*
     * 报表列表数据过滤：
     * 1、屏蔽同环比报表：
     * 矩阵树形图、雷达图、气泡图、双轴图、目标图
     * 2、仅可选同环比报表：
     * 同环比图、同环比双轴图
     */
    isShowRatioReport() {
      return (item) => {
        if (
          this.componentType === "matrixTree" ||
          this.componentType === "radarMap" ||
          this.componentType === "bubble" ||
          this.componentType === "biaxialFigure" ||
          this.componentType === "target"
        ) {
          if (item.reporttype == "ratio") {
            return false;
          } else {
            return true;
          }
        }
        if (
          this.componentType === "sequential" ||
          this.componentType === "biaxial"
        ) {
          if (item.reporttype == "ratio") {
            return true;
          } else {
            return false;
          }
        }
        return true;
      };
    },
    // 计算在选择热力图图表类型时，分组字段是否可显示进行选择
    isShowGroupFiled() {
      return (item) => {
        /* 
          满足以下情况不显示某些分组字段：
            1.列表视图数据源：选择类型为热力图图表；字段不属于省份、城市字段；
            2.列表视图数据源：选择类型为世界地图图表；字段不属于国家字段；
            3.列表视图数据源：选择类型为热力图图表；字段不属于城市字段；
        */
        if (this.sourceTypeData === "listView") {
          if (
            this.componentType === "heatMap" &&
            item.address !== "province" &&
            item.address !== "city"
          ) {
            return false;
          }
          if (this.componentType === "worldMap" && item.address !== "country") {
            return false;
          }
          if (this.componentType === "usaMap" && item.address !== "province") {
            return false;
          }
          if (this.componentType === "britainMap" && item.address !== "province") {
            return false;
          }
          if (this.componentType === "singaporeMap" && item.address !== "province") {
            return false;
          }
          if (this.componentType === "mexicoMap" && item.address !== "province") {
            return false;
          }
          if (this.componentType === "canadaMap" && item.address !== "province") {
            return false;
          }
          if (this.componentType === "franceMap" && item.address !== "province") {
            return false;
          }
          if (this.componentType === "germanyMap" && item.address !== "province") {
            return false;
          }
          if (this.componentType === "australiaMap" && item.address !== "province") {
            return false;
          }
          return true;
        } else {
          return true;
        }
      };
    },
    //判断是否属于日期类型字段
    isDateTypeField: function () {
      return (item) => {
        if (this.componentType === "digital") {
          if (item.schemefieldType == "D" || item.schemefieldType == "F") {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      };
    },
    // 计算在选择热力图图表类型时，分组字段是否需要禁选
    isDisabledGroupFiled() {
      return (item) => {
        /*
         * 满足以下情况禁选某些分组字段：
         * 1.报表数据源；选择类型为热力图,世界地图，美国地图图表；字段不属于国家、省份、城市字段；
         * 2.与二级分组字段相同；
         * 3.与默认的统计值字段相同；
         * 4.与 气泡图：三个统计值字段相同；
         * 5.与 双轴图：多个统计值字段相同；
         * 6.与 雷达图：多个统计值字段相同；
         * 7.与 矩阵树形图：两个统计值字段相同；
         * 8.报表数据源：选择类型为世界地图图表；字段不属于国家字段；
         * 9.报表数据源：选择类型为热力图图表；字段不属于城市字段；
         */
        if (
          (this.sourceTypeData === "report" &&
            this.componentType === "heatMap" &&
            item.address !== "province" &&
            item.address !== "city") ||
          item.id === this.form.groupTwo ||
          item.id === this.form.valueData ||
          (this.componentType === "worldMap" && item.address !== "country") ||
          (this.componentType === "usaMap" && item.address !== "province") ||
          (this.componentType === "britainMap" && item.address !== "province") ||
          (this.componentType === "singaporeMap" && item.address !== "province") ||
          (this.componentType === "mexicoMap" && item.address !== "province") ||
          (this.componentType === "canadaMap" && item.address !== "province") ||
          (this.componentType === "franceMap" && item.address !== "province") ||
          (this.componentType === "germanyMap" && item.address !== "province") ||
          (this.componentType === "australiaMap" && item.address !== "province") ||
          (this.componentType === "bubble" &&
            this.form.moreBubbleData.some(
              (items) => items.moreValue === item.id
            )) ||
          (this.componentType === "biaxialFigure" &&
            this.form.biaxialFigureMapData.some(
              (items) => items.moreValue === item.id
            )) ||
          (this.componentType === "radarMap" &&
            this.moreRadarMapData.some(
              (items) => items.moreValue === item.id
            )) ||
          (this.componentType === "matrixTree" &&
            this.form.matrixTreeMapData.some(
              (items) => items.moreValue === item.id
            ))
        ) {
          return true;
        }
        return false;
      };
    },
  },
  watch: {
    // 监听选择的图表类型componentType，显示对应表单选项
    componentType: {
      handler(newVal, oldVal) {
        this.groupTwoShow = false;
        switch (this.componentType) {
          case "bar_0": //1--type: "bar_0", //没有第二个分组的条形图
            this.form.groupTwo = ""; // 当没有二级分组时清空选择的二级分组表单
            this.groupOneLabel = this.$i18n.t("label.Yaxis");
            this.valueDataLabel = this.$i18n.t("label.Xaxis");
            break;
          case "column_0": // 2--type: "column_0", //没有第二个分组的柱状图
          case "waterfall": // 3--type: "waterfall", //没有第二个分组的柱状图类型中的瀑布图
          case "line_0": // 4--type: "line_0", //没有第二个分组的折线图
            this.form.groupTwo = ""; // 当没有二级分组时清空选择的二级分组表单
            this.groupOneLabel = this.$i18n.t("label.Xaxis");
            this.valueDataLabel = this.$i18n.t("label.Yaxis");
            break;
          case "pie": //5--type: "pie", //饼图
          case "donut": //6--type: "donut", //圆环图
            this.form.groupTwo = ""; // 当没有二级分组时清空选择的二级分组表单
            this.groupOneLabel = this.$i18n.t("label.wedge-shapearrows");
            this.valueDataLabel = this.$i18n.t("label.campaign.channel.value");
            break;
          case "funnel": //7--type: "funnel", //漏斗图
            this.form.groupTwo = ""; // 当没有二级分组时清空选择的二级分组表单
            this.groupOneLabel = this.$i18n.t("label.section");
            this.valueDataLabel = this.$i18n.t("label.campaign.channel.value");
            break;
          case "gauge": //8--type:"gauge" //指针图
            this.form.groupTwo = ""; // 当没有二级分组时清空选择的二级分组表单
            this.groupOneLabel = "";
            this.valueDataLabel = this.$i18n.t("label.campaign.channel.value");
            break;
          case "line_1": // 9--type: "line_1", //有第二个分组的折线图
          case "column_duidie": // 10--type: "column_duidie", //柱状堆叠图
          case "area": // 12--type: "area", //面积堆叠图
          case "column_1": // 13--type: "column_1", //有第二个分组的柱状图
            this.groupTwoShow = true;
            this.groupOneLabel = this.$i18n.t("label.Xaxis");
            this.valueDataLabel = this.$i18n.t("label.Yaxis");
            break;
          case "bar_duidie": //11--type: "bar_duidie", //条形堆叠图
          case "bar_1": //14--type: "bar_1", //有第二个分组的条形图
            this.groupTwoShow = true;
            this.groupOneLabel = this.$i18n.t("label.Yaxis");
            this.valueDataLabel = this.$i18n.t("label.Xaxis");
            break;
          case "number": //15--type: "number", //数字统计图
            this.form.groupTwo = ""; // 当没有二级分组时清空选择的二级分组表单
            this.groupOneLabel = "";
            this.valueDataLabel = this.$i18n.t("label.campaign.channel.value");
            break;
          case "tableDetail": //16--type: "tableDetail", //列表图
            this.form.groupTwo = ""; // 当没有二级分组时清空选择的二级分组表单
            this.groupOneLabel = "";
            this.valueDataLabel = "";
            break;
          case "heatMap": // 17--type: "heatMap", // 没有第二个分组的热力图
            this.form.groupTwo = "";
            this.groupOneLabel = this.$i18n.t("label.grouping"); //分组
            this.valueDataLabel = this.$i18n.t("vue_label_statistics_value"); // 统计值
            this.form.groupOne = ""; // 当选择热力图图表类型时，分组可选字段范围会更改，所以把已选择的一级分组字段清空
            break;
          case "worldMap": // 17--type: "worldMap", // 没有第二个分组的世界地图
            this.form.groupTwo = "";
            this.groupOneLabel = this.$i18n.t("label.grouping"); //分组
            this.valueDataLabel = this.$i18n.t("vue_label_statistics_value"); // 统计值
            this.form.groupOne = ""; // 当选择热力图图表类型时，分组可选字段范围会更改，所以把已选择的一级分组字段清空
            break;
          case "usaMap": // 17--type: "usaMap", // 没有第二个分组的美国地图
            this.form.groupTwo = "";
            this.groupOneLabel = this.$i18n.t("label.grouping"); //分组
            this.valueDataLabel = this.$i18n.t("vue_label_statistics_value"); // 统计值
            this.form.groupOne = ""; // 当选择热力图图表类型时，分组可选字段范围会更改，所以把已选择的一级分组字段清空
            break;
          case "sequential": // 18--type:'sequential' //同环比报表
            this.groupOneLabel = "";
            this.valueDataLabel = "";
            break;
          case "biaxial": // 19--type:'biaxial' //同环比双轴图
            this.groupOneLabel = this.$i18n.t(
              "front-lightning-module-v1-dashborad-date-dimension"
            ); //"日期维度";
            this.valueDataLabel = this.$i18n.t(
              "front-lightning-module-v1-dashborad-statistical-indicators"
            ); //"统计指标";
            break;
          case "digital": // 20--type:'digital' // 数字图（同环比）
            this.groupOneLabel = this.$i18n.t(
              "front-lightning-module-v1-dashborad-date-dimension"
            ); //"日期维度";
            this.valueDataLabel = this.$i18n.t(
              "front-lightning-module-v1-dashborad-statistical-indicators"
            ); //"统计指标";

            this.form.groupOnedatetypeone = "month";
            break;
          case "target": // 21 --type:'target' // 目标图
            this.groupOneLabel = "";
            this.valueDataLabel = this.$i18n.t("vue_label_statistics_value"); //统计值
            break;
          case "biaxialFigure": // 22 --type:'biaxialFigure' // 双轴图
            this.groupOneLabel = this.$i18n.t("label.Xaxis");
            this.valueDataLabel = "";
            break;
          case "bubble": // 23 --type:'bubble' // 气泡图
            this.groupOneLabel = this.$i18n.t("label.knowledgebase.type"); //分类";
            this.valueDataLabel = this.$i18n.t(
              "front-lightning-module-v1-dashborad-bubble-size"
            ); //"气泡大小"
            break;
          case "matrixTree": // 24 --type:'matrixTree' // 矩阵树形图
            this.groupOneLabel = this.$i18n.t("label.knowledgebase.type"); //分类";
            this.valueDataLabel = this.$i18n.t(
              "front-lightning-module-v1-dashbord-rectangle-size"
            ); //"矩形大小";
            break;
        }
        // 如果上一图表类型为表格图表，需要对分组、统计值和统计值类型清空
        if (oldVal === "tableDetail") {
          this.form.groupOne = "";
          this.form.valueData = "";
          this.form.valueDataType = "";
        }
        // 同环比数字图时，设置日期类型，其他类型图表设置默认日期类型
        if (newVal === "digital") {
          this.dateTypes = [
            {
              label: this.$i18n.t("label.brief.thisweek"), //本周
              value: "day",
            },
            {
              label: this.$i18n.t("label.brief.thismonth"), //本月",
              value: "month",
            },
            {
              label: this.$i18n.t("this.year"), //本年",
              value: "year",
            },
          ];
        } else {
          this.dateTypes = dateTypes();
        }
      },
      immediate: true, // 第一次就执行
    },
    // 监听从报表编辑页添加图表时直接传过来的图表信息
    reportInfo: {
      handler() {
        if (this.reportInfo) {
          // 在报表编辑页添加图表时
          this.getSelectDatas(this.reportInfo);
          this.reportInfoData = this.reportInfo; // 报表数据
        }
      },
      immediate: true, //代表在wacth里声明了reportInfo这个方法之后立即先去执行handler方法
      deep: true,
    },
    // 对于有多个统计值的图表，需要单独处理
    "form.valueData": function () {
      // 拆分valueData、valueDataType
      let valueDataArr = this.form.valueData.split(",");
      let valueDataTypeArr = this.form.valueDataType.split(",");
      // 雷达图，气泡图，双轴图，矩阵树形图。切换报表数据源清空统计类型，默认设置记录数和总和
      if (this.componentType === "radarMap") {
        // 对moreRadarMapData赋值
        // 切换报表数据源的时候根据统计值和字段进行赋值，默认最少一个统计值和统计字段，其余的清空
        this.moreRadarMapData.map((item, index) => {
          if (valueDataArr[index]) {
            this.$set(item, "moreValue", valueDataArr[index]);
            this.$set(
              item,
              "statisticsTypes",
              this.getSelectValueTypeFn(item.moreValue)
            );
            this.$set(item, "moreType", valueDataTypeArr[index]);
          } else {
            this.$set(item, "moreValue", "");
            this.$set(item, "statisticsTypes", "");
            this.$set(item, "moreType", []);
          }
        });
        return;
      }
      // 气泡图
      if (this.componentType === "bubble") {
        // 对form.moreBubbleData赋值
        this.form.moreBubbleData.map((item, index) => {
          if (valueDataArr[index]) {
            this.$set(item, "moreValue", valueDataArr[index]);
            this.$set(
              item,
              "statisticsTypes",
              this.getSelectValueTypeFn(item.moreValue)
            );
            this.$set(item, "moreType", valueDataTypeArr[index]);
          } else {
            this.$set(item, "moreValue", "");
            this.$set(item, "statisticsTypes", "");
            this.$set(item, "moreType", []);
          }
        });
        return;
      }
      // 矩阵树形图
      if (this.componentType === "matrixTree") {
        // 对form.moreBubbleData赋值
        this.form.matrixTreeMapData.map((item, index) => {
          if (valueDataArr[index]) {
            this.$set(item, "moreValue", valueDataArr[index]);
            this.$set(
              item,
              "statisticsTypes",
              this.getSelectValueTypeFn(item.moreValue)
            );
            this.$set(item, "moreType", valueDataTypeArr[index]);
          } else {
            this.$set(item, "moreValue", "");
            this.$set(item, "statisticsTypes", "");
            this.$set(item, "moreType", []);
          }
        });
        return;
      }
      // 双轴图
      if (this.componentType === "biaxialFigure") {
        // 对form.moreBubbleData赋值
        this.form.biaxialFigureMapData.map((item, index) => {
          // 记录数的统计类型，count设置sum
          if (
            valueDataArr[index] === "totalrecord" &&
            valueDataTypeArr[index] === "count"
          ) {
            valueDataTypeArr[index] = "sum";
          }

          if (valueDataArr[index]) {
            this.$set(item, "moreValue", valueDataArr[index]);
            this.$set(
              item,
              "statisticsTypes",
              this.getSelectValueTypeFn(item.moreValue)
            );
            this.$set(item, "moreType", valueDataTypeArr[index]);
          } else {
            this.$set(item, "moreValue", "");
            this.$set(item, "statisticsTypes", "");
            this.$set(item, "moreType", []);
          }
          this.setValueBiaxialFigureData();
        });
        return;
      }
    },
    // 给目标图的目标值赋值
    recordnum: function () {
      this.form.targetValue = this.recordnum;
    },
  },
  mounted() {
    this.getReportList();
    this.getObjectList();
    // 在仪表板中使用图表，从列表视图中新建
    if (!this.chartInfo.id && this.useLocation !== "report") {
      if (this.sourceTypeData === "listView") {
        this.$refs.chooseObject.focus();
      } else if (this.sourceTypeData !== "listView") {
        this.$refs.reportDataSource.focus(); // 从报表生成
      }
    }
  },
  destroyed() {},
  methods: {
    // 切换日期范围
    changeDate(value) {
      // 如果是数字图发送事件，更改分析维度
      if (this.componentType == "digital") {
        this.$emit("changeDateTime", value);
      }
      this.$emit("chooseViewReal");
    },
    // 获取所有报表
    async getReportList() {
      let dataObject = await DashboardApi.getReportList();
      this.listReport = dataObject.data.listReport;
      //报表添加到仪表板：有报表id且没有组件名称时，组件名=报表名
      if (this.form.reportId && !this.form.componentName) {
        this.listReport.filter((item) => {
          if (item.id == this.form.reportId) {
            return (this.form.componentName = item.fname);
          }
        });
      }
    },
    // 切换报表数据源
    async changeReportSource(id) {
      // 切换数据源的时候要判断 该报表是不是同环比报表 同环比报表才能显示 同环比报表图+同环比双轴图
      this.listReport.map((item) => {
        if (item.id == id) {
          this.reporttype = item.reporttype;
        }
      });
      //  新建组件的时候设置默认图表名称：根据图表id判断是新建还是编辑 组件名称=报表名称或者视图+对象名称
      this.listReport.filter((item) => {
        if (item.id == id) {
          return (this.form.componentName = item.fname);
        }
      });
      this.listCheckedFields = [];
      this.form.groupOne = "";
      this.form.valueData = "";
      this.form.valueDataType = "";
      this.form.groupTwo = "";
      this.$bus.$emit("clearTableGroups"); // 切换报表时需要清空表格图表选择的分组
      let params = {
        reportid: id,
      };
      let { data } = await DashboardApi.getReport(params);
      this.listCheckedFields = data.reportInfo.listCheckedFields;
      //图表类型，从报表生成，排序字段默认第一个字段
      if (this.componentType === "tableDetail" && this.listCheckedFields) {
        this.$emit("formDetailGetId", this.listCheckedFields[0].id);
      }
      this.$bus.$emit("sortFieldsfn", this.listCheckedFields); // 获取排序字段集合
      this.getGroupAndStatisticalFields(
        data.reportInfo.reportTC,
        data.reportInfo.listSummaryField,
        "report",
        data.reportInfo.groupIds
      );
      this.reportInfoData = data.reportInfo; // 报表数据
      let reportInfo = {
        groupList: data.reportInfo.groupList, // 获取分组的下拉选项
        gatherFieldList: data.reportInfo.gatherFieldList, // 总和
        listAvgFields: data.reportInfo.listAvgFields, // 平均值
        listMaxFields: data.reportInfo.listMaxFields, // 最大值
        listMinFields: data.reportInfo.listMinFields, // 最小值
        lengthwaysgroupone: data.reportInfo.lengthwaysgroupone, // 分组一的id
        lengthwaysgrouptwo: data.reportInfo.lengthwaysgrouptwo, // 分组二的id
        transversegroupone: data.reportInfo.transversegroupone, // 分组一的id
        transversegrouptwo: data.reportInfo.transversegrouptwo, // 分组二的id
        transversegroupthree: data.reportInfo.transversegroupthree, // 分组三的id
        lengthwaysdatetypeone: data.reportInfo.lengthwaysdatetypeone, // 分组一的日期分组依据
        lengthwaysdatetypetwo: data.reportInfo.lengthwaysdatetypetwo, // 分组二的日期分组依据
        transversedatetypeone: data.reportInfo.transversedatetypeone, // 分组一的日期分组依据
        transversedatetypetwo: data.reportInfo.transversedatetypetwo, // 分组二的日期分组依据
        transversedatetypethree: data.reportInfo.transversedatetypethree, // 分组三的日期分组依据
      };
      this.reporttype = data.reportInfo.reporttype;
      this.getSelectDatas(reportInfo);
      this.$refs.reportDataSource.blur(); // 从报表生成失去焦点
      // 同环比报表图不调取动态接口
      if (id) {
        // 同环比报表类型的图表，在刷新之后需要重新获取报表数据
        if (this.componentType === "sequential") {
          this.$bus.$emit("againGetReportDataByDetail");
        }else{
          this.$emit("chooseViewReal");
        }
      } else {
        this.form.componentName = ""; //清空报表名
      }
      this.$emit("changeReportSource", this.reporttype);
    },
    // 根据选择的报表id获取一级分组，二级分组及统计值下拉内容
    getSelectDatas(reportInfos) {
      let reportInfo = reportInfos ? reportInfos : this.reportInfo;
      this.groupList = reportInfo.groupList;
      this.statisticsFields = []; // 获取统计字段的下拉选项
      // 切换报表数据源，设置默认值,一级分组默认第一个可选分组值，二级分组默认第二个可选分组值，统计值默认第一个可选统计值总和，如分组涉及日期，按日/月/年分组的值也自动附上
      if (this.groupList && this.groupList.length > 0) {
        // 热力图分组条件为地址否则为空，其他类型为第一个分组字段
        if (
          this.componentType == "heatMap" ||
          this.componentType == "worldMap" ||
          this.componentType == "usaMap"
        ) {
          this.groupList.find((item) => {
            if (
              (item.address == "province" || item.address == "city") &&
              this.componentType == "heatMap"
            ) {
              return (this.form.groupOne = item.id);
            }
            if (item.address == "province" && this.componentType == "usaMap") {
              return (this.form.groupOne = item.id);
            }
            if (item.address == "country" && this.componentType == "worldMap") {
              return (this.form.groupOne = item.id);
            } else {
              return (this.form.groupOne = "");
            }
          });
        } else {
          this.form.groupOne = this.groupList[0].id;
          // 切换报表数据源的时候如果一级分组为创建时间，默认日期分组依据为月
          this.groupList.map((item) => {
            if (
              item.id == this.form.groupOne &&
              item.schemefield_name == "createdate"
            ) {
              this.form.groupOnedatetypeone = "month";
            }
          });
        }

        this.groupOneDesc(this.form.groupOne);
        if (
          (this.componentType == "line_1" ||
            this.componentType == "column_duidie" ||
            this.componentType == "bar_duidie" ||
            this.componentType == "area" ||
            this.componentType == "column_1" ||
            this.componentType == "bar_1") &&
          this.groupList.length > 1
        ) {
          this.form.groupTwo = this.groupList[1].id;
        }
      }
      // 总和，平均值，最大值，最小值都是分开的
      if (reportInfo.gatherFieldList != null) {
        reportInfo.gatherFieldList.map((item) => {
          item.typeArr = [
            { label: this.$i18n.t("label.summation"), value: "sum" },
          ];
          this.statisticsFields.push(item);
        });
      }
      if (reportInfo.listAvgFields != null) {
        reportInfo.listAvgFields.map((item) => {
          // 判断是否已有该字段
          if (this.statisticsFields.some((item1) => item1.id === item.id)) {
            let typeArr = this.statisticsFields.find(
              (item1) => item1.id === item.id
            ).typeArr;
            typeArr.push({
              label: this.$i18n.t("label.average"),
              value: "avg",
            });
          } else {
            item.typeArr = [
              { label: this.$i18n.t("label.average"), value: "avg" },
            ];
            this.statisticsFields.push(item);
          }
        });
      }
      if (reportInfo.listMaxFields != null) {
        reportInfo.listMaxFields.map((item) => {
          if (this.statisticsFields.some((item1) => item1.id === item.id)) {
            let typeArr = this.statisticsFields.find(
              (item1) => item1.id === item.id
            ).typeArr;
            typeArr.push({
              label: this.$i18n.t("label.maximum"),
              value: "max",
            });
          } else {
            item.typeArr = [
              { label: this.$i18n.t("label.maximum"), value: "max" },
            ];
            this.statisticsFields.push(item);
          }
        });
      }
      if (reportInfo.listMinFields != null) {
        reportInfo.listMinFields.map((item) => {
          if (this.statisticsFields.some((item1) => item1.id === item.id)) {
            let typeArr = this.statisticsFields.find(
              (item1) => item1.id === item.id
            ).typeArr;
            typeArr.push({
              label: this.$i18n.t("label.minimun"),
              value: "min",
            });
          } else {
            item.typeArr = [
              { label: this.$i18n.t("label.minimun"), value: "min" },
            ];
            this.statisticsFields.push(item);
          }
        });
      }
      // 统计值
      if (this.statisticsFields && this.statisticsFields.length > 0) {
        this.form.valueData = this.statisticsFields[0].id;
        this.changeStatisticsField(this.form.valueData);
      }
    },
    // 获取所有对象列表
    async getObjectList() {
      let params = {
        input: "", // 传空默认查询所有对象
      };
      let { data } = await DashboardApi.getObjectList(params);
      this.listObject = data;
    },
    // 切换列表视图对象，根据选择对象获取所有视图列表
    async changeObject(value) {
      // 首次进入编辑弹框不获取焦点，修改对象id时获取焦点
      if (this.isGetFocus) {
        this.$refs.chooseView.focus();
      }
      this.$emit("modifyisGetFocus")
      this.form.viewId = "";
      this.form.groupOne = "";
      this.form.valueData = "";
      this.form.valueDataType = "";
      this.form.groupTwo = "";
      this.groupList = [];
      this.statisticsFields = [];
      this.listCheckedFields = [];
      this.$bus.$emit("clearTableGroups"); // 切换报表时需要清空表格图表选择的分组
      // 删除对象，清空报表名和选中视图
      if (!value) {
        this.form.componentName = ""; 
        return;
      }
      let params = {
        objId: value,
      };
      let { data } = await DashboardApi.getViewList(params);
      if (data) {
        // 过滤掉0，1，2，3的视图：我最近查看的、我最近创建的、我最近修改的、最新记录。
        this.listView = data.filter(
          (item) =>
            item.id !== "0" &&
            item.id !== "1" &&
            item.id !== "2" &&
            item.id !== "3"
        );
      }
      this.$refs.chooseObject.blur(); //失去焦点
    },
    // 给根据分组1赋值给排序// 获取一级分组字段集合及选择的一级分组字段id，存储进state中
    groupOneDesc(id) {
      let e = {
        groupList: this.groupList,
        id: id,
      };
      this.$store.commit("setGroupOneLabelName", e);
    },
    // 切换视图，根据选择对象获取所有字段
    async changeView(value) {
      if (this.form.viewId) {
        this.listView.map((item) => {
          if (item.id == this.form.viewId) {
            this.form.componentName = item.label;
          }
        });
      } else {
        this.form.componentName = "";
      }
      this.listObject.map((item) => {
        if (item.id == this.form.objectId) {
          this.form.componentName += item.labelname;
        }
      });
      // if (!this.form.viewId) return;
      let params = {
        objectid: value, // 对象id
      };
      let { data } = await DashboardApi.getFieldsByObjIdForChart(params);
      this.getGroupAndStatisticalFields(
        data.listObjectFields,
        JSON.parse(JSON.stringify(data.listSummaryFields)),
        "listView"
      );
      this.groupList = data.listObjectFields; // 可分组的字段，后端已做过筛选
      // 在最开始添加记录数
      data.listSummaryFields.unshift({
        id: "totalrecord",
        labelName: this.$i18n.t("label.recordamount"),
      });
      this.statisticsFields = data.listSummaryFields; // 统计字段的下拉选项
      this.listCheckedFields = data.listObjectFields.concat(
        data.listSummaryFields
      ); // 两个集合合并成一个，作为排序字段集合
      this.$bus.$emit("sortFieldsfn", this.listCheckedFields);
      //除了指针图和数值图，数字图和表格图表,一级分组默认所有人
      if (
        this.componentType != "gauge" &&
        this.componentType != "number" &&
        this.componentType != "tableDetail" &&
        this.componentType != "report_summary" &&
        this.componentType != "digital" &&
        this.componentType != "heatMap" &&
        this.componentType != "worldMap" &&
        this.componentType != "usaMap"
      ) {
        this.groupList.find((item) => {
          if (item.schemefieldName == "ownerid") {
            return (this.form.groupOne = item.id);
          }
        });
        this.groupOneDesc(this.form.groupOne);
      }
      // 地图分组字段默认地址
      // 1、热力地图默认为第一个地址省或者市字段
      // 2、世界地图默认第一个国家字段
      // 3、美国地图默认第一个地址省字段
      if (
        this.componentType === "heatMap" ||
        this.componentType === "worldMap" ||
        this.componentType === "usaMap"||
        this.componentType === "singaporeMap"||
        this.componentType === "mexicoMap"||
        this.componentType === "canadaMap"||
        this.componentType === "franceMap"||
        this.componentType === "germanyMap"||
        this.componentType === "australiaMap"
      ) {
        this.groupList.find((item) => {
          if (
            (item.address == "province" || item.address == "city") &&
            this.componentType === "heatMap"
          ) {
            return (this.form.groupOne = item.id);
          } else if (
            item.address == "province" &&
            (this.componentType === "usaMap"||
            this.componentType === "singaporeMap"||
            this.componentType === "mexicoMap"||
            this.componentType === "canadaMap"||
            this.componentType === "franceMap"||
            this.componentType === "germanyMap"||
            this.componentType === "australiaMap")
          ) {
            return (this.form.groupOne = item.id);
          } else if (
            item.address == "country" &&
            this.componentType === "worldMap"
          ) {
            return (this.form.groupOne = item.id);
          }
        });
      }

      // 默认创建时间
      this.groupList.find((item) => {
        // 表格图表：从列表视图生成，默认排序字段为创建时间
        if (
          this.componentType === "tableDetail" &&
          item.schemefieldName === "createdate"
        ) {
          return this.$emit("tableDetailGetId", item.id);
        }
        // 带有二级分组的设置默认创建时间
        if (this.groupTwoShow && this.groupOneLabel) {
          if (item.schemefieldName === "createdate") {
            return (this.form.groupTwo = item.id);
          } else {
            this.form.groupTwo = "";
          }
        }
        // 数字图一级分组默认创建时间
        if (this.componentType == "digital") {
          if (item.schemefieldName === "createdate") {
            return (this.form.groupOne = item.id);
          }
        }
      });
      // 新建时，统计值默认：记录数
      this.statisticsFields.map((item) => {
        if (item.id === "totalrecord") {
          this.form.valueData = item.id;
          this.changeStatisticsField(item.id);
        }
      });
      this.$refs.chooseView.blur();
      if (value) {
        this.$emit("chooseViewReal");
      }
    },
    // 判断是否属于时间类型字段
    isTimeTypeField(id) {
      if (this.groupList.length > 0) {
        let schemefieldType = this.groupList.find((item) => item.id === id)
          ? this.groupList.find((item) => item.id === id).schemefieldType
          : "";
        if (schemefieldType === "D" || schemefieldType === "F") {
          // 属于日期类型
          return true;
        } else {
          return false;
        }
      }
    },
    // 切换一级分组
    changeGroupOne(id) {
      if (this.sourceTypeData === "report") {
        // 报表数据源
        this.form.groupOnedatetypeone = this.getDatetype(
          id,
          this.reportInfoData
        );
      } else {
        // 列表视图数据源
        this.form.groupOnedatetypeone = "day";
      }
      // 获取一级分组字段集合及选择的一级分组字段id，存储进state中
      let e = {
        groupList: this.groupList,
        id: id,
      };
      this.$store.commit("setGroupOneLabelName", e);
      if (id) {
        this.$emit("chooseViewReal");
        this.setValueBiaxialFigureData();
        let that=this
        setTimeout(()=>{
          // 更新sortingConditions
        that.$bus.$emit("GetsortingConditions")
        },0)
      }
    },
    // 切换二级分组
    changeGroupTwo(id) {
      if (this.sourceTypeData === "report") {
        // 报表数据源
        this.form.groupTwodatetypetwo = this.getDatetype(
          id,
          this.reportInfoData
        );
      } else {
        // 列表视图数据源
        this.form.groupTwodatetypetwo = "month";
      }
      if (id) {
        this.$emit("chooseViewReal");
      }
    },
    // 根据选择的分组id获取对应的时间分组依据
    getDatetype(id, reportInfo) {
      // 将id，groupType，dateType进行匹配
      let dataArr = [
        {
          id: reportInfo.lengthwaysgroupone,
          groupType: "lengthwaysgroupone",
          dateType: "lengthwaysdatetypeone",
        },
        {
          id: reportInfo.lengthwaysgrouptwo,
          groupType: "lengthwaysgrouptwo",
          dateType: "lengthwaysdatetypetwo",
        },
        {
          id: reportInfo.transversegroupone,
          groupType: "transversegroupone",
          dateType: "transversedatetypeone",
        },
        {
          id: reportInfo.transversegrouptwo,
          groupType: "transversegrouptwo",
          dateType: "transversedatetypetwo",
        },
        {
          id: reportInfo.transversegroupthree,
          groupType: "transversegroupthree",
          dateType: "transversedatetypethree",
        },
      ];
      if (dataArr.find((item) => item.id === id)) {
        return reportInfo[dataArr.find((item) => item.id === id).dateType];
      } else {
        return "";
      }
    },
    // 双轴图--统计值
    changeValueBiaxialFigure(value, index) {
      if (value) {
        this.form.biaxialFigureMapData[index].statisticsTypes =
          this.getSelectValueTypeFn(value);
        this.form.biaxialFigureMapData[index].moreType =
          this.form.biaxialFigureMapData[index].statisticsTypes[0].value;
      } else {
        // 删除统计值时，清空对应的统计类型
        this.form.biaxialFigureMapData[index].moreType = "";
      }
      this.setValueBiaxialFigureData();
    },
    // 双轴图--修改统计类型
    changeTypeBiaxialFigure(value, index) {
      if (value) {
        this.form.biaxialFigureMapData[index].moreType = value;
        this.$emit("chooseViewReal");
      } else {
        // 删除统计值时，清空对应的统计类型
        this.form.biaxialFigureMapData[index].moreType = "";
      }
      this.setValueBiaxialFigureData();
    },
    // 双轴图：排序方式下拉列表数据
    setValueBiaxialFigureData() {
      let BiaxialData = [];
      let name = "";
      let id = "";
      // 排序方式数据源分为：一级分组字段和统计值
      // 统计值：
      // 1、从列表视图生成：统计值拼接统计类型
      // 2、从报表生成：不用拼接统计类型
      // 3、记录数单独处理
      // 4、名称+汇总方式
      this.form.biaxialFigureMapData.map((item) => {
        this.statisticsFields.map((items) => {
          if (this.useLocation === "report") {
            if (
              items.id === item.moreValue &&
              item.moreValue !== "totalrecord"
            ) {
              id = items.schemefield_name_alias_obj + "$" + item.moreType;
              name = items.labelName;
            } else if (
              items.id === item.moreValue &&
              item.moreValue === "totalrecord"
            ) {
              id = "totalrecord";
              name = items.labelName;
            }
          } else {
            if (
              items.id === item.moreValue &&
              item.moreValue !== "totalrecord"
            ) {
              name = items.labelName;
              if (this.sourceTypeData === "report") {
                id = items.schemefield_name_alias;
              } else {
                id = items.schemefield_name_alias + "$" + item.moreType;
              }
            } else if (
              items.id === item.moreValue &&
              item.moreValue === "totalrecord"
            ) {
              name = items.labelName;
              id = "totalrecord";
            }
          }
        });
        // 汇总方式
        if (item.statisticsTypes) {
          item.statisticsTypes.map((item1) => {
            if (item.moreType == item1.value) {
              name += item1.label;
            }
          });
        }
        BiaxialData.push({
          label: name, //字段名称
          value: id, //字段id
        });
      });
      // 一级分组
      // 根据id获取对应名称，和类型字段
      let grouponeName = "";
      let grouponeField = "";
      this.groupList.map((item) => {
        if (item.id === this.form.groupOne) {
          grouponeName = item.labelName;
          grouponeField = item.schemefield_name_alias;
        }
      });
      BiaxialData.push({
        label: grouponeName, //字段名称
        value: grouponeField, //字段id
      });
      this.$bus.$emit("BiaxialFigureValue", BiaxialData);
    },
    // 气泡图 -- X轴统计值
    changeBubbleXData(value, index) {
      if (value) {
        this.form.moreBubbleData[index].statisticsTypes =
          this.getSelectValueTypeFn(value);
        this.form.moreBubbleData[index].moreType =
          this.form.moreBubbleData[index].statisticsTypes[0].value;
      } else {
        this.form.moreBubbleData[index].moreType = "";
        this.$emit("chooseViewReal");
      }
    },
    // 气泡图 -- X轴统计类型
    changeBubbleXType(value, index) {
      if (value) {
        this.form.moreBubbleData[index].moreType = value;
      } else {
        this.form.moreBubbleData[index].moreType = "";
      }
      this.$emit("chooseViewReal");
    },
    // 雷达图--统计值
    changeRadarMapValue(value, index) {
      if (value) {
        this.moreRadarMapData[index].statisticsTypes =
          this.getSelectValueTypeFn(value);
        this.moreRadarMapData[index].moreType =
          this.moreRadarMapData[index].statisticsTypes[0].value;
      } else {
        this.moreRadarMapData[index].statisticsTypes = "";
        this.moreRadarMapData[index].moreType = "";
        this.$emit("chooseViewReal");
      }
    },

    // 雷达图--修改统计类型
    changeRadarMapType(value, index) {
      if (value) {
        this.moreRadarMapData[index].moreType = value;
      } else {
        this.moreRadarMapData[index].moreType = "";
      }
      this.$emit("chooseViewReal");
    },
    // 矩阵树形图 -- 统计值
    changeMatrixTreeData(value, index) {
      this.form.matrixTreeMapData[index].statisticsTypes =
        this.getSelectValueTypeFn(value);
      this.form.matrixTreeMapData[index].moreType =
        this.form.matrixTreeMapData[index].statisticsTypes[0].value;
      this.$emit("chooseViewReal");
    },
    // 矩阵树形图 -- 统计类型
    changeMatrixTreeType(value, index) {
      if (value) {
        this.form.matrixTreeMapData[index].moreType = value;
      } else {
        this.form.matrixTreeMapData[index].moreType = "";
      }
      this.$emit("chooseViewReal");
    },
    // 目标图
    changeTargetValue(id) {
      // 修改目标值，触发动态更新
      if (id) {
        this.form.targetValue = id;
        this.$emit("chooseViewReal");
      }
    },
    // 切换统计字段
    changeStatisticsField(id) {
      if (!id || id.split(",").length > 1) return;
      this.statisticsTypes = this.getSelectValueTypeFn(id);
      this.form.valueDataType = "";
      // 设置统计类型默认选择第一个选项
      this.form.valueDataType = this.statisticsTypes[0].value;
      // 统计类型设置默认值
      if (this.statisticsTypes && this.statisticsTypes.length > 0) {
        this.form.valueDataType = this.statisticsTypes[0].value;
      }
      if (id) {
        this.$emit("chooseViewReal");
         let that=this
        setTimeout(()=>{
          // 更新sortingConditions
        that.$bus.$emit("GetsortingConditions")
        },0)
      }
    },
    // 获取统计字段 对应的统计类型
    getSelectValueTypeFn(id) {
      let statisticsTypes_data = [];
      // 选择有数据且现在是属于报表数据源
      if (id !== "" && this.sourceTypeData === "report") {
        //直接改变统计类型下拉选项列表即可
        statisticsTypes_data = this.statisticsFields.find(
          (item) => item.id === id
        ).typeArr;
      } else {
        // 列表视图数据源
        if (id === "totalrecord") {
          // 选择的是记录数
          statisticsTypes_data = [
            { label: this.$i18n.t("label.summation"), value: "sum" },
          ];
        } else {
          statisticsTypes_data = statisticsTypes();
        }
      }
      // 获取统计字段集合及选择的统计字段id，存储进state中
      let e = {
        statisticsFields: this.statisticsFields,
        id: id,
      };
      this.$store.commit("setValueDataLabelName", e);
      setTimeout(() => {
        this.changeValueDataType(this.form.valueDataType);
      }, 0);
      return statisticsTypes_data;
    },
    // 切换统计值类型
    changeValueDataType(id) {
      // 获取统计值类型集合及选择的统计值类型id，存储进state中
      let e = {
        statisticsTypes: this.statisticsTypes,
        id: id,
      };
      this.$store.commit("setValueDataTypeLabelName", e);
      if (id) {
        this.$emit("chooseViewReal");
      }
    },
    // 根据传入的数据源对象字段集合数据转换为表格图表中分组和统计字段的数据集合
    getGroupAndStatisticalFields(reportTC, listSummaryField, type, groupIds) {
      let e = {
        filteredListObjectFields: [], // 对象下的字段集合
        listSummaryField: listSummaryField, // 对象下筛选出的可统计字段的集合
        groupIds: "", // 报表本身的分组字段id集合
      };
      if (type == "report") {
        // 报表数据源
        e.filteredListObjectFields = [
          {
            label: reportTC.objectALabel,
            options: reportTC.filteredListObjectAField,
          },
          {
            label: reportTC.objectBLabel,
            options: reportTC.filteredListObjectBField,
          },
          {
            label: reportTC.objectCLabel,
            options: reportTC.filteredListObjectCField,
          },
          {
            label: reportTC.objectDLabel,
            options: reportTC.filteredListObjectDField,
          },
        ];
        e.groupIds = groupIds;
      } else if (type === "listView") {
        // 列表视图数据源
        e.filteredListObjectFields = reportTC;
      }
      this.$bus.$emit("objectFieldsfn", e);
    },
    // 切换数据源类型，此时清空选择的表单项
    changeSourceType(value) {
      this.form.reportId = "";
      this.form.componentName = "";
      this.form.objectId = "";
      this.form.viewId = "";
      this.form.groupOne = "";
      this.form.valueData = "";
      this.form.valueDataType = "";
      this.form.groupTwo = "";
      this.$emit("changeSourceTypes",value)
      // this.$refs.chooseSourceType.blur();
      // 从列表视图和从报表生成
      if (value === "listView") {
        setTimeout(() => {
          this.$refs.chooseObject.focus();
        }, 0);
      } else if (value === "report") {
        setTimeout(() => {
          this.$refs.reportDataSource.focus(); // 从报表生成
        }, 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-select .el-input__inner {
  height: 30px;
}
::v-deep .el-form-item__error {
  padding-top: 10px !important;
}
::v-deep .el-form-item {
  display: flex;
  height: auto !important;
  margin-bottom: 15px !important;
  .el-form-item__label {
    text-align: left;
    width: 100px !important;
    padding: 0;
  }
  .el-form-item__content {
    height: 30px;
    .el-select {
      width: 254px;
      padding-left: 0!important;
    }
  }
}

.choose-object,
.statistics-type,
.biaxialFigure-type,
.radarMap-type {
  display: flex;
  height: 55px;
  .el-form-item {
    margin: 0 10px 15px 0 !important;
  }
  .el-form-item__content {
    .el-select {
      width: 122px;
      padding-left: 0!important;
    }
  }
}
.biaxialFigureType,
.radarMapType {
  padding-left: 100px;
}
.valueDataLabelThree {
  margin-top: -10px;
  padding-left: 90px;
}
.valueDataLabelFour {
  margin-top: -10px;
}

.component-Name {
  ::v-deep .el-input {
    width: 254px !important;
  }
  ::v-deep .el-input__inner {
    height: 30px !important;
    border-radius: 4px !important;
    width: 254px;
    border: 1px solid #dcdfe6 !important;
    font-size: 12px!important;
  }
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  ::v-deep input[type="number"] {
    -moz-appearance: textfield !important;
  }
}
</style>
