<!-- 汇总式表格图表的数据源选择 -->
<template>
  <div class="form_data_source">
    <el-form ref="form" :model="form">
      <!-- 
        分组：
          1.数据源对象的label不为空才展示对应字段
          2.报表数据源的分组需要区分对象；列表视图数据源则不需区分；
          3.选择分组时不能选择查找多选字段作为分组；
       -->
      <el-form-item :label="$t('label.grouping')">
        <!-- 报表数据源选择分组 -->
        <el-select
          v-if="sourceType === 'report' && this.useLocation !== 'report'"
          v-model="form.groupField"
          filterable
          :placeholder="$t('vue_label_dashboard_groups')"
          @change="changeGroupField"
          :disabled="chooseGroupFields.length === 2"
        >
          <el-option-group
            v-for="(group, groupIndex) in groupFields"
            :key="groupIndex"
            :label="group.label"
            v-show="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.labelName"
              :value="item.id"
              :disabled="
                chooseGroupFields.some((item1) => item1.id === item.id)
              "
              v-show="
                isShowOtherField(item.id) &&
                !isLookMultiple(item.schemefieldType)
              "
            >
            </el-option>
          </el-option-group>
        </el-select>
        <!-- 报表：表格图表 -->
        <el-select
          v-if="sourceType === 'report' && this.useLocation === 'report'"
          v-model="form.groupField"
          filterable
          clearable
          :placeholder="$t('vue_label_dashboard_groups')"
          @change="changeGroupField"
          :disabled="chooseGroupFields.length === 2"
        >
          <el-option
            v-for="item in groupFields"
            :key="item.value"
            :label="item.labelName"
            :value="item.id"
            :disabled="chooseGroupFields.some((item1) => item1.id === item.id)"
            v-show="!isLookMultiple(item.schemefieldType)"
          >
          </el-option>
        </el-select>
        <!-- 列表视图数据源选择分组 -->
        <el-select
          v-if="sourceType === 'listView'"
          v-model="form.groupField"
          filterable
          :placeholder="$t('vue_label_dashboard_groups')"
          @change="changeGroupField"
          :disabled="chooseGroupFields.length === 2"
        >
          <el-option
            v-for="item in groupFields"
            :key="item.value"
            :label="item.labelName"
            :value="item.id"
            :disabled="chooseGroupFields.some((item1) => item1.id === item.id)"
            v-show="!isLookMultiple(item.schemefieldType)"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 选择的分组字段 -->
      <div class="group_field_center">
        <div
          class="ecah_field group_field"
          v-for="(item, index) in chooseGroupFields"
          :key="index"
        >
          <div>{{ item.labelName }}</div>
          <i
            class="el-icon-close"
            @click="deleteField(item, index, 'group')"
          ></i>
        </div>
      </div>
      <!-- 统计字段 -->
      <el-form-item
        :label="$t('vue_label_statistical_field')"
        v-if="chooseGroupFields.length > 0"
      >
        <el-select
          v-model="form.valueDataFiled"
          filterable
          :placeholder="$t('vue_label_dashboard_statisticalvalue')"
          @change="changeValueDataFiled"
          @visible-change="visibleChangeValueDataFiled"
        >
          <el-option
            v-for="(item, index) in valueDataFileds"
            :key="index"
            :label="item.labelName"
            :value="item.id"
            :disabled="
              chooseValueDataFileds.some((item1) => item1.id === item.id)
            "
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 记录计数 -->
      <div class="record_field_center">
        <div class="ecah_field group_field" v-if="chooseGroupFields.length > 0">
          <div>{{ $t("vue_label_record_count") }}</div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('vue_label_hidden_record_count')"
            placement="right"
          >
            <el-button
              type="text"
              v-show="!showRecordCountTo"
              @click="changeRecordCount"
            >
              <svg
                class="icon"
                aria-hidden="true"
                v-if="!img_butt_show"
                @mouseover="img_butt_show = true"
              >
                <use href="#icon-hide"></use>
              </svg>
              <svg
                class="icon"
                aria-hidden="true"
                v-else
                @mouseout="img_butt_show = false"
              >
                <use href="#icon-hide_blue"></use>
              </svg>
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('vue_label_show_record_count')"
            placement="right"
          >
            <el-button
              type="text"
              v-show="showRecordCountTo"
              @click="changeRecordCount"
            >
              <svg
                class="icon"
                aria-hidden="true"
                v-if="!img_butt_hide"
                @mouseover="img_butt_hide = true"
              >
                <use href="#icon-show"></use>
              </svg>
              <svg
                class="icon"
                aria-hidden="true"
                v-else
                @mouseout="img_butt_hide = false"
              >
                <use href="#icon-show_blue"></use>
              </svg>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <!-- 记录计数点击隐藏时，若是没有其他的统计值，提示的报警信息 -->
      <div
        style="color: #c23934"
        v-show="
          chooseGroupFields.length > 0 &&
          !showRecordCountTo &&
          chooseValueDataFileds.length === 0
        "
      >
        {{ $t("vue_label_metric_column") }}
      </div>
      <!-- 选择的数值字段 -->
      <div>
        <div v-for="(item, index) in chooseValueDataFileds" :key="index">
          <el-popover
            ref="popoverNumber"
            placement="right"
            width="400"
            trigger="click"
            popper-class="popover_number"
            @hide="hidePopoverNumber(item, index)"
          >
            <!-- 数值类型的字段回显统计类型 -->
            <numericalStatistical
              :summaryWays="item.summaryWays"
              @applySummary="(data) => applySummary(data, item, index)"
              @cancelSummary="cancelSummary(index)"
            />
            <div class="ecah_field value_field" slot="reference">
              <div># {{ item.labelName }}</div>
              <i
                class="el-icon-close"
                @click="deleteField(item, index, 'value')"
              ></i>
            </div>
          </el-popover>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import numericalStatistical from "../../../report/editReport/components/numericalStatistical"; // 回显数值的统计类型

export default {
  components: {
    numericalStatistical,
  },
  props: {
    // 从报表生成、从列表视图生成，决定了数据源
    sourceType: {
      type: String,
      default: "report",
    },
    // 图表使用位置
    useLocation: {
      type: String,
      default: "",
    },
    statisticsFields: {
      // 在报表编辑页添加图表时直接传过来的字段统计信息
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        groupField: "", // 分组字段
        valueDataFiled: "", // 统计字段
      },
      groupFields: [], // 可选择的分组字段集合
      chooseGroupFields: [], // 已选择的分组字段集合
      valueDataFileds: [], // 可选择的统计字段集合，及数值类型字段
      chooseValueDataFileds: [], // 已选择的统计字段集合
      showRecordCountTo: true, // 是否显示记录计数，默认显示
      groupIds: "", // 报表本身的分组字段id集合
      img_butt_show: false, // 记录计数图标-显示
      img_butt_hide: false, // 记录计数图标-不显示
    };
  },
  computed: {
    // 计算图表信息数据
    chartInfoObject: function () {
      return this.$store.getters.getChartInfoObject;
    },
    // 表格图表选择报表数据源时，添加的分组是否为报表本身的分组，控制是否显示
    isShowOtherField: function () {
      return (id) => {
        if (this.groupIds.indexOf(id) > -1) return true;
        return false;
      };
    },
    // 是否属于查找多选字段
    isLookMultiple() {
      return (fieldType) => {
        if (fieldType === "MR") {
          return true;
        }
        return false;
      };
    },
  },
  watch: {
    chartInfoObject: {
      handler() {
        // 获取图表信息
        let chartInfoObject = this.$store.getters.getChartInfoObject;
        // 判断是否是编辑时的回显
        if (this.useLocation !== "report" && !chartInfoObject.id) {
          return;
        }
        // 选择分组
        this.changeGroupField(chartInfoObject.ycon, "echo");
        if (chartInfoObject.xgather) {
          this.changeGroupField(chartInfoObject.xgather, "echo");
        }
        // 将统计字段和应用的统计类型匹配
        let xconSummarywayArr = [];
        if (chartInfoObject.xcon && chartInfoObject.xcon.indexOf(",")) {
          chartInfoObject.xcon.split(",").map((item, index) => {
            let valueObject = {
              id: "",
              summaryWays: [],
            };
            // 判断是否是之前的统计字段，若不是，则添加进去
            if (xconSummarywayArr.every((items) => items.id !== item)) {
              valueObject.id = item;
              valueObject.summaryWays.push(
                chartInfoObject.summaryway.split(",")[index]
              );
              xconSummarywayArr.push(valueObject);
            } else {
              // 若是之前统计字段的统计类型应用，则找到该统计字段，不需再添加
              valueObject = xconSummarywayArr.find(
                (items) => items.id === item
              );
              valueObject.summaryWays.push(
                chartInfoObject.summaryway.split(",")[index]
              );
            }
          });
        }
        // 选择统计字段及应用
        xconSummarywayArr.map((item, index) => {
          if (item.id === "totalrecord") {
            this.showRecordCountTo = true;
          } else {
            this.changeValueDataFiled(item.id);
            let dataItem = this.valueDataFileds.find(
              (items) => items.id === item.id
            );
            this.applySummary(item.summaryWays, dataItem, index);
          }
        });
        // 判断记录计数是否显示
        this.statisticalRecordCount();
      },
      immediate: true,
    },
    // 监听记录计数是否显示
    showRecordCountTo: {
      handler() {
        this.statisticalRecordCount();
      },
      immediate: true,
      deep: true,
    },
    ebFn1(val){
      this.groupFields = val.filteredListObjectFields;
      this.valueDataFileds = val.listSummaryField;
      this.groupIds = val.groupIds;
    },
    ebFn2(){
      this.chooseGroupFields = [];
      this.chooseValueDataFileds = [];
      this.$store.commit("setTableDetailGroupOneData", {});
      this.$bus.$emit("clearSortingCondition"); // 当没有分组时也需要清空排序字段的选择
    },
  },
  created() {},
  mounted() {
    this.$bus.$on("objectFieldsfn", this.ebFn1);
    // 在切换对象、报表时需要清空所选择的分组及统计字段
    this.$bus.$on("clearTableGroups",this.ebFn2 );
  },
  destroyed() {
    this.$bus.$off("objectFieldsfn", this.ebFn1);
    this.$bus.$off("clearTableGroups",this.ebFn2 );
  },
  methods: {
    // 实时获取图表动态数据
    // getRealData() {
    //   this.$bus.$emit("formChooseViewReal"); //实时动态显示
    // },
    // 选择分组字段
    changeGroupField(value, type) {
      // 根据id获取该字段的详细信息，需要判断属于哪种数据源
      // 根据useLocation判断图表显示位置是否在报表
      let data = {};
      if (this.sourceType === "report" && this.useLocation !== "report") {
        this.groupFields.map((item) => {
          item.options.map((item1) => {
            if (item1.id === value) {
              data = item1;
            }
          });
        });
      } else if (
        this.sourceType === "report" &&
        this.useLocation === "report"
      ) {
        this.groupFields.map((item) => {
          if (item.id === value) {
            data = item;
          }
        });
      } else if (this.sourceType === "listView") {
        data = this.groupFields.find((item) => item.id === value);
      }
      this.chooseGroupFields.push(data);
      this.form.groupField = "";
      // 只有一级分组才需要存储
      this.$nextTick(() => {
        if (this.chooseGroupFields.length === 1) {
          this.$store.commit("setTableDetailGroupOneData", data);
        }
      });
      // 判断如果是在回显已选择的分组，则不需进行清空排序字段
      if (type === "echo") return;
      this.$bus.$emit("clearSortingCondition"); // 当选择分组时也需要清空排序字段的选择
      this.$bus.$emit("formChooseViewReal"); //实时动态显示
    },
    // 选择统计字段
    changeValueDataFiled(value) {
      let data = this.valueDataFileds.find((item) => item.id === value);
      this.$set(data, "isApply", false); // 设置为未应用，默认
      // this.$set(data, "summaryWays", []); // 设置为未选择统计类型，默认
      this.chooseValueDataFileds.push(data);
      this.form.valueDataFiled = "";
      // 获取选择的数值字段的index，选择字段的时候直接显示对应弹框
      let index = this.chooseValueDataFileds.findIndex(
        (item) => item.id === value
      );
      this.$nextTick(() => {
        // 先给其他所有弹框隐藏
        this.$refs.popoverNumber.map((item) => {
          item.doClose();
        });
        this.$refs.popoverNumber[index].doShow(); // 弹框显示
      });
    },
    // 点击统计字段框
    visibleChangeValueDataFiled(value) {
      if (!value) return;
      // 当在报表中展示表格图表时，表格图表的统计字段与统计类型的选择范围需要与报表一致
      if (this.useLocation === "report") {
        this.valueDataFileds = [...this.statisticsFields];
        // 过滤掉记录数
        this.valueDataFileds = this.valueDataFileds.filter(
          (item) => item.schemefield_name_alias !== "totalrecord"
        );
        // 添加属性summaryWays
        this.valueDataFileds.map((item) => {
          const summaryWays = [];
          item.typeArr.forEach((items) => {
            summaryWays.push(items.value);
          });
          this.$set(item, "summaryWays", summaryWays);
        });
      }
    },
    // 删除选择字段：分组字段、统计字段
    deleteField(item, index, type) {
      if (type === "group") {
        // 表示在删除分组字段
        this.chooseGroupFields.splice(index, 1);
        if (this.chooseGroupFields.length === 0) {
          this.chooseValueDataFileds = [];
          this.$store.commit("setTableDetailGroupOneData", {});
          this.$bus.$emit("clearSortingCondition"); // 当没有分组时也需要清空排序字段的选择
          this.$bus.$emit("deleteGroup"); // 当没有分组时也需要清空排序字段的选择
        }
        // 更新排序字段
        if (this.chooseGroupFields.length === 1) {
          this.form.groupField = "";
          this.$bus.$emit("clearSortingCondition"); // 当没有分组时也需要清空排序字段的选择
          let data = {};
          if (this.sourceType === "report" && this.useLocation !== "report") {
            this.groupFields.map((item) => {
              item.options.map((item1) => {
                if (item1.id === this.chooseGroupFields[0].id) {
                  data = item1;
                }
              });
            });
          } else if (
            this.sourceType === "report" &&
            this.useLocation === "report"
          ) {
            this.groupFields.map((item) => {
              if (item.id === this.chooseGroupFields[0].id) {
                data = item;
              }
            });
          } else if (this.sourceType === "listView") {
            data = this.groupFields.find(
              (item) => item.id === this.chooseGroupFields[0].id
            );
          }
          // 只有一级分组才需要存储
          this.$store.commit("setTableDetailGroupOneData", data);
        }
      } else if (type === "value") {
        // 表示在删除统计字段
        this.chooseValueDataFileds.splice(index, 1);
        let e = {
          item: JSON.parse(JSON.stringify(item)),
          type: "delete",
        };
        this.$store.commit("setTableDetailStatistical", e);
        this.$bus.$emit("clearSortingCondition"); // 清空排序字段的选择
      }
      this.$bus.$emit("formChooseViewReal"); //实时动态显示
    },
    // 切换记录计数字段状态
    changeRecordCount() {
      this.showRecordCountTo = !this.showRecordCountTo;
      // 如果有除记录计数的统计值或者显示记录计数，渲染动态数据
      if (this.chooseValueDataFileds.length > 0 || this.showRecordCountTo) {
        this.$bus.$emit("formChooseViewReal"); //实时动态显示
      }
    },
    // 判断记录计数是否显示，决定是否需要对记录计数进行统计
    statisticalRecordCount() {
      let dataItem = {
        id: "totalrecord",
        labelName: this.$i18n.t("vue_label_record_count"),
        schemefieldName: "totalrecord",
        schemefield_name_alias: "totalrecord",
        schemefield_name_alias_obj: "1",
        schemefield_name_api: "1",
        summaryWay: "count",
        schemefieldType: "N",
      };
      if (!this.showRecordCountTo) {
        dataItem = {};
      }
      let e = {
        item: JSON.parse(JSON.stringify(dataItem)),
        type: "totalrecord",
      };
      this.$store.commit("setTableDetailStatistical", e);
    },
    // 应用统计方式
    applySummary(data, item, index) {
      this.$set(item, "isApply", true); // 设置为已应用
      this.$set(item, "summaryWays", data);
      let e = {
        item: JSON.parse(JSON.stringify(item)),
        summaryTypes: data,
        type: "apply",
      };
      this.$store.commit("setTableDetailStatistical", e);
      this.$bus.$emit("formChooseViewReal"); //实时动态显示
      this.$nextTick(() => {
        this.$refs.popoverNumber[index].doClose();
      });
    },
    // 取消修改数值类型字段统计方式的气泡
    cancelSummary(index) {
      this.$refs.popoverNumber[index].doClose();
    },
    // 监听关闭统计方式气泡的事件
    hidePopoverNumber(item, index) {
      // 判断该字段的统计是否已应用，若是未应用，需要删除该字段
      if (!item.isApply) {
        this.chooseValueDataFileds.splice(index, 1);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.form_data_source {
  .ecah_field {
    width: 254px;
    height: 30px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #080707;
    border: 1px solid #dddbda;
    border-radius: 4px;
    i {
      font-size: 15px;
      color: #706e6b;
      cursor: pointer;
      &:hover {
        color: #004487 !important;
      }
    }
  }
  .group_field_center,
  .record_field_center {
    display: flex;
    padding-top: 5px;
    flex-direction: column;
    margin: -15px 5px 10px 100px;
    .group_field {
      background-color: #fff;
      svg {
        width: 17px;
        height: 17px;
      }
    }
  }
  .group_field_center .group_field {
    margin-bottom: 5px;
  }
  .value_field {
    background-color: #eef7fc;
    cursor: pointer;
    display: flex;
    margin: 5px 5px 0 100px;
  }
}
</style>